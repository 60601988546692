this["CHSITemplates"]["Template_SuperClaims"] = Handlebars.template({"1":function(depth0,helpers,partials,data) {
    var stack1;

  return "        <tbody>\r\n"
    + ((stack1 = helpers.each.call(depth0,(depth0 != null ? depth0.SuperClaims : depth0),{"name":"each","hash":{},"fn":this.program(2, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "        </tbody>\r\n";
},"2":function(depth0,helpers,partials,data) {
    var helper, alias1=helpers.helperMissing, alias2="function", alias3=this.escapeExpression;

  return "            <tr>\r\n                <td>\r\n                    "
    + alias3(((helper = (helper = helpers.ClientName || (depth0 != null ? depth0.ClientName : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"ClientName","hash":{},"data":data}) : helper)))
    + "\r\n                </td>\r\n                <td>\r\n                    "
    + alias3(((helper = (helper = helpers.Description || (depth0 != null ? depth0.Description : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"Description","hash":{},"data":data}) : helper)))
    + "\r\n                </td>\r\n                <td>\r\n                    [<a href=\"#\" data-superclaimid=\""
    + alias3(((helper = (helper = helpers.SuperClaimID || (depth0 != null ? depth0.SuperClaimID : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"SuperClaimID","hash":{},"data":data}) : helper)))
    + "\" class=\"jxAssignToClaim minilink\">assign</a>]\r\n                </td>\r\n            </tr>\r\n";
},"4":function(depth0,helpers,partials,data) {
    return "        No Super Claims found.\r\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1;

  return "<div id=\"SuperClaimContainer\">\r\n    <table width=\"100%\" class=\"dtable sortable\">\r\n        <thead>\r\n            <tr>\r\n                <td width=\"200\">&nbspClient</td>\r\n                <td width=\"370\">Description</td>\r\n                <td>&nbsp</td>\r\n            </tr>\r\n        </thead>\r\n"
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.SuperClaims : depth0),{"name":"if","hash":{},"fn":this.program(1, data, 0),"inverse":this.program(4, data, 0),"data":data})) != null ? stack1 : "")
    + "    </table>\r\n</div>\r\n<table>\r\n    <tr>\r\n        <td colspan=\"3\">\r\n            Add to a new Super Claim:\r\n        </td>\r\n    </tr>\r\n    <tr>\r\n        <td width=\"100\">\r\n            Description\r\n        </td>\r\n        <td>\r\n            <input id=\"jxNewDescription\" type=\"text\" />\r\n        </td>\r\n    </tr>\r\n</table>";
},"useData":true});