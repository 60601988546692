this["CHSITemplates"]["Template_SuperClaimsResults"] = Handlebars.template({"1":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return "<table width=\"100%\" class=\"dtable\">\r\n    <thead>\r\n        <tr> \r\n            <td class=\"sorttable_nosort\" width=\"150\">Client</td>\r\n            <td class=\"sorttable_nosort\">Coverage</td>\r\n            <td class=\"sorttable_nosort\">Event/Claim #</td>\r\n            <td class=\"sorttable_nosort\">Claim Type</td>\r\n            <td class=\"sorttable_nosort\">Date Of Incident</td>\r\n            <td class=\"sorttable_nosort\">No. Claims</td>\r\n            <td class=\"sorttable_nosort\">Status</td>\r\n            <td class=\"sorttable_nosort\" style=\"text-align: right;\">Incurred</td>\r\n            <td class=\"sorttable_nosort\" style=\"padding-left: 20px;\">&nbsp</td>\r\n            <td class=\"sorttable_nosort\">&nbsp</td>\r\n        </tr>\r\n    </thead>\r\n    <tbody>\r\n"
    + ((stack1 = helpers.each.call(depth0,(depth0 != null ? depth0.SuperClaims : depth0),{"name":"each","hash":{},"fn":this.program(2, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "    </tbody>\r\n</table>\r\n";
},"2":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=helpers.helperMissing, alias2=this.escapeExpression, alias3="function";

  return "        <tr class=\""
    + alias2((helpers.backgroundcolor || (depth0 && depth0.backgroundcolor) || alias1).call(depth0,(depth0 != null ? depth0.Index : depth0),{"name":"backgroundcolor","hash":{},"data":data}))
    + "\">\r\n            <td colspan=\"2\">"
    + alias2(((helper = (helper = helpers.ClientName || (depth0 != null ? depth0.ClientName : depth0)) != null ? helper : alias1),(typeof helper === alias3 ? helper.call(depth0,{"name":"ClientName","hash":{},"data":data}) : helper)))
    + "</td>\r\n            <td colspan=\"2\">"
    + alias2(((helper = (helper = helpers.Description || (depth0 != null ? depth0.Description : depth0)) != null ? helper : alias1),(typeof helper === alias3 ? helper.call(depth0,{"name":"Description","hash":{},"data":data}) : helper)))
    + "</td>   \r\n            <td>&nbsp;</td>\r\n            <td>"
    + alias2(this.lambda(((stack1 = (depth0 != null ? depth0.Claims : depth0)) != null ? stack1.length : stack1), depth0))
    + "</td>\r\n            <td>"
    + alias2(((helper = (helper = helpers.Status || (depth0 != null ? depth0.Status : depth0)) != null ? helper : alias1),(typeof helper === alias3 ? helper.call(depth0,{"name":"Status","hash":{},"data":data}) : helper)))
    + "</td>            \r\n            <td style=\"text-align: right;\">"
    + alias2((helpers.FormatCurrency || (depth0 && depth0.FormatCurrency) || alias1).call(depth0,(depth0 != null ? depth0.TotalIncurred : depth0),true,{"name":"FormatCurrency","hash":{},"data":data}))
    + "</td>\r\n            <td>[<a href=\"javascript: ShowSuperClaimModal("
    + alias2(((helper = (helper = helpers.SuperClaimID || (depth0 != null ? depth0.SuperClaimID : depth0)) != null ? helper : alias1),(typeof helper === alias3 ? helper.call(depth0,{"name":"SuperClaimID","hash":{},"data":data}) : helper)))
    + ");\" class=\"minilink\">edit</a>]</td>\r\n        </tr>\r\n"
    + ((stack1 = helpers.each.call(depth0,(depth0 != null ? depth0.Claims : depth0),{"name":"each","hash":{},"fn":this.program(3, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "");
},"3":function(depth0,helpers,partials,data,blockParams,depths) {
    var helper, alias1=helpers.helperMissing, alias2=this.escapeExpression, alias3="function";

  return "        <tr class=\""
    + alias2((helpers.backgroundcolor || (depth0 && depth0.backgroundcolor) || alias1).call(depth0,(depths[1] != null ? depths[1].Index : depths[1]),{"name":"backgroundcolor","hash":{},"data":data}))
    + "\">            \r\n            <td>&nbsp;</td>\r\n            <td>"
    + alias2(((helper = (helper = helpers.Coverage || (depth0 != null ? depth0.Coverage : depth0)) != null ? helper : alias1),(typeof helper === alias3 ? helper.call(depth0,{"name":"Coverage","hash":{},"data":data}) : helper)))
    + "</td>\r\n            <td>"
    + alias2(((helper = (helper = helpers.ClaimNumber || (depth0 != null ? depth0.ClaimNumber : depth0)) != null ? helper : alias1),(typeof helper === alias3 ? helper.call(depth0,{"name":"ClaimNumber","hash":{},"data":data}) : helper)))
    + "</td>\r\n            <td>"
    + alias2(((helper = (helper = helpers.ClaimTypeCategory || (depth0 != null ? depth0.ClaimTypeCategory : depth0)) != null ? helper : alias1),(typeof helper === alias3 ? helper.call(depth0,{"name":"ClaimTypeCategory","hash":{},"data":data}) : helper)))
    + "</td>\r\n            <td>"
    + alias2((helpers.CHSIDate || (depth0 && depth0.CHSIDate) || alias1).call(depth0,(depth0 != null ? depth0.DateOfIncident : depth0),{"name":"CHSIDate","hash":{},"data":data}))
    + "</td>            \r\n            <td>"
    + alias2(((helper = (helper = helpers.Claimant || (depth0 != null ? depth0.Claimant : depth0)) != null ? helper : alias1),(typeof helper === alias3 ? helper.call(depth0,{"name":"Claimant","hash":{},"data":data}) : helper)))
    + "</td>            \r\n            <td>"
    + alias2(((helper = (helper = helpers.Status || (depth0 != null ? depth0.Status : depth0)) != null ? helper : alias1),(typeof helper === alias3 ? helper.call(depth0,{"name":"Status","hash":{},"data":data}) : helper)))
    + "</td>\r\n            <td style=\"text-align: right;\">"
    + alias2((helpers.FormatCurrency || (depth0 && depth0.FormatCurrency) || alias1).call(depth0,(depth0 != null ? depth0.Incurred : depth0),true,{"name":"FormatCurrency","hash":{},"data":data}))
    + "</td>\r\n            <td>[<a href=\"javascript: ShowClaimView("
    + alias2(((helper = (helper = helpers.ClaimID || (depth0 != null ? depth0.ClaimID : depth0)) != null ? helper : alias1),(typeof helper === alias3 ? helper.call(depth0,{"name":"ClaimID","hash":{},"data":data}) : helper)))
    + ", "
    + alias2(((helper = (helper = helpers.ClientID || (depth0 != null ? depth0.ClientID : depth0)) != null ? helper : alias1),(typeof helper === alias3 ? helper.call(depth0,{"name":"ClientID","hash":{},"data":data}) : helper)))
    + ", "
    + alias2(((helper = (helper = helpers.ClaimSystemVersion || (depth0 != null ? depth0.ClaimSystemVersion : depth0)) != null ? helper : alias1),(typeof helper === alias3 ? helper.call(depth0,{"name":"ClaimSystemVersion","hash":{},"data":data}) : helper)))
    + ")\" class=\"minilink\">view</a>]</td>\r\n        </tr>\r\n";
},"5":function(depth0,helpers,partials,data) {
    return "<h4>No results found.</h4>\r\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.SuperClaims : depth0),{"name":"if","hash":{},"fn":this.program(1, data, 0, blockParams, depths),"inverse":this.program(5, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "");
},"useData":true,"useDepths":true});