this["CHSITemplates"]["Template_Attachments"] = Handlebars.template({"1":function(depth0,helpers,partials,data) {
    var helper, alias1=helpers.helperMissing, alias2=this.escapeExpression;

  return "    <tr>\r\n        <td><a href=\"/Chsifilehandler.axd?fileid="
    + alias2(((helper = (helper = helpers.FileID || (depth0 != null ? depth0.FileID : depth0)) != null ? helper : alias1),(typeof helper === "function" ? helper.call(depth0,{"name":"FileID","hash":{},"data":data}) : helper)))
    + "\" target=\"_blank\">"
    + alias2((helpers.TruncateString || (depth0 && depth0.TruncateString) || alias1).call(depth0,(depth0 != null ? depth0.FileName : depth0),50,{"name":"TruncateString","hash":{},"data":data}))
    + "</a></td>\r\n    </tr>\r\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1;

  return "\r\n<table cellpadding=\"2\" cellspacing=\"0\">\r\n"
    + ((stack1 = helpers.each.call(depth0,(depth0 != null ? depth0.Attachments : depth0),{"name":"each","hash":{},"fn":this.program(1, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "</table>";
},"useData":true});