this["CHSITemplates"]["AdminTools_Questionnaire_Editor_Sections"] = Handlebars.template({"1":function(depth0,helpers,partials,data) {
    var stack1;

  return "<table width=\"100%\" class=\"dtable sortable sortableQuestionnaire\">\r\n    <thead>\r\n        <tr>\r\n            <td></td>\r\n            <td>Section Name</td>\r\n            <td>Status</td>\r\n            <td width=\"60\">Is Req.</td>\r\n            <td width=\"120\">Default Visibility</td>\r\n            <td>#Elements</td>\r\n            <td width=\"21\"></td>\r\n            <td></td>\r\n        </tr>\r\n    </thead>\r\n    <tbody>        \r\n"
    + ((stack1 = helpers.each.call(depth0,(depth0 != null ? depth0.Sections : depth0),{"name":"each","hash":{},"fn":this.program(2, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "    </tbody>\r\n</table>\r\n";
},"2":function(depth0,helpers,partials,data) {
    var stack1, helper, alias1=helpers.helperMissing, alias2="function", alias3=this.escapeExpression;

  return "        <tr class=\"section sortableOnly\" data-sectionid=\""
    + alias3(((helper = (helper = helpers.SectionID || (depth0 != null ? depth0.SectionID : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"SectionID","hash":{},"data":data}) : helper)))
    + "\">\r\n            <td>\r\n                <div class=\"grip\"></div>\r\n            </td>\r\n            <td>\r\n                <input class=\"sectionName\" type=\"text\" "
    + alias3((helpers.isValid || (depth0 && depth0.isValid) || alias1).call(depth0,"Title",{"name":"isValid","hash":{},"data":data}))
    + " value=\""
    + alias3(((helper = (helper = helpers.Title || (depth0 != null ? depth0.Title : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"Title","hash":{},"data":data}) : helper)))
    + "\" tabindex=\"1\" />\r\n            </td>\r\n            <td>\r\n                <select class=\"sectionStatus\">\r\n                    <option value=\"Active\" "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias1).call(depth0,(depth0 != null ? depth0.Status : depth0),"Active",{"name":"compare","hash":{"operator":"=="},"fn":this.program(3, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ">Active</option>\r\n                    <option value=\"Inactive\" "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias1).call(depth0,(depth0 != null ? depth0.Status : depth0),"Inactive",{"name":"compare","hash":{"operator":"=="},"fn":this.program(3, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ">Inactive</option>\r\n                </select>\r\n            </td>\r\n            <td>\r\n                <input class=\"sectionRequired\" type=\"checkbox\" "
    + ((stack1 = (helpers.GetSectionMetaValue || (depth0 && depth0.GetSectionMetaValue) || alias1).call(depth0,16,"1",{"name":"GetSectionMetaValue","hash":{},"fn":this.program(5, data, 0),"inverse":this.program(7, data, 0),"data":data})) != null ? stack1 : "")
    + " />\r\n            </td>\r\n            <td>\r\n                <select class=\"sectionVisibility\"></select>\r\n            </td>\r\n            <td>\r\n                <span>"
    + alias3(this.lambda(((stack1 = (depth0 != null ? depth0.Elements : depth0)) != null ? stack1.length : stack1), depth0))
    + "</span>\r\n            </td>\r\n            <td>\r\n                <div class=\"has_changed_exclamation_container\" "
    + ((stack1 = (helpers.ifHasChanged || (depth0 && depth0.ifHasChanged) || alias1).call(depth0,""," style=\"display: none;\"",{"name":"ifHasChanged","hash":{},"data":data})) != null ? stack1 : "")
    + "><span class=\"ui-icon ui-icon-alert\"></span></div>\r\n            </td>\r\n            <td>\r\n                <button type=\"button\" class=\"gotoSection\" "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias1).call(depth0,(depth0 != null ? depth0.SectionID : depth0),0,{"name":"compare","hash":{"operator":"<"},"fn":this.program(9, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ">Go to Elements</button><button type=\"button\" class=\"sectionUndo "
    + alias3(((helper = (helper = helpers.HideIfGrid || (depth0 != null ? depth0.HideIfGrid : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"HideIfGrid","hash":{},"data":data}) : helper)))
    + "\" "
    + alias3(((helper = (helper = helpers.ifHasChangedDisable || (depth0 != null ? depth0.ifHasChangedDisable : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"ifHasChangedDisable","hash":{},"data":data}) : helper)))
    + ">Undo Changes</button><button type=\"button\" class=\"sectionDelete "
    + alias3(((helper = (helper = helpers.HideIfGrid || (depth0 != null ? depth0.HideIfGrid : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"HideIfGrid","hash":{},"data":data}) : helper)))
    + "\">Delete</button>\r\n            </td>\r\n        </tr>\r\n";
},"3":function(depth0,helpers,partials,data) {
    return " selected ";
},"5":function(depth0,helpers,partials,data) {
    return " checked ";
},"7":function(depth0,helpers,partials,data) {
    return " ";
},"9":function(depth0,helpers,partials,data) {
    return " disabled ";
},"11":function(depth0,helpers,partials,data) {
    return "<div style=\"margin: 0 auto; width: 425px; margin-top: 155px;\">\r\n    <h3>No sections found. You can add sections by clicking \"Add\" below.</h3>\r\n</div>\r\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1;

  return "<style>\r\n    .gotoSection, .sectionUndo{\r\n        margin-right: 5px;\r\n    }\r\n    .sectionName{\r\n        width: 170px;\r\n    }\r\n   .section > td {\r\n    padding-top: 2px;\r\n    padding-bottom: 2px;\r\n    padding-right: 2px;\r\n}\r\n</style>\r\n"
    + ((stack1 = helpers["if"].call(depth0,((stack1 = (depth0 != null ? depth0.Sections : depth0)) != null ? stack1.length : stack1),{"name":"if","hash":{},"fn":this.program(1, data, 0),"inverse":this.program(11, data, 0),"data":data})) != null ? stack1 : "");
},"useData":true});