this["CHSITemplates"]["Template_DistributionListAdmin"] = Handlebars.template({"1":function(depth0,helpers,partials,data) {
    return " checked=\"checked\" ";
},"3":function(depth0,helpers,partials,data) {
    return " ";
},"5":function(depth0,helpers,partials,data) {
    var helper;

  return this.escapeExpression(((helper = (helper = helpers.ListName || (depth0 != null ? depth0.ListName : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"ListName","hash":{},"data":data}) : helper)));
},"7":function(depth0,helpers,partials,data) {
    return "";
},"9":function(depth0,helpers,partials,data) {
    var helper;

  return this.escapeExpression(((helper = (helper = helpers.Description || (depth0 != null ? depth0.Description : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"Description","hash":{},"data":data}) : helper)));
},"11":function(depth0,helpers,partials,data) {
    var helper;

  return this.escapeExpression(((helper = (helper = helpers.ListSource || (depth0 != null ? depth0.ListSource : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"ListSource","hash":{},"data":data}) : helper)));
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1, alias1=helpers.helperMissing, alias2=this.escapeExpression;

  return "<table width=\"100%\">\r\n    <tr>\r\n        <td>\r\n            Distribution List Type\r\n        </td>\r\n        <td>\r\n            <select id=\"jxDistributionListType\">\r\n                <option value=\"1\" "
    + alias2((helpers.Selected || (depth0 && depth0.Selected) || alias1).call(depth0,(depth0 != null ? depth0.ListTypeID : depth0),1,{"name":"Selected","hash":{},"data":data}))
    + ">Custom</option>\r\n                <option value=\"5\" "
    + alias2((helpers.Selected || (depth0 && depth0.Selected) || alias1).call(depth0,(depth0 != null ? depth0.ListTypeID : depth0),5,{"name":"Selected","hash":{},"data":data}))
    + ">Constant Contact</option>\r\n            </select>\r\n        </td>\r\n        <td>\r\n            <input id=\"jxFax\" type=\"checkbox\" "
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.AllowFax : depth0),{"name":"if","hash":{},"fn":this.program(1, data, 0),"inverse":this.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + " />Fax\r\n        </td>\r\n        <td>\r\n            <input id=\"jxPhone\" type=\"checkbox\" "
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.AllowPhone : depth0),{"name":"if","hash":{},"fn":this.program(1, data, 0),"inverse":this.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + " />Phone\r\n        </td>\r\n        <td>\r\n            <input id=\"jxPost\" type=\"checkbox\" "
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.AllowPost : depth0),{"name":"if","hash":{},"fn":this.program(1, data, 0),"inverse":this.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + " />Post\r\n        </td>\r\n        <td>\r\n            <input id=\"jxEmail\" type=\"checkbox\" "
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.AllowEmail : depth0),{"name":"if","hash":{},"fn":this.program(1, data, 0),"inverse":this.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + " />Email\r\n        </td>\r\n    </tr>\r\n    <tr>\r\n        <td>\r\n            Display Name\r\n        </td>\r\n        <td colspan=\"2\">\r\n            <input id=\"jxDisplayName\" type=\"text\" value=\""
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.ListName : depth0),{"name":"if","hash":{},"fn":this.program(5, data, 0),"inverse":this.program(7, data, 0),"data":data})) != null ? stack1 : "")
    + "\" />\r\n        </td>\r\n    </tr>\r\n    <tr>\r\n        <td>\r\n            Private\r\n        </td>\r\n        <td>\r\n            <input id=\"jxPrivate\" type=\"checkbox\" "
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.isPrivate : depth0),{"name":"if","hash":{},"fn":this.program(1, data, 0),"inverse":this.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + " />\r\n        </td>\r\n    </tr>\r\n    <tr>\r\n        <td>\r\n            Active\r\n        </td>\r\n        <td>\r\n            <input id=\"jxActive\" type=\"checkbox\" "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias1).call(depth0,(depth0 != null ? depth0.Status : depth0),"active",{"name":"compare","hash":{},"fn":this.program(1, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + " />\r\n        </td>\r\n    </tr>\r\n    <tr>\r\n        <td>\r\n            Description\r\n        </td>\r\n        <td colspan=\"3\">\r\n            <input id=\"jxDescription\" type=\"text\" value=\""
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.Description : depth0),{"name":"if","hash":{},"fn":this.program(9, data, 0),"inverse":this.program(7, data, 0),"data":data})) != null ? stack1 : "")
    + "\" />\r\n        </td>\r\n    </tr>\r\n</table>\r\n<table width=\"100%\" id=\"jxListSourceContainer\">\r\n    <tr>\r\n        <td valign=\"top\" width=\"138\">\r\n            List Source\r\n        </td>\r\n        <td>\r\n            <textarea id=\"jxListSource\" rows=\"10\" cols=\"50\">"
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.ListSource : depth0),{"name":"if","hash":{},"fn":this.program(11, data, 0),"inverse":this.program(7, data, 0),"data":data})) != null ? stack1 : "")
    + "</textarea>\r\n            <select id=\"jxConstantContactList\" style=\"display:none;\"></select>\r\n        </td>\r\n    </tr>\r\n</table>\r\n<div>\r\n    <input id=\"jxDelete\" type=\"button\" class=\"buttonPadding\" value=\"Delete This List\" />\r\n    <input id=\"jxSave\" class=\"buttonFloat buttonPadding\" type=\"button\" value=\"Save\" />\r\n    <input id=\"jxCancel\" class=\"buttonFloat buttonPadding\" type=\"button\" value=\"Cancel\" onclick=\"closeThis(true);\" />\r\n</div>    ";
},"useData":true});