this["CHSITemplates"]["Template_IncidentSearch_GroupResults"] = Handlebars.template({"1":function(depth0,helpers,partials,data) {
    return "    <div style=\"color: Red; font-size: 16px;\">\r\n        More than 100 records found, please refine your search.\r\n    </div>\r\n";
},"3":function(depth0,helpers,partials,data) {
    var stack1;

  return "            <table cellspacing=0 cellpadding=0 class=\"dtable sortable\">\r\n                <thead>\r\n                    <tr>\r\n                        <td style=\"width: 80px\">Group</td>\r\n                        <td style=\"width: 200px\">Client</td>\r\n                        <td style=\"width: 80px\">Incident No.</td>\r\n                        <td style=\"width: 100px\">Incident Date</td>\r\n                        <td style=\"width: 150px\">Employee Name</td>\r\n                        <td style=\"width: 100px\">Status</td>\r\n                        <td style=\"width: 80px\">&nbsp;</td>\r\n                    </tr>\r\n                </thead>\r\n                <tbody>"
    + ((stack1 = helpers.each.call(depth0,(depth0 != null ? depth0.IncidentTableRecord : depth0),{"name":"each","hash":{},"fn":this.program(4, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\r\n                </tbody>\r\n            </table>\r\n";
},"4":function(depth0,helpers,partials,data) {
    var stack1, alias1=this.lambda, alias2=this.escapeExpression, alias3=helpers.helperMissing;

  return "\r\n                    <tr>\r\n                        <td style=\"width: 80px\">"
    + alias2(alias1((depth0 != null ? depth0.GroupID : depth0), depth0))
    + "</td>\r\n                        <td style=\"width: 200px\">"
    + alias2((helpers.TruncateString || (depth0 && depth0.TruncateString) || alias3).call(depth0,(depth0 != null ? depth0.ClientName : depth0),34,{"name":"TruncateString","hash":{},"data":data}))
    + "</td>\r\n                        <td style=\"width: 80px\">"
    + alias2(alias1((depth0 != null ? depth0.IncidentID : depth0), depth0))
    + "</td>\r\n                        <td style=\"width: 100px\">"
    + alias2((helpers.CHSIDate || (depth0 && depth0.CHSIDate) || alias3).call(depth0,(depth0 != null ? depth0.IncidentDate : depth0),{"name":"CHSIDate","hash":{},"data":data}))
    + "</td>\r\n                        <td style=\"width: 150px\">"
    + alias2((helpers.TruncateString || (depth0 && depth0.TruncateString) || alias3).call(depth0,(depth0 != null ? depth0.EmployeeName : depth0),20,{"name":"TruncateString","hash":{},"data":data}))
    + "</td>\r\n                        <td style=\"width: 100px\">"
    + alias2(alias1((depth0 != null ? depth0.Status : depth0), depth0))
    + "</td>\r\n                        <td style=\"width: 80px\">[<a href=\"/sig/Claims/details.aspx?id="
    + alias2(alias1((depth0 != null ? depth0.IncidentID : depth0), depth0))
    + "\" class=\"minilink\">view</a>][<a href=\""
    + alias2((helpers.CreatePrintURL || (depth0 && depth0.CreatePrintURL) || alias3).call(depth0,(depth0 != null ? depth0.FormType : depth0),((stack1 = (depth0 != null ? depth0.FormTypeObject : depth0)) != null ? stack1.ReportForm : stack1),(depth0 != null ? depth0.IncidentID : depth0),{"name":"CreatePrintURL","hash":{},"data":data}))
    + "\" target=\"_blank\" class=\"minilink\">print</a>]</td>\r\n                    </tr>";
},"6":function(depth0,helpers,partials,data) {
    return "            <p>No incidents found using the supplied search filters.  Please refine your search and try again...</p>\r\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.ShowTooManyRecords : depth0),{"name":"if","hash":{},"fn":this.program(1, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "<br /><span style=\"font-size: 20px;\">Reported Incidents</span><hr style=\"color: #7ba2d6;\" />\r\n"
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.IncidentTableRecord : depth0),{"name":"if","hash":{},"fn":this.program(3, data, 0),"inverse":this.program(6, data, 0),"data":data})) != null ? stack1 : "");
},"useData":true});