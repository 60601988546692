this["CHSITemplates"]["ClaimManagement"]["CreateClaimModalView"] = Handlebars.template({"1":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=helpers.helperMissing, alias2=this.escapeExpression;

  return "                <option value=\""
    + alias2(((helper = (helper = helpers.key || (data && data.key)) != null ? helper : alias1),(typeof helper === "function" ? helper.call(depth0,{"name":"key","hash":{},"data":data}) : helper)))
    + "\" "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias1).call(depth0,(data && data.key),((stack1 = (depths[1] != null ? depths[1].Claim : depths[1])) != null ? stack1.ClientID : stack1),{"name":"compare","hash":{},"fn":this.program(2, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ">"
    + alias2(this.lambda(depth0, depth0))
    + "</option>\r\n";
},"2":function(depth0,helpers,partials,data) {
    return " selected";
},"4":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=helpers.helperMissing, alias2=this.escapeExpression;

  return "                <option value=\""
    + alias2(((helper = (helper = helpers.key || (data && data.key)) != null ? helper : alias1),(typeof helper === "function" ? helper.call(depth0,{"name":"key","hash":{},"data":data}) : helper)))
    + "\" "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias1).call(depth0,(data && data.key),((stack1 = (depths[1] != null ? depths[1].Claim : depths[1])) != null ? stack1.GroupLineID : stack1),{"name":"compare","hash":{},"fn":this.program(2, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ">"
    + alias2(this.lambda(depth0, depth0))
    + "</option>\r\n";
},"6":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=helpers.helperMissing, alias2=this.escapeExpression;

  return "                <option value=\""
    + alias2(((helper = (helper = helpers.key || (data && data.key)) != null ? helper : alias1),(typeof helper === "function" ? helper.call(depth0,{"name":"key","hash":{},"data":data}) : helper)))
    + "\" "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias1).call(depth0,(data && data.key),((stack1 = (depths[1] != null ? depths[1].Claim : depths[1])) != null ? stack1.ClaimType : stack1),{"name":"compare","hash":{},"fn":this.program(2, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ">"
    + alias2(this.lambda(depth0, depth0))
    + "</option>\r\n";
},"8":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=helpers.helperMissing, alias2=this.escapeExpression;

  return "                <option value=\""
    + alias2(((helper = (helper = helpers.key || (data && data.key)) != null ? helper : alias1),(typeof helper === "function" ? helper.call(depth0,{"name":"key","hash":{},"data":data}) : helper)))
    + "\" "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias1).call(depth0,(data && data.key),((stack1 = (depths[1] != null ? depths[1].Claim : depths[1])) != null ? stack1.ClaimClass : stack1),{"name":"compare","hash":{},"fn":this.program(2, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ">"
    + alias2(this.lambda(depth0, depth0))
    + "</option>\r\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=this.lambda, alias2=this.escapeExpression;

  return "<div class=\"popup-form\">\r\n    <div class=\"group-container col-1\">\r\n        <div class=\"input-container col-2\">\r\n            <label>Date of Incident</label>\r\n            <input type=\"text\" id=\"DateOfIncident\" class=\"popup-form-input date\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.Claim : depth0)) != null ? stack1.DateOfIncident : stack1), depth0))
    + "\" />\r\n        </div>\r\n        <div class=\"input-container col-2\">\r\n            <label>Date Reported</label>\r\n            <input type=\"text\" id=\"DateReported\" class=\"popup-form-input date\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.Claim : depth0)) != null ? stack1.DateReported : stack1), depth0))
    + "\" />\r\n        </div>\r\n        <div class=\"input-container col-2\">\r\n            <label>Client</label>\r\n            <select id=\"ClientID\" class=\"popup-form-input\">\r\n"
    + ((stack1 = helpers.each.call(depth0,((stack1 = (depth0 != null ? depth0.Config : depth0)) != null ? stack1.Clients : stack1),{"name":"each","hash":{},"fn":this.program(1, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "            </select>\r\n        </div>\r\n        <div class=\"input-container col-2\">\r\n            <label>Coverage Line</label>\r\n            <select id=\"GroupLineID\" class=\"popup-form-input\">\r\n"
    + ((stack1 = helpers.each.call(depth0,((stack1 = (depth0 != null ? depth0.Config : depth0)) != null ? stack1.GroupLines : stack1),{"name":"each","hash":{},"fn":this.program(4, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "            </select>\r\n        </div>\r\n        <div class=\"input-container col-2\">\r\n            <label>Claim Type</label>\r\n            <select id=\"ClaimType\" class=\"popup-form-input\">\r\n"
    + ((stack1 = helpers.each.call(depth0,((stack1 = (depth0 != null ? depth0.Config : depth0)) != null ? stack1.ClaimType : stack1),{"name":"each","hash":{},"fn":this.program(6, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "            </select>\r\n        </div>\r\n        <div class=\"input-container col-2\">\r\n            <label>Classification</label>\r\n            <select id=\"ClaimClass\" class=\"popup-form-input\">\r\n"
    + ((stack1 = helpers.each.call(depth0,((stack1 = (depth0 != null ? depth0.Config : depth0)) != null ? stack1.ClaimClass : stack1),{"name":"each","hash":{},"fn":this.program(8, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "            </select>\r\n        </div>\r\n    </div>\r\n</div>\r\n<div class=\"btn-bar\">\r\n    <input class=\"claim-cancel\" value=\"Cancel\" type=\"button\" style=\"float:right;\" />\r\n    <input class=\"claim-save\" value=\"Create\" type=\"button\" style=\"float:right;margin-right:20px\" />\r\n</div> ";
},"useData":true,"useDepths":true});