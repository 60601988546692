this["CHSITemplates"]["AdminTools_ClaimIntakeEditor_Categories"] = Handlebars.template({"1":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.each.call(depth0,(depth0 != null ? depth0.Categories : depth0),{"name":"each","hash":{},"fn":this.program(2, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "");
},"2":function(depth0,helpers,partials,data) {
    var stack1, helper, alias1=helpers.helperMissing, alias2="function", alias3=this.escapeExpression;

  return "        <tr class=\"sortableOnly category\" data-formcategoryid=\""
    + alias3(((helper = (helper = helpers.FormCategoryID || (depth0 != null ? depth0.FormCategoryID : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"FormCategoryID","hash":{},"data":data}) : helper)))
    + "\" data-formsortorder=\"\">\r\n            <td>\r\n                <div><div class=\"grip\"></div><input class=\"catName\" type=\"text\" value=\""
    + alias3(((helper = (helper = helpers.CategoryName || (depth0 != null ? depth0.CategoryName : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"CategoryName","hash":{},"data":data}) : helper)))
    + "\" "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias1).call(depth0,(depth0 != null ? depth0.CategoryName : depth0),"Internal",{"name":"compare","hash":{"operator":"=="},"fn":this.program(3, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + " /></div>\r\n            </td>\r\n            <td>\r\n                <span>"
    + alias3(((helper = (helper = helpers.FieldCount || (depth0 != null ? depth0.FieldCount : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"FieldCount","hash":{},"data":data}) : helper)))
    + "</span>\r\n            </td>\r\n            <td>\r\n"
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias1).call(depth0,(depth0 != null ? depth0.CategoryName : depth0),"Internal",{"name":"compare","hash":{"operator":"!="},"fn":this.program(5, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "            </td>\r\n        </tr>\r\n";
},"3":function(depth0,helpers,partials,data) {
    return " readonly=\"readonly\" disabled title=\"The Interntal category is for fields that currently have no assisgned category.\" ";
},"5":function(depth0,helpers,partials,data) {
    return "                <input type=\"button\" class=\"catDelete\" value=\" Delete \" />\r\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1;

  return "<table width=\"100%\" class=\"sortableFormTypeEditTable dtable sortable categoryTable\">\r\n    <thead>\r\n        <tr>\r\n            <td>Category Name</td>\r\n            <td>No. of Fields</td>\r\n            <td></td>\r\n        </tr>\r\n    </thead>\r\n    <tbody>\r\n"
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.Categories : depth0),{"name":"if","hash":{},"fn":this.program(1, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "    </tbody>\r\n</table>";
},"useData":true});