this["CHSITemplates"]["AdminTools_ClaimIntakeEditor_Fields"] = Handlebars.template({"1":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=helpers.helperMissing, alias2="function", alias3=this.escapeExpression;

  return "        <tr class=\"sortableOnly field\" data-fieldid=\""
    + alias3(((helper = (helper = helpers.FormFieldID || (depth0 != null ? depth0.FormFieldID : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"FormFieldID","hash":{},"data":data}) : helper)))
    + "\" data-index=\""
    + alias3(((helper = (helper = helpers.index || (data && data.index)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"index","hash":{},"data":data}) : helper)))
    + "\" data-gridsortorder=\""
    + alias3(((helper = (helper = helpers.GridSortOrder || (depth0 != null ? depth0.GridSortOrder : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"GridSortOrder","hash":{},"data":data}) : helper)))
    + "\" data-formsortorder=\""
    + alias3(((helper = (helper = helpers.FormSortOrder || (depth0 != null ? depth0.FormSortOrder : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"FormSortOrder","hash":{},"data":data}) : helper)))
    + "\">                        \r\n            <td colspan=\"8\">\r\n                <div>\r\n                    <div class=\"grip\"></div>\r\n                    <input class=\"fieldName fieldTypeText\" name=\"FieldName\" type=\"text\" value=\""
    + alias3(((helper = (helper = helpers.FieldName || (depth0 != null ? depth0.FieldName : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"FieldName","hash":{},"data":data}) : helper)))
    + "\" tabindex=\""
    + alias3((helpers.TabIndex || (depth0 && depth0.TabIndex) || alias1).call(depth0,(data && data.index),{"name":"TabIndex","hash":{},"data":data}))
    + "\" /> \r\n                    <input class=\"fieldLongName fieldTypeText\" name=\"LongName\" type=\"text\" value=\""
    + alias3(((helper = (helper = helpers.LongName || (depth0 != null ? depth0.LongName : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"LongName","hash":{},"data":data}) : helper)))
    + "\" tabindex=\""
    + alias3((helpers.TabIndex || (depth0 && depth0.TabIndex) || alias1).call(depth0,(data && data.index),{"name":"TabIndex","hash":{},"data":data}))
    + "\" "
    + ((stack1 = (helpers.IfFieldTypeID || (depth0 && depth0.IfFieldTypeID) || alias1).call(depth0,(depth0 != null ? depth0.FieldType : depth0),{"name":"IfFieldTypeID","hash":{},"data":data})) != null ? stack1 : "")
    + " />\r\n                     \r\n                    <select class=\"fieldStatus fieldTypeSelect\" name=\"Status\">\r\n"
    + ((stack1 = (helpers.select || (depth0 && depth0.select) || alias1).call(depth0,(depth0 != null ? depth0.Status : depth0),{"name":"select","hash":{},"fn":this.program(2, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "                    </select>\r\n                    <label><span>Show in Form? </span><input class=\"fieldShowInForm fieldTypeCheckbox\" name=\"FormShow\" type=\"checkbox\" "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias1).call(depth0,(depth0 != null ? depth0.FormShow : depth0),true,{"name":"compare","hash":{},"fn":this.program(4, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + " /></label> \r\n                    <label><span>Show in Grid? </span><input class=\"fieldShowInGrid fieldTypeCheckbox\" name=\"GridShow\" type=\"checkbox\"  disabled=\"disabled\" title=\"This option is not yet implemented.\" /></label> \r\n                    <label><span>Required?: </span><input class=\"fieldIsRequired fieldTypeCheckbox\" name=\"Required\" type=\"checkbox\" "
    + ((stack1 = (helpers.isRequired || (depth0 && depth0.isRequired) || alias1).call(depth0,(depth0 != null ? depth0.FieldType : depth0),(depth0 != null ? depth0.Required : depth0),{"name":"isRequired","hash":{},"data":data})) != null ? stack1 : "")
    + " /></label>\r\n                    <button type=\"button\" class=\"fieldEdit\">Edit<span class=\"ui-icon ui-icon-triangle-1-s editIcon\"></span></button>\r\n                    <div class=\"fieldMasterDetail "
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.isExpanded : depth0),{"name":"if","hash":{},"fn":this.program(6, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\">\r\n                        <table>\r\n                            <tbody>                        \r\n                                <tr>\r\n                                    <td width=\"50%\">\r\n                                        <label><span>Tool Tip Text: </span><input class=\"fieldToolTip fieldTypeText\" name=\"ToolTipText\" type=\"text\" value=\""
    + alias3(((helper = (helper = helpers.ToolTipText || (depth0 != null ? depth0.ToolTipText : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"ToolTipText","hash":{},"data":data}) : helper)))
    + "\" "
    + ((stack1 = (helpers.IfFieldTypeID || (depth0 && depth0.IfFieldTypeID) || alias1).call(depth0,(depth0 != null ? depth0.FieldType : depth0),{"name":"IfFieldTypeID","hash":{},"data":data})) != null ? stack1 : "")
    + " /></label>\r\n                                    </td>\r\n                                    <td width=\"50%\">\r\n\r\n                                        <label>\r\n                                            <span>Categories: </span>\r\n                                            <select class=\"fieldCategory fieldTypeSelect\" name=\"FormCategoryID\">                                        "
    + ((stack1 = (helpers.SelectFieldCategoryID || (depth0 && depth0.SelectFieldCategoryID) || alias1).call(depth0,(depths[1] != null ? depths[1].Categories : depths[1]),(depth0 != null ? depth0.FormCategoryID : depth0),{"name":"SelectFieldCategoryID","hash":{},"data":data})) != null ? stack1 : "")
    + "                            \r\n                                            </select>\r\n                                        </label>                                        \r\n                                    </td>\r\n                                </tr>\r\n                                <tr>\r\n                                    <td width=\"50%\">\r\n                                        <label>\r\n                                            <span>Field Type: </span>\r\n                                            <select class=\"fieldType fieldTypeSelect\" name=\"FieldType\">\r\n                                                <option value=\"1\" "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias1).call(depth0,1,(depth0 != null ? depth0.FieldType : depth0),{"name":"compare","hash":{"operator":"=="},"fn":this.program(8, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ">Regular</option>\r\n                                                <option value=\"2\" "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias1).call(depth0,2,(depth0 != null ? depth0.FieldType : depth0),{"name":"compare","hash":{"operator":"=="},"fn":this.program(8, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ">Calculated</option>\r\n                                                <option value=\"3\" "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias1).call(depth0,3,(depth0 != null ? depth0.FieldType : depth0),{"name":"compare","hash":{"operator":"=="},"fn":this.program(8, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ">Date</option>\r\n                                                <option value=\"4\" "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias1).call(depth0,4,(depth0 != null ? depth0.FieldType : depth0),{"name":"compare","hash":{"operator":"=="},"fn":this.program(8, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ">Dropdown</option>                                   \r\n                                                <option value=\"5\" "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias1).call(depth0,5,(depth0 != null ? depth0.FieldType : depth0),{"name":"compare","hash":{"operator":"=="},"fn":this.program(8, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ">Text Box</option>\r\n                                                <option value=\"6\" "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias1).call(depth0,6,(depth0 != null ? depth0.FieldType : depth0),{"name":"compare","hash":{"operator":"=="},"fn":this.program(8, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ">HTML (readonly)</option>\r\n                                                <option value=\"7\" "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias1).call(depth0,7,(depth0 != null ? depth0.FieldType : depth0),{"name":"compare","hash":{"operator":"=="},"fn":this.program(8, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ">Attachment</option>\r\n                                                <option value=\"8\" "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias1).call(depth0,8,(depth0 != null ? depth0.FieldType : depth0),{"name":"compare","hash":{"operator":"=="},"fn":this.program(8, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ">Multiselect Dropdown</option>\r\n                                            </select>\r\n                                        </label>              \r\n                                    \r\n                                    </td>\r\n                                    <td width=\"50%\">\r\n                                        <div class=\"fieldTypeOptions "
    + alias3((helpers.SelectActiveFieldType || (depth0 && depth0.SelectActiveFieldType) || alias1).call(depth0,(depth0 != null ? depth0.FieldType : depth0),1,{"name":"SelectActiveFieldType","hash":{},"data":data}))
    + "\">\r\n                                            <label>\r\n                                                <span class=\"fieldTypeHeader\">Validator Type: </span><br />\r\n                                                <select class=\"fieldTypeSelect fieldValidation\" name=\"FieldLogic\">\r\n"
    + ((stack1 = helpers.each.call(depth0,(depths[1] != null ? depths[1].ContentTypes : depths[1]),{"name":"each","hash":{},"fn":this.program(10, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "                                                </select>\r\n                                            </label>\r\n                                        </div>\r\n                                        <div class=\"fieldTypeOptions "
    + alias3((helpers.SelectActiveFieldType || (depth0 && depth0.SelectActiveFieldType) || alias1).call(depth0,(depth0 != null ? depth0.FieldType : depth0),2,{"name":"SelectActiveFieldType","hash":{},"data":data}))
    + "\">\r\n                                            <label><span class=\"fieldTypeHeader\">Calculated: </span><br /><textarea class=\"fieldCalculated fieldTypeText\" maxlength=\"490\">"
    + alias3(((helper = (helper = helpers.FieldLogic || (depth0 != null ? depth0.FieldLogic : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"FieldLogic","hash":{},"data":data}) : helper)))
    + "</textarea></label><br />\r\n                                            <span class=\"htmlRemainingLength\">490</span> out of 490 characters remaining.\r\n                                        </div>\r\n                                        <div class=\"fieldTypeOptions "
    + alias3((helpers.SelectActiveFieldType || (depth0 && depth0.SelectActiveFieldType) || alias1).call(depth0,(depth0 != null ? depth0.FieldType : depth0),3,{"name":"SelectActiveFieldType","hash":{},"data":data}))
    + "\">\r\n                                            <label><span class=\"fieldTypeHeader\">Date: </span><span>A date picker will be placed for the user to select a date.</span></label>\r\n                                        </div>\r\n                                        <div class=\"fieldTypeOptions "
    + alias3((helpers.SelectActiveFieldType || (depth0 && depth0.SelectActiveFieldType) || alias1).call(depth0,(depth0 != null ? depth0.FieldType : depth0),4,{"name":"SelectActiveFieldType","hash":{},"data":data}))
    + "\">\r\n                                            <label><span class=\"fieldTypeHeader\">Drop Down Source: </span><br /><textarea class=\"fieldDropDownSource fieldTypeText\" name=\"FieldLogic\" title=\"You can use ';' to delimit options. Ex: Y; N; NA. In addition you can use simple SQL statements to pull question key and values from the server to fill the drop down source list. Ex: select col1 (col1 will be both value and display) from table, also - select col1, col2 (col1 will be display where as col2 will be the value saved when selected) from table.\" maxlength=\"490\">"
    + alias3(((helper = (helper = helpers.FieldLogic || (depth0 != null ? depth0.FieldLogic : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"FieldLogic","hash":{},"data":data}) : helper)))
    + "</textarea></label><br />\r\n                                            <span class=\"htmlRemainingLength\">490</span> out of 490 characters remaining.\r\n                                        </div>\r\n                                        <div class=\"fieldTypeOptions "
    + alias3((helpers.SelectActiveFieldType || (depth0 && depth0.SelectActiveFieldType) || alias1).call(depth0,(depth0 != null ? depth0.FieldType : depth0),5,{"name":"SelectActiveFieldType","hash":{},"data":data}))
    + "\">\r\n                                            <label><span class=\"fieldTypeHeader\">Text Box: </span><span>A text box will be placed for the user to fill in a short response.</span></label>\r\n                                        </div>                                                         \r\n                                        <div class=\"fieldTypeOptions "
    + alias3((helpers.SelectActiveFieldType || (depth0 && depth0.SelectActiveFieldType) || alias1).call(depth0,(depth0 != null ? depth0.FieldType : depth0),6,{"name":"SelectActiveFieldType","hash":{},"data":data}))
    + "\">\r\n                                            <label><span class=\"fieldTypeHeader\">HTML: </span><br /><textarea class=\"fieldTypeHTML fieldTypeText\" name=\"FieldLogic\" maxlength=\"490\">"
    + alias3(((helper = (helper = helpers.FieldLogic || (depth0 != null ? depth0.FieldLogic : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"FieldLogic","hash":{},"data":data}) : helper)))
    + "</textarea></label><br />\r\n                                            <span class=\"htmlRemainingLength\">490</span> out of 490 characters remaining.\r\n                                        </div>\r\n                                        <div class=\"fieldTypeOptions "
    + alias3((helpers.SelectActiveFieldType || (depth0 && depth0.SelectActiveFieldType) || alias1).call(depth0,(depth0 != null ? depth0.FieldType : depth0),7,{"name":"SelectActiveFieldType","hash":{},"data":data}))
    + "\">                              \r\n                                            <label>                                                \r\n                                                <span class=\"fieldTypeHeader\">Attachment Question: </span><br />\r\n                                                <span></span><textarea class=\"fieldTypeAttachment fieldTypeText\" name=\"FieldLogic\" maxlength=\"490\">"
    + alias3(((helper = (helper = helpers.FieldLogic || (depth0 != null ? depth0.FieldLogic : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"FieldLogic","hash":{},"data":data}) : helper)))
    + "</textarea>\r\n                                            </label><br />\r\n                                            <span class=\"htmlRemainingLength\">490</span> out of 490 characters remaining.<br />\r\n                                            <label><span class=\"fieldTypeHeader\">Attachment: </span><span>An attachment dropzone will be displayed for a user to drag & drop an attachment into.</span></label>                                 \r\n                                        </div>\r\n                                        <div class=\"fieldTypeOptions "
    + alias3((helpers.SelectActiveFieldType || (depth0 && depth0.SelectActiveFieldType) || alias1).call(depth0,(depth0 != null ? depth0.FieldType : depth0),8,{"name":"SelectActiveFieldType","hash":{},"data":data}))
    + "\">\r\n                                            <label><span class=\"fieldTypeHeader\">Drop Down Source: </span><br /><textarea class=\"fieldDropDownSource fieldTypeText\" name=\"FieldLogic\" title=\"You can use ';' to delimit options. Ex: Y; N; NA. In addition you can use simple SQL statements to pull question key and values from the server to fill the drop down source list. Ex: select col1 (col1 will be both value and display) from table, also - select col1, col2 (col1 will be display where as col2 will be the value saved when selected) from table.\" maxlength=\"490\">"
    + alias3(((helper = (helper = helpers.FieldLogic || (depth0 != null ? depth0.FieldLogic : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"FieldLogic","hash":{},"data":data}) : helper)))
    + "</textarea></label><br />\r\n                                            <span class=\"htmlRemainingLength\">490</span> out of 490 characters remaining.\r\n                                        </div>\r\n                                    </td>\r\n                                </tr>\r\n                                <tr>\r\n                                    <td width=\"50%\">\r\n                                        <label>\r\n                                            <span>Data Type: </span>\r\n                                            <select class=\"fieldDataType fieldTypeSelect\" name=\"DataType\" "
    + ((stack1 = (helpers.IfFieldTypeID || (depth0 && depth0.IfFieldTypeID) || alias1).call(depth0,(depth0 != null ? depth0.FieldType : depth0),{"name":"IfFieldTypeID","hash":{},"data":data})) != null ? stack1 : "")
    + ">\r\n                                                <option value=\"1\" "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias1).call(depth0,1,(depth0 != null ? depth0.DataType : depth0),{"name":"compare","hash":{"operator":"=="},"fn":this.program(8, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ">String</option>\r\n                                                <option value=\"2\" "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias1).call(depth0,2,(depth0 != null ? depth0.DataType : depth0),{"name":"compare","hash":{"operator":"=="},"fn":this.program(8, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ">Date</option>\r\n                                                <option value=\"3\" "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias1).call(depth0,3,(depth0 != null ? depth0.DataType : depth0),{"name":"compare","hash":{"operator":"=="},"fn":this.program(8, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ">Decimal</option>\r\n                                                <option value=\"5\" "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias1).call(depth0,5,(depth0 != null ? depth0.DataType : depth0),{"name":"compare","hash":{"operator":"=="},"fn":this.program(8, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ">Integer</option>\r\n                                            </select>\r\n                                        </label> <label><span>Size: </span><input class=\"fieldDataSize fieldTypeText\" name=\"DataSize\" type=\"number\" "
    + ((stack1 = (helpers.IfFieldTypeID || (depth0 && depth0.IfFieldTypeID) || alias1).call(depth0,(depth0 != null ? depth0.FieldType : depth0),{"name":"IfFieldTypeID","hash":{},"data":data})) != null ? stack1 : "")
    + " /></label>                                                                              \r\n                                    </td>\r\n                                    <td width=\"50%\">\r\n                                        <label><span>Display Format: </span><input class=\"fieldDisplayFormat\" type=\"text\" "
    + ((stack1 = (helpers.IfFieldTypeID || (depth0 && depth0.IfFieldTypeID) || alias1).call(depth0,(depth0 != null ? depth0.FieldType : depth0),{"name":"IfFieldTypeID","hash":{},"data":data})) != null ? stack1 : "")
    + " /></label>\r\n                                        <label><span>Summarized?: </span><input class=\"fieldIsSummarized fieldTypeCheckbox\" name=\"Summarize\" type=\"checkbox\" "
    + ((stack1 = (helpers.IfFieldTypeID || (depth0 && depth0.IfFieldTypeID) || alias1).call(depth0,(depth0 != null ? depth0.FieldType : depth0),{"name":"IfFieldTypeID","hash":{},"data":data})) != null ? stack1 : "")
    + " /></label>                                                                              \r\n                                    </td>\r\n                                </tr>\r\n                                <tr>\r\n                                    <td width=\"50%\">\r\n"
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias1).call(depth0,(depth0 != null ? depth0.CreateUser : depth0),null,{"name":"compare","hash":{"operator":"!="},"fn":this.program(13, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "                                    </td>\r\n                                    <td width=\"50%\">\r\n                                        <div class=\"fieldButtonOptions\">\r\n                                            <input class=\"fieldRevert\" type=\"button\" value=\" Revert \" disabled=\"disabled\" title=\"This option is not yet implemented.\"/> <input class=\"fieldDelete\" type=\"button\" value=\" Delete \" />\r\n                                        </div>\r\n                                    </td>\r\n                                </tr>\r\n                            </tbody>\r\n                        </table>\r\n                    </div>\r\n                </div>                  \r\n        </tr>        \r\n";
},"2":function(depth0,helpers,partials,data) {
    return "                        <option value=\"Active\">Active</option>\r\n                        <option value=\"Inactive\">Inactive</option>\r\n";
},"4":function(depth0,helpers,partials,data) {
    return " checked=\"checked\" ";
},"6":function(depth0,helpers,partials,data) {
    return "fieldMasterDetailShow";
},"8":function(depth0,helpers,partials,data) {
    return " selected ";
},"10":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=this.lambda, alias2=this.escapeExpression;

  return "                                                    <option value=\""
    + alias2(alias1((depth0 != null ? depth0.CssClass : depth0), depth0))
    + "\" "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.ContentTypeID : depth0),(depths[1] != null ? depths[1].FieldLogic : depths[1]),{"name":"compare","hash":{"operator":"=="},"fn":this.program(8, data, 0, blockParams, depths),"inverse":this.program(11, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + ">"
    + alias2(alias1((depth0 != null ? depth0.CssClass : depth0), depth0))
    + "</option>\r\n";
},"11":function(depth0,helpers,partials,data) {
    return "  ";
},"13":function(depth0,helpers,partials,data) {
    var helper, alias1=helpers.helperMissing, alias2="function", alias3=this.escapeExpression;

  return "                                        <span>Created By: "
    + alias3(((helper = (helper = helpers.CreateUser || (depth0 != null ? depth0.CreateUser : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"CreateUser","hash":{},"data":data}) : helper)))
    + " - Last Updated By: "
    + alias3(((helper = (helper = helpers.UpdateUser || (depth0 != null ? depth0.UpdateUser : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"UpdateUser","hash":{},"data":data}) : helper)))
    + " on "
    + alias3((helpers.CHSIDate || (depth0 && depth0.CHSIDate) || alias1).call(depth0,(depth0 != null ? depth0.UpdateTmsp : depth0),{"name":"CHSIDate","hash":{},"data":data}))
    + "</span>\r\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return "<table width=\"100%\" class=\"sortableFormTypeEditTable dtable sortable fieldTable\">\r\n    <thead>\r\n        <tr>\r\n            <td>&nbsp;</td>\r\n            <td>Field Name</td>\r\n            <td>Long Name</td>\r\n            <td>Status</td>\r\n            <td>&nbsp;</td>\r\n            <td>&nbsp;</td>\r\n            <td>&nbsp;</td>\r\n            <td>&nbsp;</td>\r\n        </tr>\r\n    </thead>\r\n    <tbody>\r\n"
    + ((stack1 = helpers.each.call(depth0,(depth0 != null ? depth0.Fields : depth0),{"name":"each","hash":{},"fn":this.program(1, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "    </tbody>\r\n</table>";
},"useData":true,"useDepths":true});