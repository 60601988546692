this["CHSITemplates"]["ClaimManagement"]["TreatmentModalView"] = Handlebars.template({"1":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return "                <option "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || helpers.helperMissing).call(depth0,depth0,((stack1 = (depths[1] != null ? depths[1].Treatment : depths[1])) != null ? stack1.TreatmentStatus : stack1),{"name":"compare","hash":{},"fn":this.program(2, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ">"
    + this.escapeExpression(this.lambda(depth0, depth0))
    + "</option>\r\n";
},"2":function(depth0,helpers,partials,data) {
    return " selected";
},"4":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return "                <option "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || helpers.helperMissing).call(depth0,depth0,((stack1 = ((stack1 = (depths[1] != null ? depths[1].Treatment : depths[1])) != null ? stack1.Provider : stack1)) != null ? stack1.ProviderType : stack1),{"name":"compare","hash":{},"fn":this.program(2, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ">"
    + this.escapeExpression(this.lambda(depth0, depth0))
    + "</option>\r\n";
},"6":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return "                <option "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || helpers.helperMissing).call(depth0,depth0,((stack1 = (depths[1] != null ? depths[1].Treatment : depths[1])) != null ? stack1.TreatmentType : stack1),{"name":"compare","hash":{},"fn":this.program(2, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ">"
    + this.escapeExpression(this.lambda(depth0, depth0))
    + "</option>\r\n";
},"8":function(depth0,helpers,partials,data) {
    return " disabled ";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=this.lambda, alias2=this.escapeExpression;

  return "<div class=\"popup-form\">\r\n    <div class=\"group-container col-1\">\r\n        <div class=\"input-container col-2\">\r\n            <label>Provider Name</label>\r\n            <input type=\"text\" id=\"ProviderName\" class=\"popup-form-input\" value=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.Treatment : depth0)) != null ? stack1.Provider : stack1)) != null ? stack1.ProviderName : stack1), depth0))
    + "\" />\r\n        </div>\r\n        <div class=\"input-container col-2\">\r\n            <label>Status Type</label>\r\n            <select id=\"TreatmentStatus\" class=\"popup-form-input\">\r\n"
    + ((stack1 = helpers.each.call(depth0,((stack1 = (depth0 != null ? depth0.Config : depth0)) != null ? stack1.TreatmentStatus : stack1),{"name":"each","hash":{},"fn":this.program(1, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "            </select>\r\n        </div>\r\n        <div class=\"input-container col-2\">\r\n            <label>Provider Type</label>\r\n            <select id=\"ProviderType\" class=\"popup-form-input\">\r\n"
    + ((stack1 = helpers.each.call(depth0,((stack1 = (depth0 != null ? depth0.Config : depth0)) != null ? stack1.ProviderType : stack1),{"name":"each","hash":{},"fn":this.program(4, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "            </select>\r\n        </div>\r\n        <div class=\"input-container col-2\">\r\n            <label>Treatment Type</label>\r\n            <select id=\"TreatmentType\" class=\"popup-form-input\">\r\n"
    + ((stack1 = helpers.each.call(depth0,((stack1 = (depth0 != null ? depth0.Config : depth0)) != null ? stack1.TreatmentType : stack1),{"name":"each","hash":{},"fn":this.program(6, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "            </select>\r\n        </div>\r\n        <div class=\"input-container col-1\">\r\n            <label>Address</label>\r\n            <input maxlength=\"100\" type=\"text\" id=\"Address1\" class=\"popup-form-input\" value=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.Treatment : depth0)) != null ? stack1.Provider : stack1)) != null ? stack1.Address1 : stack1), depth0))
    + "\" />\r\n        </div>\r\n        <div class=\"input-container col-1\">\r\n            <input maxlength=\"100\" type=\"text\" id=\"Address2\" class=\"popup-form-input\" value=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.Treatment : depth0)) != null ? stack1.Provider : stack1)) != null ? stack1.Address2 : stack1), depth0))
    + "\" />\r\n        </div>\r\n        <div class=\"input-container col-2\">\r\n            <label>City</label>\r\n            <input maxlength=\"50\" type=\"text\" id=\"City\" class=\"popup-form-input\" value=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.Treatment : depth0)) != null ? stack1.Provider : stack1)) != null ? stack1.City : stack1), depth0))
    + "\" />\r\n        </div>\r\n        <div class=\"input-container col-4\">\r\n            <label>State</label>\r\n            <input maxlength=\"2\" type=\"text\" id=\"State\" class=\"popup-form-input\" value=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.Treatment : depth0)) != null ? stack1.Provider : stack1)) != null ? stack1.State : stack1), depth0))
    + "\" />\r\n        </div>\r\n        <div class=\"input-container col-4\">\r\n            <label>Zip</label>\r\n            <input maxlength=\"10\" type=\"text\" id=\"Zip\" class=\"popup-form-input\" value=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.Treatment : depth0)) != null ? stack1.Provider : stack1)) != null ? stack1.Zip : stack1), depth0))
    + "\" />\r\n        </div>\r\n        <div class=\"input-container col-1\">\r\n            <label>Primary Phone</label>\r\n            <input maxlength=\"10\" type=\"text\" id=\"Phone\" class=\"popup-form-input\" value=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.Treatment : depth0)) != null ? stack1.Provider : stack1)) != null ? stack1.Phone : stack1), depth0))
    + "\" />\r\n        </div>\r\n        <div class=\"input-container col-1\">\r\n            <label>Tax ID</label>\r\n            <input maxlength=\"100\" type=\"text\" id=\"TaxID\" class=\"popup-form-input\" value=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.Treatment : depth0)) != null ? stack1.Provider : stack1)) != null ? stack1.TaxID : stack1), depth0))
    + "\" />\r\n        </div>\r\n        <div class=\"input-container col-1\">\r\n            <label>Restrictions</label>\r\n            <textarea id=\"Restrictions\" class=\"popup-form-textarea\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.Treatment : depth0)) != null ? stack1.Restrictions : stack1), depth0))
    + "</textarea>\r\n        </div>\r\n        <div class=\"input-container col-1\">\r\n            <label>Comments</label>\r\n            <textarea id=\"Comments\" class=\"popup-form-textarea\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.Treatment : depth0)) != null ? stack1.Comments : stack1), depth0))
    + "</textarea>\r\n        </div>\r\n    </div>\r\n</div>\r\n<div class=\"btn-bar\">\r\n    <input class=\"treatment-delete edit\" value=\"Delete\" type=\"button\" style=\"float:left;\" "
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.isNew : depth0),{"name":"if","hash":{},"fn":this.program(8, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + " />\r\n    <input class=\"treatment-cancel\" value=\"Cancel\" type=\"button\" style=\"float:right;\" />\r\n    <input class=\"treatment-save edit\" value=\"Save\" type=\"button\" style=\"float:right;margin-right:20px\" />\r\n</div> ";
},"useData":true,"useDepths":true});