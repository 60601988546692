this["CHSITemplates"]["CRM_DistributionListList"] = Handlebars.template({"1":function(depth0,helpers,partials,data) {
    var stack1, helper, alias1=helpers.helperMissing, alias2="function", alias3=this.escapeExpression;

  return ((stack1 = helpers.unless.call(depth0,(data && data.index),{"name":"unless","hash":{},"fn":this.program(2, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "        <tr>\r\n            <td><img src=\"../../images/Icons/ListType/ListType"
    + alias3(((helper = (helper = helpers.ListTypeID || (depth0 != null ? depth0.ListTypeID : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"ListTypeID","hash":{},"data":data}) : helper)))
    + ".png\" /></td>\r\n            <td>"
    + alias3(((helper = (helper = helpers.ListName || (depth0 != null ? depth0.ListName : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"ListName","hash":{},"data":data}) : helper)))
    + " - <b>("
    + alias3(((helper = (helper = helpers.CreateUser || (depth0 != null ? depth0.CreateUser : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"CreateUser","hash":{},"data":data}) : helper)))
    + ")</b></td>\r\n            <td class=\"DStatus\">"
    + alias3(((helper = (helper = helpers.Status || (depth0 != null ? depth0.Status : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"Status","hash":{},"data":data}) : helper)))
    + "</td>\r\n            <td>"
    + alias3(((helper = (helper = helpers.MemberCount || (depth0 != null ? depth0.MemberCount : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"MemberCount","hash":{},"data":data}) : helper)))
    + "</td>\r\n             \r\n            <td>"
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.AllowEmail : depth0),{"name":"if","hash":{},"fn":this.program(4, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "</td>\r\n            <td>"
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.AllowPost : depth0),{"name":"if","hash":{},"fn":this.program(9, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "</td>\r\n            <td>"
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.AllowPhone : depth0),{"name":"if","hash":{},"fn":this.program(11, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "</td>\r\n            <td>"
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.AllowFax : depth0),{"name":"if","hash":{},"fn":this.program(13, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "</td>\r\n            <td>[<a class=\"minilink\" href=\"javascript:ShowDistributionListAdmin("
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.ListID : depth0),{"name":"if","hash":{},"fn":this.program(5, data, 0),"inverse":this.program(7, data, 0),"data":data})) != null ? stack1 : "")
    + ");\">edit</a>]</td>\r\n        </tr>\r\n";
},"2":function(depth0,helpers,partials,data) {
    return "    <thead>\r\n        <tr>\r\n            <td>Type</td>\r\n            <td>Distrubution List</td>\r\n            <td class=\"DStatus\">Status</td>\r\n            <td>Membership</td>\r\n            <td class=\"sorttable_nosort\">Email</td>\r\n            <td class=\"sorttable_nosort\">Post</td>\r\n            <td class=\"sorttable_nosort\">Phone</td>\r\n            <td class=\"sorttable_nosort\">Fax</td>\r\n            <td class=\"sorttable_nosort\">&nbsp</td>\r\n        </tr>\r\n    </thead>\r\n    <tbody>\r\n";
},"4":function(depth0,helpers,partials,data) {
    var stack1, helper, alias1=helpers.helperMissing, alias2="function", alias3=this.escapeExpression;

  return "<a href=\"javascript:ShowEmail("
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.ListID : depth0),{"name":"if","hash":{},"fn":this.program(5, data, 0),"inverse":this.program(7, data, 0),"data":data})) != null ? stack1 : "")
    + ","
    + alias3(((helper = (helper = helpers.ListTypeID || (depth0 != null ? depth0.ListTypeID : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"ListTypeID","hash":{},"data":data}) : helper)))
    + ", '"
    + alias3(((helper = (helper = helpers.ListSource || (depth0 != null ? depth0.ListSource : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"ListSource","hash":{},"data":data}) : helper)))
    + "',0);\"><img src=\"../../images/emailicon.gif\" /></a> ";
},"5":function(depth0,helpers,partials,data) {
    var helper;

  return this.escapeExpression(((helper = (helper = helpers.ListID || (depth0 != null ? depth0.ListID : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"ListID","hash":{},"data":data}) : helper)));
},"7":function(depth0,helpers,partials,data) {
    return "null";
},"9":function(depth0,helpers,partials,data) {
    var stack1, helper, alias1=helpers.helperMissing, alias2="function", alias3=this.escapeExpression;

  return "<a href=\"javascript:ShowPost("
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.ListID : depth0),{"name":"if","hash":{},"fn":this.program(5, data, 0),"inverse":this.program(7, data, 0),"data":data})) != null ? stack1 : "")
    + ","
    + alias3(((helper = (helper = helpers.ListTypeID || (depth0 != null ? depth0.ListTypeID : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"ListTypeID","hash":{},"data":data}) : helper)))
    + ", '"
    + alias3(((helper = (helper = helpers.ListSource || (depth0 != null ? depth0.ListSource : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"ListSource","hash":{},"data":data}) : helper)))
    + "',1);\"><img src=\"../../images/envelope.gif\" /></a> ";
},"11":function(depth0,helpers,partials,data) {
    var stack1, helper, alias1=helpers.helperMissing, alias2="function", alias3=this.escapeExpression;

  return "<a href=\"javascript:ShowPhone("
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.ListID : depth0),{"name":"if","hash":{},"fn":this.program(5, data, 0),"inverse":this.program(7, data, 0),"data":data})) != null ? stack1 : "")
    + ","
    + alias3(((helper = (helper = helpers.ListTypeID || (depth0 != null ? depth0.ListTypeID : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"ListTypeID","hash":{},"data":data}) : helper)))
    + ", '"
    + alias3(((helper = (helper = helpers.ListSource || (depth0 != null ? depth0.ListSource : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"ListSource","hash":{},"data":data}) : helper)))
    + "',2);\"><img src=\"../../images/phone.gif\" /></a> ";
},"13":function(depth0,helpers,partials,data) {
    var stack1, helper, alias1=helpers.helperMissing, alias2="function", alias3=this.escapeExpression;

  return "<a href=\"javascript:ShowFax("
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.ListID : depth0),{"name":"if","hash":{},"fn":this.program(5, data, 0),"inverse":this.program(7, data, 0),"data":data})) != null ? stack1 : "")
    + ","
    + alias3(((helper = (helper = helpers.ListTypeID || (depth0 != null ? depth0.ListTypeID : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"ListTypeID","hash":{},"data":data}) : helper)))
    + ", '"
    + alias3(((helper = (helper = helpers.ListSource || (depth0 != null ? depth0.ListSource : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"ListSource","hash":{},"data":data}) : helper)))
    + "',3);\"><img src=\"../../images/printicon.gif\" /></a> ";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1;

  return "<table width=\"100%\" class=\"dtable sortable\">\r\n"
    + ((stack1 = helpers.each.call(depth0,(depth0 != null ? depth0.DistributionList : depth0),{"name":"each","hash":{},"fn":this.program(1, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "    </tbody>\r\n</table>";
},"useData":true});