this["CHSITemplates"]["ClaimIntakeForm_Finish"] = Handlebars.template({"1":function(depth0,helpers,partials,data) {
    var stack1;

  return "<table>\r\n    <tr>\r\n        <td>            \r\n        </td> \r\n        <td>\r\n            <h3>FINISH</h3>\r\n        </td>\r\n        <td>\r\n        </td>\r\n    </tr>\r\n    <tr>\r\n        <td>\r\n            &nbsp;\r\n        </td>\r\n    </tr>\r\n    <tr>\r\n        <td colspan=\"2\" style=\" text-align: justify;\">\r\n"
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.FinishInstructions : depth0),{"name":"if","hash":{},"fn":this.program(2, data, 0),"inverse":this.program(4, data, 0),"data":data})) != null ? stack1 : "")
    + "        </td>\r\n    </tr>\r\n    <tr>\r\n        <td>\r\n            &nbsp;\r\n        </td>\r\n    </tr>\r\n    <tr>\r\n        <td style=\"width: 40%\">\r\n            <b>Form completed by*</b>\r\n        </td>\r\n        <td>\r\n            <input style=\"width: 200px;\" type=\"text\" id=\"jxCompletedBy\" value=\"\" />\r\n        </td>\r\n    </tr>\r\n    <tr>\r\n        <td style=\"width: 40%\">\r\n            <b>Title</b>\r\n        </td>\r\n        <td>\r\n            <input style=\"width: 200px;\" type=\"text\" id=\"jxTitle\" value=\"\" />\r\n        </td>\r\n    </tr>\r\n    <tr>\r\n        <td style=\"width: 40%\">\r\n            <b>If this report is being filed late, please select the reason</b>\r\n        </td>\r\n        <td>\r\n            <select style=\"width: 205px;\" id=\"jxLate\">\r\n"
    + ((stack1 = helpers.each.call(depth0,(depth0 != null ? depth0.LateReasons : depth0),{"name":"each","hash":{},"fn":this.program(6, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "            </select>\r\n        </td>\r\n    </tr>\r\n    <tr>\r\n        <!--<td valign=\"top\">\r\n            <b>You can review and or remove all attachments associated with this form.</b>\r\n        </td>-->\r\n        <td>\r\n            <div id=\"reviewAttachmentsContainer\">\r\n"
    + ((stack1 = helpers.each.call(depth0,(depth0 != null ? depth0.Attachments : depth0),{"name":"each","hash":{},"fn":this.program(8, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "            </div>\r\n            \r\n        </td>\r\n    </tr>\r\n    <tr>        \r\n        <td colspan=\"2\">\r\n"
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.FinishInstructions : depth0),{"name":"if","hash":{},"fn":this.program(10, data, 0),"inverse":this.program(12, data, 0),"data":data})) != null ? stack1 : "")
    + "        </td>\r\n    </tr>\r\n</table>\r\n";
},"2":function(depth0,helpers,partials,data) {
    var stack1, helper;

  return "            "
    + ((stack1 = ((helper = (helper = helpers.FinishInstructions || (depth0 != null ? depth0.FinishInstructions : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"FinishInstructions","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "            \r\n";
},"4":function(depth0,helpers,partials,data) {
    return "            Once you click the Finish button, the claim report is automatically sent to the TPA for processing. You will no longer be able to make changes to this report. If you need to make a change, you will need to follow the procedures defined by the state for making modifications to existing claims. After clicking Finish, the View Claims tab displays allowing you to view and print the claim form.\r\n";
},"6":function(depth0,helpers,partials,data) {
    var helper, alias1=helpers.helperMissing, alias2="function", alias3=this.escapeExpression;

  return "                <option value=\""
    + alias3(((helper = (helper = helpers.LateCode || (depth0 != null ? depth0.LateCode : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"LateCode","hash":{},"data":data}) : helper)))
    + "\">"
    + alias3(((helper = (helper = helpers.LateDescription || (depth0 != null ? depth0.LateDescription : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"LateDescription","hash":{},"data":data}) : helper)))
    + "</option>\r\n";
},"8":function(depth0,helpers,partials,data) {
    var alias1=this.lambda, alias2=this.escapeExpression;

  return "                <div class=\"attachmentInformation\">\r\n                    <div class=\"attachmentName\">"
    + alias2(alias1((depth0 != null ? depth0.FileName : depth0), depth0))
    + "</div>\r\n                    <span class=\"attachmentDelete ui-icon ui-icon-close\" data-fileid=\""
    + alias2(alias1((depth0 != null ? depth0.FileID : depth0), depth0))
    + "\" data-formfieldid=\""
    + alias2(alias1((depth0 != null ? depth0.FormFieldID : depth0), depth0))
    + "\"></span>\r\n                    <div class=\"loading-icon\"></div>\r\n                </div>\r\n";
},"10":function(depth0,helpers,partials,data) {
    return "\r\n";
},"12":function(depth0,helpers,partials,data) {
    return "            <p>Once you submit this report it&nbsp;is automatically sent to the TPA and State for processing. You will not be able to make any changes to this report here, you will need to follow the <br />procedures defined by the State for making modifications to existing claims.&nbsp; </p><p>After clicking Finish, the View Claims page displays allowing you to print the claim form for your records.</p>\r\n";
},"14":function(depth0,helpers,partials,data) {
    return "<div class=\"submittedContainer\">\r\n    Form has been Submitted\r\n</div>\r\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1;

  return "<style>\r\n        .loading-icon {\r\n        background-size: 20px 20px;\r\n        background-repeat: no-repeat;\r\n        position: relative;\r\n        left: 203px;\r\n        bottom: 41px;\r\n        display:none;\r\n    }\r\n    .show-loading-icon {\r\n        display: inline-block !important;\r\n    }\r\n    .submittedContainer {\r\n        border: 1px solid black;\r\n        border-radius: 2px;\r\n        background-color: #B6FCB6;\r\n        width: 130px;\r\n        height: 40px;\r\n        padding: 15px;\r\n        margin: 0 auto;\r\n        margin-top: 220px;\r\n        font-size: large;\r\n        text-align: center;\r\n    }\r\n        .dynamicCategoryTitle{\r\n        margin: 0 auto;\r\n        text-align: center;\r\n    }\r\n    .attachmentContainerText{\r\n        padding-top: 30px;\r\n    }    \r\n    .attachmentInformation{\r\n        width: 200px;\r\n        height: 22px;\r\n        background-color: #E6E6E6;\r\n        border-radius: 3px;\r\n        text-align: center;\r\n        margin-left: 4px;\r\n        margin-bottom: 1px;\r\n    }\r\n    .attachmentInformation:hover {\r\n        background-color: #ededed;\r\n    }\r\n    .attachmentName{\r\n        text-align: left;\r\n        width: 185px;\r\n        line-height: 23px;\r\n        white-space: nowrap;\r\n        overflow: hidden;\r\n        text-overflow: ellipsis;\r\n        padding-left: 2px;\r\n    }\r\n    .attachmentDelete{\r\n        zoom: 1.2;\r\n        position: relative;\r\n        left: 150px;\r\n        bottom: 18px;\r\n    }\r\n    .attachmentDelete:hover{\r\n        cursor: pointer;\r\n    }\r\n    .attachmentInformationContainer{\r\n\r\n    }\r\n    .saving {\r\n        color: green;\r\n    }\r\n    .deleting {\r\n        color: red;\r\n    }\r\n</style>\r\n\r\n"
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.Status : depth0),"Submitted",{"name":"compare","hash":{"operator":"!="},"fn":this.program(1, data, 0),"inverse":this.program(14, data, 0),"data":data})) != null ? stack1 : "");
},"useData":true});