this["CHSITemplates"]["Template_DistributionPostList"] = Handlebars.template({"1":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.each.call(depth0,(depth0 != null ? depth0.DistributionList : depth0),{"name":"each","hash":{},"fn":this.program(2, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "");
},"2":function(depth0,helpers,partials,data) {
    var helper, alias1=helpers.helperMissing, alias2="function", alias3=this.escapeExpression;

  return "        <tr><td>"
    + alias3(((helper = (helper = helpers.Greeting || (depth0 != null ? depth0.Greeting : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"Greeting","hash":{},"data":data}) : helper)))
    + "</td><td>"
    + alias3(((helper = (helper = helpers.FirstName || (depth0 != null ? depth0.FirstName : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"FirstName","hash":{},"data":data}) : helper)))
    + "</td><td>"
    + alias3(((helper = (helper = helpers.LastName || (depth0 != null ? depth0.LastName : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"LastName","hash":{},"data":data}) : helper)))
    + "</td><td>"
    + alias3(((helper = (helper = helpers.Title || (depth0 != null ? depth0.Title : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"Title","hash":{},"data":data}) : helper)))
    + "</td><td>"
    + alias3(((helper = (helper = helpers.Client || (depth0 != null ? depth0.Client : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"Client","hash":{},"data":data}) : helper)))
    + "</td><td>"
    + alias3(((helper = (helper = helpers.Address1 || (depth0 != null ? depth0.Address1 : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"Address1","hash":{},"data":data}) : helper)))
    + "</td><td>"
    + alias3(((helper = (helper = helpers.Address2 || (depth0 != null ? depth0.Address2 : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"Address2","hash":{},"data":data}) : helper)))
    + "</td><td>"
    + alias3(((helper = (helper = helpers.City || (depth0 != null ? depth0.City : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"City","hash":{},"data":data}) : helper)))
    + "</td><td>"
    + alias3(((helper = (helper = helpers.State || (depth0 != null ? depth0.State : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"State","hash":{},"data":data}) : helper)))
    + "</td><td>"
    + alias3(((helper = (helper = helpers.Zip || (depth0 != null ? depth0.Zip : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"Zip","hash":{},"data":data}) : helper)))
    + "</td></tr>\r\n";
},"4":function(depth0,helpers,partials,data) {
    return "        No list to display.\r\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1;

  return "<table width=\"100%\" class=\"dtable sortable\">\r\n    <thead>\r\n        <tr>\r\n            <td>Greeting</td>\r\n            <td>First Name</td>\r\n            <td>Last Name</td>\r\n            <td>Title</td>\r\n            <td>Client</td>\r\n            <td>Address 1</td>\r\n            <td>Address 2</td>\r\n            <td>City</td>\r\n            <td>State</td>\r\n            <td>Zip</td>\r\n        </tr>\r\n    </thead>\r\n    <tbody>\r\n        \r\n"
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.DistributionList : depth0),{"name":"if","hash":{},"fn":this.program(1, data, 0),"inverse":this.program(4, data, 0),"data":data})) != null ? stack1 : "")
    + "    </tbody>\r\n</table>";
},"useData":true});