this["CHSITemplates"]["ClaimManagement"]["PaymentModalView"] = Handlebars.template({"1":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return "                <option "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || helpers.helperMissing).call(depth0,depth0,((stack1 = (depths[1] != null ? depths[1].Payment : depths[1])) != null ? stack1.Payee : stack1),{"name":"compare","hash":{},"fn":this.program(2, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ">"
    + this.escapeExpression(this.lambda(depth0, depth0))
    + "</option>\r\n";
},"2":function(depth0,helpers,partials,data) {
    return " selected";
},"4":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return "                <option "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || helpers.helperMissing).call(depth0,depth0,((stack1 = (depths[1] != null ? depths[1].Payment : depths[1])) != null ? stack1.ReserveType : stack1),{"name":"compare","hash":{},"fn":this.program(2, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ">"
    + this.escapeExpression(this.lambda(depth0, depth0))
    + "</option>\r\n";
},"6":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=this.lambda, alias2=this.escapeExpression;

  return "                <option value=\""
    + alias2(alias1((depth0 != null ? depth0.ClaimantID : depth0), depth0))
    + "\" "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.ClaimantID : depth0),((stack1 = (depths[1] != null ? depths[1].Payment : depths[1])) != null ? stack1.ClaimantID : stack1),{"name":"compare","hash":{},"fn":this.program(2, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ">"
    + alias2(alias1((depth0 != null ? depth0.FirstName : depth0), depth0))
    + " "
    + alias2(alias1((depth0 != null ? depth0.LastName : depth0), depth0))
    + "</option>\r\n";
},"8":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return "                <option "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || helpers.helperMissing).call(depth0,depth0,((stack1 = (depths[1] != null ? depths[1].Payment : depths[1])) != null ? stack1.PaymentCategory : stack1),{"name":"compare","hash":{},"fn":this.program(2, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ">"
    + this.escapeExpression(this.lambda(depth0, depth0))
    + "</option>\r\n";
},"10":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return "                <option "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || helpers.helperMissing).call(depth0,depth0,((stack1 = (depths[1] != null ? depths[1].Payment : depths[1])) != null ? stack1.PaymentMethod : stack1),{"name":"compare","hash":{},"fn":this.program(2, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ">"
    + this.escapeExpression(this.lambda(depth0, depth0))
    + "</option>\r\n";
},"12":function(depth0,helpers,partials,data) {
    return " checked";
},"14":function(depth0,helpers,partials,data) {
    return " disabled ";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=this.lambda, alias2=this.escapeExpression;

  return "<div class=\"popup-form payment-modal\">\r\n    <div class=\"group-container col-1\">\r\n        <div class=\"input-container col-2\">\r\n            <label>Date</label>\r\n            <input type=\"text\" id=\"PaymentDate\" class=\"popup-form-input date\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.Payment : depth0)) != null ? stack1.PaymentDate : stack1), depth0))
    + "\" />\r\n        </div>\r\n        <div class=\"input-container col-2\">\r\n            <label>Payee</label>\r\n            <input type=\"text\" id=\"PayeeName\" class=\"popup-form-input col-33\" value=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.Payment : depth0)) != null ? stack1.Payee : stack1)) != null ? stack1.Name : stack1), depth0))
    + "\" readonly/>\r\n            <!--<select id=\"PaymentMethod\" class=\"popup-form-input col-33\">\r\n"
    + ((stack1 = helpers.each.call(depth0,((stack1 = (depth0 != null ? depth0.Config : depth0)) != null ? stack1.Payees : stack1),{"name":"each","hash":{},"fn":this.program(1, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "            </select>-->\r\n            <input class=\"select-payees edit\" value=\"Select\" type=\"button\" style=\"width: 80px;\">\r\n        </div>\r\n        <div class=\"input-container col-2\">\r\n            <label>Reserve Type</label>\r\n            <select id=\"ReserveType\" class=\"popup-form-input\">\r\n"
    + ((stack1 = helpers.each.call(depth0,((stack1 = (depth0 != null ? depth0.Config : depth0)) != null ? stack1.ReserveType : stack1),{"name":"each","hash":{},"fn":this.program(4, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "            </select>\r\n        </div>\r\n        <div class=\"input-container col-2\">\r\n            <label>Claimant</label>\r\n            <select id=\"ClaimantID\" class=\"popup-form-input\">\r\n                <option value=\"0\">N/A</option>\r\n"
    + ((stack1 = helpers.each.call(depth0,(depth0 != null ? depth0.Claimants : depth0),{"name":"each","hash":{},"fn":this.program(6, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "            </select>\r\n        </div>\r\n        <div class=\"input-container col-2\">\r\n            <label>Payment Category</label>\r\n            <select id=\"PaymentCategory\" class=\"popup-form-input\">\r\n"
    + ((stack1 = helpers.each.call(depth0,((stack1 = (depth0 != null ? depth0.Config : depth0)) != null ? stack1.PaymentCategory : stack1),{"name":"each","hash":{},"fn":this.program(8, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "            </select>\r\n        </div>\r\n        <div class=\"input-container col-2\">\r\n            <label>Invoice #</label>\r\n            <input maxlength=\"100\" type=\"text\" id=\"InvoiceNumber\" class=\"popup-form-input\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.Payment : depth0)) != null ? stack1.InvoiceNumber : stack1), depth0))
    + "\" />\r\n        </div>\r\n        <div class=\"input-container col-2\">\r\n            <label>Reference #</label>\r\n            <input maxlength=\"100\" type=\"text\" id=\"ReferenceNumber\" class=\"popup-form-input\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.Payment : depth0)) != null ? stack1.ReferenceNumber : stack1), depth0))
    + "\" />\r\n        </div>\r\n        <div class=\"input-container col-2\">\r\n            <label>Method</label>\r\n            <select id=\"PaymentMethod\" class=\"popup-form-input\">\r\n"
    + ((stack1 = helpers.each.call(depth0,((stack1 = (depth0 != null ? depth0.Config : depth0)) != null ? stack1.PaymentMethod : stack1),{"name":"each","hash":{},"fn":this.program(10, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "            </select>\r\n        </div>\r\n        <div class=\"input-container col-4 center\" style=\"clear:both;\">\r\n            <label>Recovery?</label>\r\n            <input type=\"checkbox\" id=\"Recovery\" class=\"popup-form-input\" "
    + ((stack1 = helpers["if"].call(depth0,((stack1 = (depth0 != null ? depth0.Payment : depth0)) != null ? stack1.Recovery : stack1),{"name":"if","hash":{},"fn":this.program(12, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + " />\r\n        </div>\r\n        <div class=\"input-container col-4 center\">\r\n            <label>Cumulative?</label>\r\n            <input type=\"checkbox\" id=\"Cumulative\" class=\"popup-form-input\" />\r\n        </div>\r\n        <div class=\"input-container col-4 center\">\r\n            <label>Printed?</label>\r\n            <input type=\"checkbox\" id=\"Printed\" class=\"popup-form-input\" "
    + ((stack1 = helpers["if"].call(depth0,((stack1 = (depth0 != null ? depth0.Payment : depth0)) != null ? stack1.Printed : stack1),{"name":"if","hash":{},"fn":this.program(12, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + " />\r\n        </div>\r\n        <div class=\"input-container col-4 center\">\r\n            <label>Voided?</label>\r\n            <input type=\"checkbox\" id=\"Voided\" class=\"popup-form-input\" "
    + ((stack1 = helpers["if"].call(depth0,((stack1 = (depth0 != null ? depth0.Payment : depth0)) != null ? stack1.Voided : stack1),{"name":"if","hash":{},"fn":this.program(12, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + " />\r\n        </div>\r\n        <div class=\"input-container col-3\">\r\n            <label>Invoiced</label>\r\n            <input maxlength=\"100\" type=\"number\" id=\"InvoicedAmount\" class=\"popup-form-input\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.Payment : depth0)) != null ? stack1.InvoicedAmount : stack1), depth0))
    + "\" />\r\n        </div>\r\n        <div class=\"input-container col-3\">\r\n            <label>Discounted</label>\r\n            <input maxlength=\"100\" type=\"number\" id=\"DiscountAmount\" class=\"popup-form-input\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.Payment : depth0)) != null ? stack1.DiscountAmount : stack1), depth0))
    + "\" disabled />\r\n        </div>\r\n        <div class=\"input-container col-3\">\r\n            <label>Paid</label>\r\n            <input maxlength=\"100\" type=\"number\" id=\"PaidAmount\" class=\"popup-form-input\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.Payment : depth0)) != null ? stack1.PaidAmount : stack1), depth0))
    + "\" />\r\n        </div>\r\n        <div class=\"input-container col-1\">\r\n            <label>Comments</label>\r\n            <textarea id=\"Comments\" class=\"popup-form-textarea\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.Payment : depth0)) != null ? stack1.Comments : stack1), depth0))
    + "</textarea>\r\n        </div>\r\n    </div>\r\n</div>\r\n<div class=\"btn-bar payment-modal\">\r\n    <input class=\"payment-delete edit\" value=\"Delete\" type=\"button\" style=\"float:left;\" "
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.isNew : depth0),{"name":"if","hash":{},"fn":this.program(14, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + " />\r\n    <input class=\"payment-cancel\" value=\"Cancel\" type=\"button\" style=\"float:right;\" />\r\n    <input class=\"payment-save edit\" value=\"Save\" type=\"button\" style=\"float:right;margin-right:20px\" />\r\n</div>\r\n\r\n<!-- payee view -->\r\n<div class=\"popup-form payee-modal\" style=\"display:none\">\r\n    <div class=\"group-container col-1\">\r\n        <table id=\"dt-payees\" class=\"dtable\" style=\"width:100%\"></table>\r\n    </div>\r\n</div>\r\n<div class=\"btn-bar payee-modal\" style=\"display:none\">\r\n    <input class=\"payment-payee-cancel\" value=\"Cancel\" type=\"button\" style=\"float:right;\" /> \r\n    <input class=\"add-payee edit\" value=\"Add Payee\" type=\"button\" style=\"float:right;margin-right:20px\" />\r\n</div>";
},"useData":true,"useDepths":true});