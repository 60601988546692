this["CHSITemplates"]["Template_ReportImagePreview"] = Handlebars.template({"1":function(depth0,helpers,partials,data) {
    var helper;

  return "    <img id=\"jxReportImage\" src=\"/CHSIFileHandler.axd?fileid="
    + this.escapeExpression(((helper = (helper = helpers.PreviewImageID || (depth0 != null ? depth0.PreviewImageID : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"PreviewImageID","hash":{},"data":data}) : helper)))
    + "\" />\r\n";
},"3":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.PreviewImageURL : depth0),{"name":"if","hash":{},"fn":this.program(4, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "");
},"4":function(depth0,helpers,partials,data) {
    var helper;

  return "    <img id=\"Img1\" src=\""
    + this.escapeExpression(((helper = (helper = helpers.PreviewImageURL || (depth0 != null ? depth0.PreviewImageURL : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"PreviewImageURL","hash":{},"data":data}) : helper)))
    + "\" />\r\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1;

  return "<div id=\"jxImageContainer\">\r\n"
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.PreviewImageID : depth0),{"name":"if","hash":{},"fn":this.program(1, data, 0),"inverse":this.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "</div> ";
},"useData":true});