this["CHSITemplates"]["Template_Attachments"] = Handlebars.template({"1":function(depth0,helpers,partials,data) {
    var stack1;

  return "<table style=\"width: 406px;\">\r\n"
    + ((stack1 = helpers.each.call(depth0,(depth0 != null ? depth0.Attachments : depth0),{"name":"each","hash":{},"fn":this.program(2, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "</table>\r\n";
},"2":function(depth0,helpers,partials,data) {
    var helper, alias1=helpers.helperMissing, alias2="function", alias3=this.escapeExpression;

  return "    <tr>\r\n        <td>\r\n            <a href=\"/chsifilehandler.axd?FileID="
    + alias3(((helper = (helper = helpers.FileID || (depth0 != null ? depth0.FileID : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"FileID","hash":{},"data":data}) : helper)))
    + "\" target=\"_blank\" class=\"minilink\">"
    + alias3(this.lambda((depth0 != null ? depth0.FileName : depth0), depth0))
    + "</a>\r\n            <input type=\"hidden\" class=\"FileID\" value=\""
    + alias3(((helper = (helper = helpers.FileID || (depth0 != null ? depth0.FileID : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"FileID","hash":{},"data":data}) : helper)))
    + "\" />\r\n        </td>\r\n        <td style=\"text-align: right\">[<a href=\"javascript: ConfirmDeleteAttachment("
    + alias3(((helper = (helper = helpers.FileID || (depth0 != null ? depth0.FileID : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"FileID","hash":{},"data":data}) : helper)))
    + ");\" class=\"minilink\">delete</a>]</td>\r\n    </tr>\r\n";
},"4":function(depth0,helpers,partials,data) {
    return "            No attachments.\r\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.Attachments : depth0),{"name":"if","hash":{},"fn":this.program(1, data, 0),"inverse":this.program(4, data, 0),"data":data})) != null ? stack1 : "");
},"useData":true});