this["CHSITemplates"]["Template_SuperClaim"] = Handlebars.template({"1":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=helpers.helperMissing, alias2=this.escapeExpression, alias3="function";

  return "<table>\r\n    <tr>\r\n        <td class=\"FormLabel\">Status</td>\r\n        <td>\r\n            <select id=\"uxStatus\" onchange=\"CHSI.Page.ViewSuperClaim.EnableSelectClient();\">\r\n                <option value=\"Open\" "
    + alias2((helpers.selected || (depth0 && depth0.selected) || alias1).call(depth0,(depth0 != null ? depth0.Status : depth0),"open",{"name":"selected","hash":{},"data":data}))
    + ">Open</option>\r\n                <option value=\"Closed\" "
    + alias2((helpers.selected || (depth0 && depth0.selected) || alias1).call(depth0,(depth0 != null ? depth0.Status : depth0),"closed",{"name":"selected","hash":{},"data":data}))
    + ">Closed</option>\r\n            </select>\r\n        </td>\r\n    </tr>\r\n    <tr>\r\n        <td class=\"FormLabel\">Associated Client</td>\r\n        <td><span id=\"uxClientName\">"
    + alias2(((helper = (helper = helpers.ClientName || (depth0 != null ? depth0.ClientName : depth0)) != null ? helper : alias1),(typeof helper === alias3 ? helper.call(depth0,{"name":"ClientName","hash":{},"data":data}) : helper)))
    + "</span> <input type=\"hidden\" id=\"uxClientID\" value=\""
    + alias2(((helper = (helper = helpers.DefaultClientID || (depth0 != null ? depth0.DefaultClientID : depth0)) != null ? helper : alias1),(typeof helper === alias3 ? helper.call(depth0,{"name":"DefaultClientID","hash":{},"data":data}) : helper)))
    + "\" /> <input type=\"button\" value=\"Select Client\" id=\"uxSelectClient\" /></td>\r\n    </tr>\r\n    <tr>\r\n        <td>Description</td>\r\n        <td><input type=\"text\" value=\""
    + alias2(((helper = (helper = helpers.Description || (depth0 != null ? depth0.Description : depth0)) != null ? helper : alias1),(typeof helper === alias3 ? helper.call(depth0,{"name":"Description","hash":{},"data":data}) : helper)))
    + "\" maxlength=\"100\" id=\"uxDescription\" class=\"iRequired\" style=\"width:600px;\" /></td>\r\n    </tr>\r\n</table>\r\n\r\n<table>\r\n    <tr>\r\n        <td class=\"FormLabel\">To manually add a claim to this Super Claim enter the Claim Number and click the add button.</td>\r\n    </tr>\r\n\r\n</table>\r\n<table>\r\n    <tr>\r\n        <td class=\"FormLabel\">ClaimID</td>\r\n        <td><input type=\"text\" id=\"uxClaimID\" class=\"number-field iInteger iRequired\" maxlength=\"30\" /></td>\r\n        <td class=\"FormLabel\">Version</td>\r\n        <td>\r\n            <select id=\"uxVersion\">\r\n                <option value=\"0\">V1</option>\r\n                <option value=\"1\">V2</option>\r\n            </select>\r\n        </td>\r\n        <td><input type=\"button\" value=\"Add\" id=\"uxAddClaim\" onclick=\"CHSI.Page.ViewSuperClaim.SaveSuperClaim(null, true);\" /></td>\r\n    </tr>\r\n</table>\r\n<table id=\"ClaimsContainer\" width=\"100%\" class=\"dtable sortable\">\r\n    <thead>\r\n        <tr>\r\n            <td>Coverage</td>\r\n            <td>Client</td>\r\n            <td>Claim Number</td>\r\n            <td>Incident Date</td>\r\n            <td>Claimant</td>\r\n            <td>Status</td>\r\n            <td style=\"text-align:right;\">Incurred</td>\r\n            <td>&nbsp</td>\r\n            <td>&nbsp</td>\r\n        </tr>\r\n    </thead> \r\n    <tbody>\r\n"
    + ((stack1 = helpers.each.call(depth0,(depth0 != null ? depth0.Claims : depth0),{"name":"each","hash":{},"fn":this.program(2, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "        <tr><td>&nbsp</td></tr>\r\n        <tr><td>&nbsp</td></tr>\r\n        <tr>\r\n            <td></td>\r\n            <td></td>\r\n            <td></td>\r\n            <td></td>\r\n            <td></td>\r\n            <td>Total Incurred:</td>\r\n            <td style=\"text-align:right;\">"
    + alias2((helpers.FormatCurrency || (depth0 && depth0.FormatCurrency) || alias1).call(depth0,(depth0 != null ? depth0.TotalIncurred : depth0),true,{"name":"FormatCurrency","hash":{},"data":data}))
    + "</td>\r\n            <td></td>\r\n            <td></td>\r\n        </tr>\r\n    </tbody>\r\n</table>\r\n";
},"2":function(depth0,helpers,partials,data,blockParams,depths) {
    var helper, alias1=helpers.helperMissing, alias2=this.escapeExpression, alias3="function";

  return "        <tr class=\""
    + alias2((helpers.StyleRow || (depth0 && depth0.StyleRow) || alias1).call(depth0,(data && data.index),"prow","arow",{"name":"StyleRow","hash":{},"data":data}))
    + "\">\r\n            <td>"
    + alias2(((helper = (helper = helpers.Coverage || (depth0 != null ? depth0.Coverage : depth0)) != null ? helper : alias1),(typeof helper === alias3 ? helper.call(depth0,{"name":"Coverage","hash":{},"data":data}) : helper)))
    + "</td>\r\n            <td>"
    + alias2(((helper = (helper = helpers.ClientName || (depth0 != null ? depth0.ClientName : depth0)) != null ? helper : alias1),(typeof helper === alias3 ? helper.call(depth0,{"name":"ClientName","hash":{},"data":data}) : helper)))
    + "</td>\r\n            <td>"
    + alias2(((helper = (helper = helpers.ClaimNumber || (depth0 != null ? depth0.ClaimNumber : depth0)) != null ? helper : alias1),(typeof helper === alias3 ? helper.call(depth0,{"name":"ClaimNumber","hash":{},"data":data}) : helper)))
    + "</td>\r\n            <td>"
    + alias2((helpers.CHSIDate || (depth0 && depth0.CHSIDate) || alias1).call(depth0,(depth0 != null ? depth0.DateOfIncident : depth0),{"name":"CHSIDate","hash":{},"data":data}))
    + "</td>\r\n            <td>"
    + alias2(((helper = (helper = helpers.Claimant || (depth0 != null ? depth0.Claimant : depth0)) != null ? helper : alias1),(typeof helper === alias3 ? helper.call(depth0,{"name":"Claimant","hash":{},"data":data}) : helper)))
    + "</td>\r\n            <td>"
    + alias2(((helper = (helper = helpers.Status || (depth0 != null ? depth0.Status : depth0)) != null ? helper : alias1),(typeof helper === alias3 ? helper.call(depth0,{"name":"Status","hash":{},"data":data}) : helper)))
    + "</td>\r\n            <td style=\"text-align:right;\">"
    + alias2((helpers.FormatCurrency || (depth0 && depth0.FormatCurrency) || alias1).call(depth0,(depth0 != null ? depth0.Incurred : depth0),true,{"name":"FormatCurrency","hash":{},"data":data}))
    + "</td>\r\n            <td style=\"padding-left:50px;\">[<a href=\"javascript: CHSI.Page.ViewSuperClaim.ViewChildClaim("
    + alias2(((helper = (helper = helpers.ClaimID || (depth0 != null ? depth0.ClaimID : depth0)) != null ? helper : alias1),(typeof helper === alias3 ? helper.call(depth0,{"name":"ClaimID","hash":{},"data":data}) : helper)))
    + ","
    + alias2(((helper = (helper = helpers.ClientID || (depth0 != null ? depth0.ClientID : depth0)) != null ? helper : alias1),(typeof helper === alias3 ? helper.call(depth0,{"name":"ClientID","hash":{},"data":data}) : helper)))
    + ","
    + alias2(((helper = (helper = helpers.ClaimSystemVersion || (depth0 != null ? depth0.ClaimSystemVersion : depth0)) != null ? helper : alias1),(typeof helper === alias3 ? helper.call(depth0,{"name":"ClaimSystemVersion","hash":{},"data":data}) : helper)))
    + ")\" class=\"minilink\">view</a>]</td>\r\n            <td>[<a href=\"javascript: CHSI.Page.ViewSuperClaim.DeleteChildClaim("
    + alias2(this.lambda((depths[1] != null ? depths[1].SuperClaimID : depths[1]), depth0))
    + ","
    + alias2(((helper = (helper = helpers.ClaimID || (depth0 != null ? depth0.ClaimID : depth0)) != null ? helper : alias1),(typeof helper === alias3 ? helper.call(depth0,{"name":"ClaimID","hash":{},"data":data}) : helper)))
    + ","
    + alias2(((helper = (helper = helpers.ClaimSystemVersion || (depth0 != null ? depth0.ClaimSystemVersion : depth0)) != null ? helper : alias1),(typeof helper === alias3 ? helper.call(depth0,{"name":"ClaimSystemVersion","hash":{},"data":data}) : helper)))
    + ")\" class=\"minilink\">remove</a>]</td>\r\n        </tr>\r\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return ((stack1 = helpers["with"].call(depth0,(depth0 != null ? depth0.SuperClaim : depth0),{"name":"with","hash":{},"fn":this.program(1, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "");
},"useData":true,"useDepths":true});