this["CHSITemplates"]["ClaimManagement"]["LitigationContactModalView"] = Handlebars.template({"1":function(depth0,helpers,partials,data) {
    return " disabled";
},"3":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=this.lambda, alias2=this.escapeExpression;

  return "                <option value=\""
    + alias2(alias1((depth0 != null ? depth0["0"] : depth0), depth0))
    + "\" "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0["1"] : depth0),((stack1 = (depths[1] != null ? depths[1].Contact : depths[1])) != null ? stack1.ContactName : stack1),{"name":"compare","hash":{},"fn":this.program(4, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ">"
    + alias2(alias1((depth0 != null ? depth0["1"] : depth0), depth0))
    + "</option>\r\n";
},"4":function(depth0,helpers,partials,data) {
    return " selected";
},"6":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return "                <option "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || helpers.helperMissing).call(depth0,depth0,((stack1 = (depths[1] != null ? depths[1].Contact : depths[1])) != null ? stack1.Type : stack1),{"name":"compare","hash":{},"fn":this.program(4, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ">"
    + this.escapeExpression(this.lambda(depth0, depth0))
    + "</option>\r\n";
},"8":function(depth0,helpers,partials,data) {
    return " disabled ";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=this.lambda, alias2=this.escapeExpression, alias3=helpers.helperMissing;

  return "<div class=\"popup-form\">\r\n    <div class=\"group-container col-1\">\r\n        <div class=\"input-container col-2\">\r\n            <label>Contact</label>\r\n            <select id=\"ContactID\" class=\"contact-select popup-form-input\" "
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.editOnly : depth0),{"name":"if","hash":{},"fn":this.program(1, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ">\r\n                <option value=\"0\">New Contact</option>\r\n"
    + ((stack1 = helpers.each.call(depth0,((stack1 = (depth0 != null ? depth0.Config : depth0)) != null ? stack1.Contacts : stack1),{"name":"each","hash":{},"fn":this.program(3, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "            </select>\r\n        </div>\r\n        <div class=\"input-container col-2\">\r\n            <label>Type</label>\r\n            <select id=\"Type\" class=\"popup-form-input\">\r\n"
    + ((stack1 = helpers.each.call(depth0,((stack1 = (depth0 != null ? depth0.Config : depth0)) != null ? stack1.ContactType : stack1),{"name":"each","hash":{},"fn":this.program(6, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "            </select>\r\n        </div>\r\n        <div class=\"input-container col-1\">\r\n            <label>Contact Name</label>\r\n            <input maxlength=\"100\" type=\"text\" id=\"ContactName\" class=\"popup-form-input\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.Contact : depth0)) != null ? stack1.ContactName : stack1), depth0))
    + "\" />\r\n        </div>\r\n        <div class=\"input-container col-1\">\r\n            <label>Firm</label>\r\n            <input maxlength=\"100\" type=\"text\" id=\"Firm\" class=\"popup-form-input\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.Contact : depth0)) != null ? stack1.Firm : stack1), depth0))
    + "\" />\r\n        </div>\r\n        <div class=\"input-container col-1\">\r\n            <label>Address</label>\r\n            <input maxlength=\"100\" type=\"text\" id=\"Address1\" class=\"popup-form-input\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.Contact : depth0)) != null ? stack1.Address1 : stack1), depth0))
    + "\" />\r\n        </div>\r\n        <div class=\"input-container col-1\">\r\n            <input maxlength=\"100\" type=\"text\" id=\"Address2\" class=\"popup-form-input\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.Contact : depth0)) != null ? stack1.Address2 : stack1), depth0))
    + "\" />\r\n        </div>\r\n        <div class=\"input-container col-2\">\r\n            <label>City</label>\r\n            <input maxlength=\"50\" type=\"text\" id=\"City\" class=\"popup-form-input\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.Contact : depth0)) != null ? stack1.City : stack1), depth0))
    + "\" />\r\n        </div>\r\n        <div class=\"input-container col-4\">\r\n            <label>State</label>\r\n            <input maxlength=\"2\" type=\"text\" id=\"State\" class=\"popup-form-input\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.Contact : depth0)) != null ? stack1.State : stack1), depth0))
    + "\" />\r\n        </div>\r\n        <div class=\"input-container col-4\">\r\n            <label>Zip</label>\r\n            <input maxlength=\"10\" type=\"text\" id=\"Zip\" class=\"popup-form-input\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.Contact : depth0)) != null ? stack1.Zip : stack1), depth0))
    + "\" />\r\n        </div>\r\n        <div class=\"input-container col-2\">\r\n            <label>Primary Phone Type</label>\r\n            <select id=\"Phone1Type\" class=\"popup-form-input\">\r\n                <option "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias3).call(depth0,"",((stack1 = (depth0 != null ? depth0.Contact : depth0)) != null ? stack1.Phone1Type : stack1),{"name":"compare","hash":{},"fn":this.program(4, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "></option>\r\n                <option "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias3).call(depth0,"Cell",((stack1 = (depth0 != null ? depth0.Contact : depth0)) != null ? stack1.Phone1Type : stack1),{"name":"compare","hash":{},"fn":this.program(4, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ">Cell</option>\r\n                <option "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias3).call(depth0,"Home",((stack1 = (depth0 != null ? depth0.Contact : depth0)) != null ? stack1.Phone1Type : stack1),{"name":"compare","hash":{},"fn":this.program(4, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ">Home</option>\r\n                <option "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias3).call(depth0,"Office",((stack1 = (depth0 != null ? depth0.Contact : depth0)) != null ? stack1.Phone1Type : stack1),{"name":"compare","hash":{},"fn":this.program(4, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ">Office</option>\r\n            </select>\r\n        </div>\r\n        <div class=\"input-container col-2\">\r\n            <label>Primary Phone</label>\r\n            <input maxlength=\"10\" type=\"text\" id=\"Phone1\" class=\"popup-form-input phone-mask\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.Contact : depth0)) != null ? stack1.Phone1 : stack1), depth0))
    + "\" />\r\n        </div>\r\n        <div class=\"input-container col-3\">\r\n            <label>Extension</label>\r\n            <input maxlength=\"6\" type=\"text\" id=\"Phone1Ext\" class=\"popup-form-input form-control\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.Contact : depth0)) != null ? stack1.Phone1Ext : stack1), depth0))
    + "\" />\r\n        </div>\r\n        <div class=\"input-container col-2\">\r\n            <label>Secondary Phone Type</label>\r\n            <select id=\"Phone2Type\" class=\"popup-form-input\">\r\n                <option "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias3).call(depth0,"",((stack1 = (depth0 != null ? depth0.Contact : depth0)) != null ? stack1.Phone2Type : stack1),{"name":"compare","hash":{},"fn":this.program(4, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "></option>\r\n                <option "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias3).call(depth0,"Cell",((stack1 = (depth0 != null ? depth0.Contact : depth0)) != null ? stack1.Phone2Type : stack1),{"name":"compare","hash":{},"fn":this.program(4, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ">Cell</option>\r\n                <option "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias3).call(depth0,"Home",((stack1 = (depth0 != null ? depth0.Contact : depth0)) != null ? stack1.Phone2Type : stack1),{"name":"compare","hash":{},"fn":this.program(4, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ">Home</option>\r\n                <option "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias3).call(depth0,"Office",((stack1 = (depth0 != null ? depth0.Contact : depth0)) != null ? stack1.Phone2Type : stack1),{"name":"compare","hash":{},"fn":this.program(4, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ">Office</option>\r\n            </select>\r\n        </div>\r\n        <div class=\"input-container col-2\">\r\n            <label>Secondary Phone</label>\r\n            <input maxlength=\"10\" type=\"text\" id=\"Phone2\" class=\"popup-form-input phone-mask\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.Contact : depth0)) != null ? stack1.Phone2 : stack1), depth0))
    + "\" />\r\n        </div>\r\n        <div class=\"input-container col-3\">\r\n            <label>Extension</label>\r\n            <input maxlength=\"6\" type=\"text\" id=\"Phone2Ext\" class=\"popup-form-input form-control\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.Contact : depth0)) != null ? stack1.Phone2Ext : stack1), depth0))
    + "\" />\r\n        </div>\r\n        <div class=\"input-container col-1\">\r\n            <label>Email</label>\r\n            <input maxlength=\"100\" type=\"text\" id=\"Email\" class=\"popup-form-input\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.Contact : depth0)) != null ? stack1.Email : stack1), depth0))
    + "\" />\r\n        </div>\r\n        <div class=\"input-container col-1\">\r\n            <label>Assistant Name</label>\r\n            <input maxlength=\"100\" type=\"text\" id=\"AssistantName\" class=\"popup-form-input\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.Contact : depth0)) != null ? stack1.AssistantName : stack1), depth0))
    + "\" />\r\n        </div>\r\n        <div class=\"input-container col-2\">\r\n            <label>Assistant Phone Type</label>\r\n            <select id=\"Phone3Type\" class=\"popup-form-input\">\r\n                <option "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias3).call(depth0,"",((stack1 = (depth0 != null ? depth0.Contact : depth0)) != null ? stack1.Phone3Type : stack1),{"name":"compare","hash":{},"fn":this.program(4, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "></option>\r\n                <option "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias3).call(depth0,"Cell",((stack1 = (depth0 != null ? depth0.Contact : depth0)) != null ? stack1.Phone3Type : stack1),{"name":"compare","hash":{},"fn":this.program(4, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ">Cell</option>\r\n                <option "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias3).call(depth0,"Home",((stack1 = (depth0 != null ? depth0.Contact : depth0)) != null ? stack1.Phone3Type : stack1),{"name":"compare","hash":{},"fn":this.program(4, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ">Home</option>\r\n                <option "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias3).call(depth0,"Office",((stack1 = (depth0 != null ? depth0.Contact : depth0)) != null ? stack1.Phone3Type : stack1),{"name":"compare","hash":{},"fn":this.program(4, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ">Office</option>\r\n            </select>\r\n        </div>\r\n        <div class=\"input-container col-2\">\r\n            <label>Assistant Phone</label>\r\n            <input maxlength=\"10\" type=\"text\" id=\"Phone3\" class=\"popup-form-input phone-mask\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.Contact : depth0)) != null ? stack1.Phone3 : stack1), depth0))
    + "\" />\r\n        </div>\r\n        <div class=\"input-container col-3\">\r\n            <label>Extension</label>\r\n            <input maxlength=\"6\" type=\"text\" id=\"Phone3Ext\" class=\"popup-form-input form-control\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.Contact : depth0)) != null ? stack1.Phone3Ext : stack1), depth0))
    + "\" />\r\n        </div>\r\n    </div>\r\n</div>\r\n<div class=\"btn-bar\">\r\n    <input class=\"contact-delete edit\" value=\"Delete\" type=\"button\" style=\"float:left;\" "
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.isNew : depth0),{"name":"if","hash":{},"fn":this.program(8, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + " />\r\n    <input class=\"contact-cancel\" value=\"Cancel\" type=\"button\" style=\"float:right;\" />\r\n    <input class=\"contact-save edit\" value=\"Save\" type=\"button\" style=\"float:right;margin-right:20px\" />\r\n</div> ";
},"useData":true,"useDepths":true});