this["CHSITemplates"]["ClaimManagement"]["AssignmentModalView"] = Handlebars.template({"1":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return "                <option "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || helpers.helperMissing).call(depth0,depth0,((stack1 = (depths[1] != null ? depths[1].Assignment : depths[1])) != null ? stack1.AssigneeType : stack1),{"name":"compare","hash":{},"fn":this.program(2, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ">"
    + this.escapeExpression(this.lambda(depth0, depth0))
    + "</option>\r\n";
},"2":function(depth0,helpers,partials,data) {
    return " selected";
},"4":function(depth0,helpers,partials,data) {
    return " checked";
},"6":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=helpers.helperMissing, alias2=this.escapeExpression;

  return "                <option value=\""
    + alias2(((helper = (helper = helpers.key || (data && data.key)) != null ? helper : alias1),(typeof helper === "function" ? helper.call(depth0,{"name":"key","hash":{},"data":data}) : helper)))
    + "\" "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias1).call(depth0,(data && data.key),((stack1 = (depths[1] != null ? depths[1].Assignment : depths[1])) != null ? stack1.AssigneeID : stack1),{"name":"compare","hash":{},"fn":this.program(2, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ">"
    + alias2(this.lambda(depth0, depth0))
    + "</option>\r\n";
},"8":function(depth0,helpers,partials,data) {
    return " disabled ";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=this.lambda, alias2=this.escapeExpression;

  return "<div class=\"popup-form assignment-modal\">\r\n    <div class=\"group-container col-1\">\r\n        <div class=\"input-container col-2\">\r\n            <label>From</label>\r\n            <input type=\"text\" id=\"FromDate\" class=\"popup-form-input date\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.Assignment : depth0)) != null ? stack1.FromDate : stack1), depth0))
    + "\" />\r\n        </div>\r\n        <div class=\"input-container col-2\">\r\n            <label>To</label>\r\n            <input type=\"text\" id=\"ToDate\" class=\"popup-form-input date\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.Assignment : depth0)) != null ? stack1.ToDate : stack1), depth0))
    + "\" />\r\n        </div>\r\n        <div class=\"input-container col-2\">\r\n            <label>Type</label>\r\n            <select id=\"AssigneeType\" class=\"popup-form-input\">\r\n"
    + ((stack1 = helpers.each.call(depth0,((stack1 = (depth0 != null ? depth0.Config : depth0)) != null ? stack1.AssigneeType : stack1),{"name":"each","hash":{},"fn":this.program(1, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "            </select>\r\n        </div>\r\n        <div class=\"input-container col-2 center\">\r\n            <label>Primary?</label>\r\n            <input type=\"checkbox\" id=\"Primary\" class=\"popup-form-input\" "
    + ((stack1 = helpers["if"].call(depth0,((stack1 = (depth0 != null ? depth0.Assignment : depth0)) != null ? stack1.Primary : stack1),{"name":"if","hash":{},"fn":this.program(4, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + " />\r\n        </div>\r\n        <div class=\"input-container col-2\">\r\n            <label>Assignee</label>\r\n            <input type=\"text\" id=\"AssigneeName\" class=\"popup-form-input col-33\" value=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.Assignment : depth0)) != null ? stack1.AssigneeInfo : stack1)) != null ? stack1.AssigneeName : stack1), depth0))
    + "\" readonly />\r\n            <!--<select id=\"AssigneeID\" class=\"popup-form-input\">\r\n"
    + ((stack1 = helpers.each.call(depth0,((stack1 = (depth0 != null ? depth0.Config : depth0)) != null ? stack1.Assignees : stack1),{"name":"each","hash":{},"fn":this.program(6, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "            </select>-->\r\n            <input class=\"select-assignee edit\" value=\"Select\" type=\"button\" style=\"width: 79px;\">\r\n        </div>\r\n    </div>\r\n</div>\r\n<div class=\"btn-bar assignment-modal\">\r\n    <input class=\"assignment-delete edit\" value=\"Delete\" type=\"button\" style=\"float:left;\" "
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.isNew : depth0),{"name":"if","hash":{},"fn":this.program(8, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + " />\r\n    <input class=\"assignment-cancel\" value=\"Cancel\" type=\"button\" style=\"float:right;\" />\r\n    <input class=\"assignment-save edit\" value=\"Save\" type=\"button\" style=\"float:right;margin-right:20px\" />\r\n</div> \r\n\r\n<!-- assignee view -->\r\n<div class=\"popup-form assignee-modal\" style=\"display:none\">\r\n    <div class=\"group-container col-1\">\r\n        <table id=\"dt-assignees\" class=\"dtable\" style=\"width:100%\"></table>\r\n    </div>\r\n</div>\r\n<div class=\"btn-bar assignee-modal\" style=\"display:none\">\r\n    <input class=\"assignment-assignee-cancel\" value=\"Cancel\" type=\"button\" style=\"float:right;\" />\r\n    <input class=\"add-assignee edit\" value=\"Add Assignee\" type=\"button\" style=\"float:right;margin-right:20px;width:auto\" />\r\n</div>";
},"useData":true,"useDepths":true});