this["CHSITemplates"]["AdminTools_Questionnaire_Editor_Grid_Preview"] = Handlebars.template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1, alias1=helpers.helperMissing;

  return "<style>\r\n    #gridPreview {\r\n        margin-top: 10px;\r\n        overflow-x: auto;\r\n        height: 400px;\r\n    }\r\n    #gridPreviewInformation{\r\n        font-weight: bold;\r\n    }\r\n    #gridPreviewButtons{\r\n        width: 100%;\r\n    }\r\n    .gridPreviewHighLight{\r\n        color:#536c87;\r\n    }\r\n</style>\r\n<div>\r\n    <!--<h1>Grid Preview Mode</h1> \r\n    <hr />\r\n    <div>\r\n        <span id=\"gridPreviewInformation\">In the <span class=\"gridPreviewHighLight\">Grid Preivew Mode</span> you can review what the final product will look like. You can see the number of columns (elements), the widths of the resulting element columns, and if the element column is or is not read-only. You can switch back and forth between the editor and preview mode to adjust the final grid result as desired.</span>        \r\n    </div>-->\r\n    \r\n</div>\r\n\r\n<div id=\"gridPreview\">\r\n    <table>\r\n        <thead>\r\n            "
    + ((stack1 = (helpers.buildGridPreviewHeader || (depth0 && depth0.buildGridPreviewHeader) || alias1).call(depth0,(depth0 != null ? depth0.Elements : depth0),{"name":"buildGridPreviewHeader","hash":{},"data":data})) != null ? stack1 : "")
    + "\r\n        </thead>\r\n        <tbody>\r\n            "
    + ((stack1 = (helpers.buildGridPreviewBody || (depth0 && depth0.buildGridPreviewBody) || alias1).call(depth0,(depth0 != null ? depth0.Elements : depth0),{"name":"buildGridPreviewBody","hash":{},"data":data})) != null ? stack1 : "")
    + "\r\n        </tbody>\r\n        <tfoot>\r\n            "
    + ((stack1 = (helpers.buildGridPreviewFooter || (depth0 && depth0.buildGridPreviewFooter) || alias1).call(depth0,(depth0 != null ? depth0.Elements : depth0),{"name":"buildGridPreviewFooter","hash":{},"data":data})) != null ? stack1 : "")
    + "\r\n        </tfoot>\r\n    </table>\r\n</div>\r\n";
},"useData":true});