this["CHSITemplates"]["Template_QuotePremium_VariableHistory"] = Handlebars.template({"1":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=helpers.helperMissing, alias2=this.escapeExpression, alias3=this.lambda;

  return "            <tr>\r\n                <td style=\"padding-left:10px;\">\r\n                    "
    + alias2(((helper = (helper = helpers.VariableName || (depth0 != null ? depth0.VariableName : depth0)) != null ? helper : alias1),(typeof helper === "function" ? helper.call(depth0,{"name":"VariableName","hash":{},"data":data}) : helper)))
    + "\r\n                </td>\r\n                <td>\r\n                    "
    + alias2((helpers.GetValueByDataType || (depth0 && depth0.GetValueByDataType) || alias1).call(depth0,(depth0 != null ? depth0.DataObject : depth0),{"name":"GetValueByDataType","hash":{},"data":data}))
    + "\r\n                </td>\r\n                <td>\r\n                    "
    + alias2(alias3(((stack1 = (depth0 != null ? depth0.History : depth0)) != null ? stack1.Comments : stack1), depth0))
    + "\r\n                </td>\r\n                <td>\r\n                    "
    + alias2(alias3(((stack1 = (depth0 != null ? depth0.History : depth0)) != null ? stack1.CreateUser : stack1), depth0))
    + "\r\n                </td>\r\n                <td>\r\n                    "
    + alias2(alias3(((stack1 = (depth0 != null ? depth0.History : depth0)) != null ? stack1.CreateTmsp : stack1), depth0))
    + "\r\n                </td>\r\n                <td>\r\n"
    + ((stack1 = helpers["if"].call(depth0,((stack1 = (depths[1] != null ? depths[1].HistoryObj : depths[1])) != null ? stack1.EditMode : stack1),{"name":"if","hash":{},"fn":this.program(2, data, 0, blockParams, depths),"inverse":this.program(4, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + "                </td>\r\n            </tr>\r\n";
},"2":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=this.escapeExpression, alias2=helpers.helperMissing;

  return "                    <input type=\"button\" value=\"Select\" onclick=\"CHSI.UW.MuniQuote_VariableHistory.Calls.ChangeHistory('"
    + alias1(this.lambda(((stack1 = (depths[2] != null ? depths[2].HistoryObj : depths[2])) != null ? stack1.VarID : stack1), depth0))
    + "', "
    + alias1((helpers.GetValueByDataType || (depth0 && depth0.GetValueByDataType) || alias2).call(depth0,(depth0 != null ? depth0.DataObject : depth0),{"name":"GetValueByDataType","hash":{},"data":data}))
    + ", "
    + alias1(((helper = (helper = helpers.index || (data && data.index)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(depth0,{"name":"index","hash":{},"data":data}) : helper)))
    + ");\" />\r\n";
},"4":function(depth0,helpers,partials,data) {
    return "                    &nbsp;\r\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return "<div>\r\n    <table class=\"dtable sortable\" width=\"100%\">\r\n        <thead>\r\n            <tr>\r\n                <td style=\"padding-left:10px;\">Variable Name</td>\r\n                <td>Value</td>\r\n                <td>Comments</td>\r\n                <td>User</td>\r\n                <td>Date</td>\r\n                <td>&nbsp;</td>\r\n            </tr>\r\n        </thead>\r\n        <tbody>\r\n\r\n"
    + ((stack1 = helpers.each.call(depth0,(depth0 != null ? depth0.HistoryObj : depth0),{"name":"each","hash":{},"fn":this.program(1, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "        </tbody>\r\n    </table>\r\n</div>    ";
},"useData":true,"useDepths":true});