this["CHSITemplates"]["Template_Subsidy_CurrentSubsidyInformation"] = Handlebars.template({"1":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return "<table class=\"dtable sortable\">    \r\n    <thead>\r\n\r\n            <style type=\"text/css\">\r\n                .tableRight {\r\n                    vertical-align: middle;\r\n                    text-align: right;\r\n                    width: 160px;   \r\n                    font-size: larger;                 \r\n                } \r\n                .HeaderFontClass{\r\n                    font-size: larger;\r\n                }\r\n                .BodyFontClass{\r\n                    font-size: x-large;\r\n                }\r\n            </style>\r\n\r\n        <tr>\r\n            <td class=\"HeaderFontClass\" style=\"text-align: left; width: 160px;\">Subsidy</td>\r\n            <td class=\"HeaderFontClass tableRight\">Current Balance</td>\r\n            <td class=\"HeaderFontClass tableRight\">Last Activity</td>\r\n            <td class=\"HeaderFontClass tableRight\">&nbsp</td>\r\n        </tr>\r\n    </thead>\r\n    <tbody>\r\n"
    + ((stack1 = helpers.each.call(depth0,(depth0 != null ? depth0.GroupSubsidyByClientRecord : depth0),{"name":"each","hash":{},"fn":this.program(2, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "    </tbody>\r\n</table>\r\n";
},"2":function(depth0,helpers,partials,data,blockParams,depths) {
    var alias1=this.lambda, alias2=this.escapeExpression, alias3=helpers.helperMissing;

  return "        <tr>\r\n            <td style=\"font-size: medium\">"
    + alias2(alias1((depth0 != null ? depth0.SubsidyName : depth0), depth0))
    + "</td>\r\n            <td style=\"font-size: medium\" class=\"tableRight\">"
    + alias2((helpers.NumberToCurrency || (depth0 && depth0.NumberToCurrency) || alias3).call(depth0,(depth0 != null ? depth0.Balance : depth0),{"name":"NumberToCurrency","hash":{},"data":data}))
    + "</td>\r\n            <td style=\"font-size: medium\" class=\"tableRight\">"
    + alias2((helpers.CHSIDate || (depth0 && depth0.CHSIDate) || alias3).call(depth0,(depth0 != null ? depth0.LastActivity : depth0),{"name":"CHSIDate","hash":{},"data":data}))
    + "</td>\r\n            <td style=\"text-align: center;\">[<a class=\"minilink\" href=\"javascript: PopupSubsidyTransactionModal("
    + alias2(alias1((depth0 != null ? depth0.GroupSubsidyID : depth0), depth0))
    + ",0,0);\"> "
    + alias2(alias1((depths[1] != null ? depths[1].RequestText : depths[1]), depth0))
    + "</a>]</td>\r\n        </tr>\r\n";
},"4":function(depth0,helpers,partials,data) {
    return "<p>No information to display.</p>\r\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.GroupSubsidyByClientRecord : depth0),{"name":"if","hash":{},"fn":this.program(1, data, 0, blockParams, depths),"inverse":this.program(4, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "");
},"useData":true,"useDepths":true});