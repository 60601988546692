this["CHSITemplates"]["Common"] = this["CHSITemplates"]["Common"] || {};
this["CHSITemplates"]["Common"]["QuotePrebindModal"] = Handlebars.template({"1":function(depth0,helpers,partials,data) {
    var stack1;

  return "            <div style=\"margin:0 0 10px 0\"><label>Validation Warnings:</label></div>\r\n"
    + ((stack1 = helpers.each.call(depth0,(depth0 != null ? depth0.results : depth0),{"name":"each","hash":{},"fn":this.program(2, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "");
},"2":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.compare || (depth0 && depth0.compare) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.ValidationStatus : depth0),"warning",{"name":"compare","hash":{},"fn":this.program(3, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "");
},"3":function(depth0,helpers,partials,data) {
    return "            <div>\r\n                <a class=\"statusicon statusvacation\" style=\"display:inline-block\"/>\r\n                <span>"
    + this.escapeExpression(this.lambda((depth0 != null ? depth0.ValidationMessage : depth0), depth0))
    + "</span>\r\n            </div>\r\n";
},"5":function(depth0,helpers,partials,data) {
    var stack1;

  return "            <div style=\"margin:"
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.hasWarnings : depth0),{"name":"if","hash":{},"fn":this.program(6, data, 0),"inverse":this.program(8, data, 0),"data":data})) != null ? stack1 : "")
    + " 0 10px 0\"><label>Validation Errors: (must be fixed before binding)</label></div>\r\n"
    + ((stack1 = helpers.each.call(depth0,(depth0 != null ? depth0.results : depth0),{"name":"each","hash":{},"fn":this.program(10, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "");
},"6":function(depth0,helpers,partials,data) {
    return "10px";
},"8":function(depth0,helpers,partials,data) {
    return "0";
},"10":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.compare || (depth0 && depth0.compare) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.ValidationStatus : depth0),"critical",{"name":"compare","hash":{},"fn":this.program(11, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "");
},"11":function(depth0,helpers,partials,data) {
    return "            <div>\r\n                <a class=\"statusicon statusoutofoffice\" style=\"display:inline-block\"/>\r\n                <span>"
    + this.escapeExpression(this.lambda((depth0 != null ? depth0.ValidationMessage : depth0), depth0))
    + "</span>\r\n            </div>\r\n";
},"13":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.compare || (depth0 && depth0.compare) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.ValidationStatus : depth0),"error",{"name":"compare","hash":{},"fn":this.program(14, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "");
},"14":function(depth0,helpers,partials,data) {
    return "        <div>\r\n            <a class=\"statusicon statusunknown\" style=\"display:inline-block\"/>\r\n            <span>"
    + this.escapeExpression(this.lambda((depth0 != null ? depth0.ValidationMessage : depth0), depth0))
    + "</span>\r\n        </div>\r\n";
},"16":function(depth0,helpers,partials,data) {
    return "";
},"18":function(depth0,helpers,partials,data) {
    return "<input class=\"validation-bind\" value=\"Bind\" type=\"button\" style=\"float:right;margin-right:20px;\" />";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"popup-form\" style=\"margin:8px\">\r\n    <div class=\"group-container col-1\">\r\n"
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.hasWarnings : depth0),{"name":"if","hash":{},"fn":this.program(1, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.hasErrors : depth0),{"name":"if","hash":{},"fn":this.program(5, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers.each.call(depth0,(depth0 != null ? depth0.results : depth0),{"name":"each","hash":{},"fn":this.program(13, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "    </div>\r\n</div>\r\n<div class=\"btn-bar\">\r\n    <input class=\"validation-cancel\" value=\"Cancel\" type=\"button\" style=\"float:right;\" />\r\n    "
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.hasErrors : depth0),{"name":"if","hash":{},"fn":this.program(16, data, 0),"inverse":this.program(18, data, 0),"data":data})) != null ? stack1 : "")
    + "\r\n</div> ";
},"useData":true});