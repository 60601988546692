this["CHSITemplates"]["ClaimManagement"]["LitigationView"] = Handlebars.template({"1":function(depth0,helpers,partials,data) {
    return " disabled ";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1, alias1=this.lambda, alias2=this.escapeExpression;

  return "<div class=\"popup-form\">\r\n    <div class=\"group-container row-1\">\r\n        <h1>Litigation Details</h1>\r\n        <div class=\"input-container col-2\">\r\n            <label>Served</label>\r\n            <input type=\"text\" id=\"Served\" class=\"popup-form-input date\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.Litigation : depth0)) != null ? stack1.Served : stack1), depth0))
    + "\" />\r\n        </div>\r\n        <div class=\"input-container col-2\">\r\n            <label>Answered</label>\r\n            <input type=\"text\" id=\"Answered\" class=\"popup-form-input date\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.Litigation : depth0)) != null ? stack1.Answered : stack1), depth0))
    + "\" />\r\n        </div>\r\n        <div class=\"input-container col-2\">\r\n            <label>Case Number</label>\r\n            <input type=\"text\" id=\"CaseNumber\" class=\"popup-form-input\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.Litigation : depth0)) != null ? stack1.CaseNumber : stack1), depth0))
    + "\" />\r\n        </div>\r\n        <div class=\"input-container col-2\">\r\n            <label>Court</label>\r\n            <input type=\"text\" id=\"Court\" class=\"popup-form-input\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.Litigation : depth0)) != null ? stack1.Court : stack1), depth0))
    + "\" />\r\n        </div>\r\n        <div class=\"input-container col-1\">\r\n            <label>Venue</label>\r\n            <input type=\"text\" id=\"Venue\" class=\"popup-form-input\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.Litigation : depth0)) != null ? stack1.Venue : stack1), depth0))
    + "\" />\r\n        </div>\r\n        <div class=\"input-container col-1\">\r\n            <label>Comments</label>\r\n            <textarea id=\"Comments\" class=\"popup-form-textarea\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.Litigation : depth0)) != null ? stack1.Comments : stack1), depth0))
    + "</textarea>\r\n        </div>      \r\n    </div>\r\n</div>\r\n<div class=\"btn-bar\">\r\n    <input class=\"lit-delete edit\" value=\"Delete\" type=\"button\" style=\"float:left;\" "
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.isNew : depth0),{"name":"if","hash":{},"fn":this.program(1, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + " />\r\n    <input class=\"lit-cancel\" value=\"Cancel\" type=\"button\" style=\"float:right;\" />\r\n    <input class=\"lit-save edit\" value=\"Save\" type=\"button\" style=\"float:right;margin-right:20px\" />\r\n</div> ";
},"useData":true});