this["CHSITemplates"]["AdminTools_ContactTypes_Edit"] = Handlebars.template({"1":function(depth0,helpers,partials,data) {
    return " selected ";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1, helper, alias1=helpers.helperMissing, alias2="function", alias3=this.escapeExpression;

  return "<style>\r\n\r\n    #contacttype_edit_container{\r\n        padding: 5px;\r\n    }\r\n    .bottomButtonContainer{\r\n        height: 48px !important;\r\n        width: 174px !important;\r\n    }\r\n\r\n    .margin_bottom {\r\n        margin-bottom: 4px;\r\n    }\r\n    #contacttype_cancel{\r\n        position: initial !important;\r\n        right: 0px !important; \r\n        top: 0px !important;         \r\n        margin-left: 4px;\r\n    }\r\n</style>\r\n\r\n<div id=\"contacttype_edit_container\" data-originalcontacttypename=\""
    + alias3(((helper = (helper = helpers.ContactTypeName || (depth0 != null ? depth0.ContactTypeName : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"ContactTypeName","hash":{},"data":data}) : helper)))
    + "\">\r\n\r\n    <div class=\"margin_bottom\"><label><span>Contact Type: </span><input id=\"contacttype_name\" type=\"text\" value=\""
    + alias3(((helper = (helper = helpers.ContactTypeName || (depth0 != null ? depth0.ContactTypeName : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"ContactTypeName","hash":{},"data":data}) : helper)))
    + "\" /></label></div>\r\n    <div class=\"margin_bottom\">\r\n        <label>\r\n            <span>Status: </span>\r\n            <select id=\"contacttype_status\">\r\n                <option value=\"active\" "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias1).call(depth0,(depth0 != null ? depth0.Status : depth0),"active",{"name":"compare","hash":{"operator":"=="},"fn":this.program(1, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ">Active</option>\r\n                <option value=\"inactive\" "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias1).call(depth0,(depth0 != null ? depth0.Status : depth0),"inactive",{"name":"compare","hash":{"operator":"=="},"fn":this.program(1, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ">Inactive</option>                \r\n            </select>\r\n        </label>\r\n    </div>\r\n    <div id=\"loading_notification\" class=\"margin_bottom\"></div>\r\n    <div class=\"bottomButtonContainer\">\r\n        <button id=\"contacttype_cancel\" class=\"CHSI-ModalClose\" style=\"float: right;\">Close</button>\r\n        <button id=\"contacttype_save\" style=\"float: right;\">Save</button>\r\n    </div>\r\n\r\n</div>";
},"useData":true});