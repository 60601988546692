this["CHSITemplates"]["Template_ClaimSearch_ClientResults"] = Handlebars.template({"1":function(depth0,helpers,partials,data) {
    return "    <div style=\"color: Red; font-size: 16px;\">\r\n        More than 100 records found, please refine your search.\r\n    </div>\r\n";
},"3":function(depth0,helpers,partials,data) {
    var stack1;

  return "            <table cellspacing=0 cellpadding=0 class=\"dtable sortable\">\r\n                <thead>\r\n                    <tr>\r\n                        <td style=\"width: 100px\">Coverage</td>\r\n                        <td style=\"width: 100px\">Type</td>\r\n                        <td style=\"width: 100px\">Claim No.</td>\r\n                        <td style=\"width: 150px\">Claimant</td>\r\n                        <td style=\"width: 100px\">Incident Date</td>\r\n                        <td style=\"width: 100px\">Date Opened</td>\r\n                        <td style=\"width: 100px\">Status</td>\r\n                        <td style=\"width: 100px; text-align: right\">Paid</td>\r\n                        <td style=\"width: 100px; text-align: right\">Reserves</td>\r\n                        <td style=\"width: 100px; text-align: right\">Incurred</td>\r\n                        <td style=\"width: 60px\" class=\"sorttable_nosort\">&nbsp;</td>\r\n                    </tr>\r\n            </thead>\r\n            <tbody>\r\n"
    + ((stack1 = helpers.each.call(depth0,(depth0 != null ? depth0.ClaimTableRecord : depth0),{"name":"each","hash":{},"fn":this.program(4, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "            </tbody>\r\n        </table>\r\n";
},"4":function(depth0,helpers,partials,data) {
    var alias1=this.lambda, alias2=this.escapeExpression, alias3=helpers.helperMissing;

  return "                <tr>\r\n                    <td style=\"width: 100px\">"
    + alias2(alias1((depth0 != null ? depth0.Coverage : depth0), depth0))
    + "</td>\r\n                    <td style=\"width: 100px\">"
    + alias2((helpers.TruncateString || (depth0 && depth0.TruncateString) || alias3).call(depth0,(depth0 != null ? depth0.Type : depth0),15,{"name":"TruncateString","hash":{},"data":data}))
    + "</td>\r\n                    <td style=\"width: 100px\">"
    + alias2(alias1((depth0 != null ? depth0.ClaimNumber : depth0), depth0))
    + "</td>\r\n                    <td style=\"width: 150px\">"
    + alias2((helpers.TruncateString || (depth0 && depth0.TruncateString) || alias3).call(depth0,(depth0 != null ? depth0.Claimant : depth0),22,{"name":"TruncateString","hash":{},"data":data}))
    + "</td>\r\n                    <td style=\"width: 100px\">"
    + alias2((helpers.CHSIDate || (depth0 && depth0.CHSIDate) || alias3).call(depth0,(depth0 != null ? depth0.DateOfIncident : depth0),{"name":"CHSIDate","hash":{},"data":data}))
    + "</td>\r\n                    <td style=\"width: 100px\">"
    + alias2((helpers.CHSIDate || (depth0 && depth0.CHSIDate) || alias3).call(depth0,(depth0 != null ? depth0.DateOpened : depth0),{"name":"CHSIDate","hash":{},"data":data}))
    + "</td>\r\n                    <td style=\"width: 100px\">"
    + alias2(alias1((depth0 != null ? depth0.Status : depth0), depth0))
    + "</td>\r\n                    <td style=\"width: 100px; text-align: right\">"
    + alias2((helpers.FormatCurrency || (depth0 && depth0.FormatCurrency) || alias3).call(depth0,(depth0 != null ? depth0.Paid : depth0),true,{"name":"FormatCurrency","hash":{},"data":data}))
    + "</td>\r\n                    <td style=\"width: 100px; text-align: right\">"
    + alias2((helpers.FormatCurrency || (depth0 && depth0.FormatCurrency) || alias3).call(depth0,(depth0 != null ? depth0.Reserves : depth0),true,{"name":"FormatCurrency","hash":{},"data":data}))
    + "</td>\r\n                    <td style=\"width: 100px; text-align: right\">"
    + alias2((helpers.FormatCurrency || (depth0 && depth0.FormatCurrency) || alias3).call(depth0,(depth0 != null ? depth0.Incurred : depth0),true,{"name":"FormatCurrency","hash":{},"data":data}))
    + "</td>\r\n                    <td style=\"width: 60px\">[<a href=\""
    + alias2((helpers.GetClaimDetailsURL || (depth0 && depth0.GetClaimDetailsURL) || alias3).call(depth0,(depth0 != null ? depth0.ClaimVersion : depth0),(depth0 != null ? depth0.ClaimID : depth0),(depth0 != null ? depth0.ClientID : depth0),{"name":"GetClaimDetailsURL","hash":{},"data":data}))
    + "\" class=\"minilink\">view</a>]</td>\r\n                    </tr>\r\n";
},"6":function(depth0,helpers,partials,data) {
    return "            <p>No claims found using the supplied search filters.  Please refine your search and try again...</p>\r\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.ShowTooManyRecords : depth0),{"name":"if","hash":{},"fn":this.program(1, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "<br /><span style=\"font-size: 20px;\">Reported Claims</span><hr style=\"color: #7ba2d6;\" />\r\n"
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.ClaimTableRecord : depth0),{"name":"if","hash":{},"fn":this.program(3, data, 0),"inverse":this.program(6, data, 0),"data":data})) != null ? stack1 : "");
},"useData":true});