this["CHSITemplates"]["Template_IncidentSearch_ClientResults"] = Handlebars.template({"1":function(depth0,helpers,partials,data) {
    return "    <div style=\"color: Red; font-size: 16px;\">\r\n        More than 100 records found, please refine your search.\r\n    </div>\r\n";
},"3":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return "            <table style=\"width: 100%;\" cellspacing=0 cellpadding=0 class=\"dtable sortable\">\r\n                <thead>\r\n                    <tr>\r\n                        <td style=\"width: 80px\">Incident #</td>\r\n                        <td style=\"width: 130px\">Incident Date</td>\r\n                        <td style=\"width: 150px\">Employee</td>\r\n                        <td style=\"width: 100px\">Status</td>\r\n                        <!--<td style=\"width: 70px\">Form Type</td>-->\r\n                        <td style=\"width: 130px\">Create Date</td>\r\n                        <td style=\"width: 90px\">Created By</td>\r\n                        <td>&nbsp;</td>\r\n                    </tr>\r\n                </thead>\r\n                <tbody>\r\n"
    + ((stack1 = helpers.each.call(depth0,(depth0 != null ? depth0.IncidentTableRecord : depth0),{"name":"each","hash":{},"fn":this.program(4, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "                </tbody>\r\n            </table>\r\n";
},"4":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=this.lambda, alias2=this.escapeExpression, alias3=helpers.helperMissing, alias4="function";

  return "                    <tr>\r\n                        <td style=\"width: 80px\">"
    + alias2(alias1((depth0 != null ? depth0.IncidentID : depth0), depth0))
    + "</td>\r\n                        <td style=\"width: 130px\">"
    + alias2((helpers.CHSIDate || (depth0 && depth0.CHSIDate) || alias3).call(depth0,(depth0 != null ? depth0.IncidentDate : depth0),{"name":"CHSIDate","hash":{},"data":data}))
    + "</td>\r\n                        <td style=\"width: 150px\">"
    + alias2(alias1((depth0 != null ? depth0.EmployeeName : depth0), depth0))
    + "</td>\r\n                        <td style=\"width: 100px\">"
    + alias2(alias1((depth0 != null ? depth0.Status : depth0), depth0))
    + "</td>\r\n                        <!--td style=\"width: 70px\">"
    + alias2(alias1((depth0 != null ? depth0.FormType : depth0), depth0))
    + "</td>-->\r\n                        <td style=\"width: 130px\">"
    + alias2((helpers.CHSIDate || (depth0 && depth0.CHSIDate) || alias3).call(depth0,(depth0 != null ? depth0.CreatedDate : depth0),{"name":"CHSIDate","hash":{},"data":data}))
    + "</td>\r\n                        <td style=\"width: 100px\">"
    + alias2(alias1((depth0 != null ? depth0.CreatedBy : depth0), depth0))
    + "</td>\r\n                        <td style=\"width: 130px\">[<a href=\"#\" class=\"minilink\" onclick=\"showIntakeForm("
    + alias2(((helper = (helper = helpers.IncidentID || (depth0 != null ? depth0.IncidentID : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(depth0,{"name":"IncidentID","hash":{},"data":data}) : helper)))
    + ", "
    + alias2(alias1((depths[1] != null ? depths[1].ClientID : depths[1]), depth0))
    + ", "
    + alias2(((helper = (helper = helpers.FormTypeID || (depth0 != null ? depth0.FormTypeID : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(depth0,{"name":"FormTypeID","hash":{},"data":data}) : helper)))
    + ", '"
    + alias2(((helper = (helper = helpers.FormType || (depth0 != null ? depth0.FormType : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(depth0,{"name":"FormType","hash":{},"data":data}) : helper)))
    + "');\">View/Edit</a>][<a href=\""
    + alias2((helpers.CreatePrintURL || (depth0 && depth0.CreatePrintURL) || alias3).call(depth0,(depth0 != null ? depth0.FormType : depth0),((stack1 = (depth0 != null ? depth0.FormTypeObject : depth0)) != null ? stack1.ReportForm : stack1),(depth0 != null ? depth0.IncidentID : depth0),{"name":"CreatePrintURL","hash":{},"data":data}))
    + "\" target=\"_blank\" class=\"minilink\">Print Incident</a>]<a href=\""
    + alias2((helpers.CreateAttachmentPrintURL || (depth0 && depth0.CreateAttachmentPrintURL) || alias3).call(depth0,(depth0 != null ? depth0.IncidentID : depth0),{"name":"CreateAttachmentPrintURL","hash":{},"data":data}))
    + "\" target=\"_blank\" style=\"display: none;\" class=\"minilink\">Print Incident Attachments</a>\r\n                        </td>\r\n                    </tr>\r\n";
},"6":function(depth0,helpers,partials,data) {
    return "            <p>No incidents found using the supplied search filters.  Please refine your search and try again...</p>\r\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.ShowTooManyRecords : depth0),{"name":"if","hash":{},"fn":this.program(1, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "<br />\r\n<span style=\"font-size: 20px;\">Reported Incidents</span><hr style=\"color: #7ba2d6;\" />\r\n"
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.IncidentTableRecord : depth0),{"name":"if","hash":{},"fn":this.program(3, data, 0, blockParams, depths),"inverse":this.program(6, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "");
},"useData":true,"useDepths":true});