this["CHSITemplates"]["Template_BatchCredit"] = Handlebars.template({"1":function(depth0,helpers,partials,data) {
    var stack1;

  return "<table width=\"850px\" cellspacing=0 cellpadding=2 class=\"dtable sortable\">\r\n    <thead>\r\n        <tr> \r\n            <td style=\"width: 100px;\">Member Type</td>\r\n            <td style=\"width: 140px; text-align: left;\">Member</td>\r\n            <td style=\"width: 340px;\">Participates In</td>\r\n            <td style=\"width: 60px; text-align: right;\">Balance</td>\r\n            <td style=\"width:100px; text-align: center;\">Last Date</td>\r\n            <td style=\"width:115px; text-align: center;\">Amount</td>\r\n            <td>&nbsp;</td>\r\n        </tr>\r\n    </thead>\r\n    <tbody>\r\n"
    + ((stack1 = helpers.each.call(depth0,(depth0 != null ? depth0.Batches : depth0),{"name":"each","hash":{},"fn":this.program(2, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "    </tbody>\r\n</table>\r\n";
},"2":function(depth0,helpers,partials,data) {
    var helper, alias1=helpers.helperMissing, alias2=this.escapeExpression, alias3=this.lambda;

  return "        <tr class=\""
    + alias2((helpers.StyleRow || (depth0 && depth0.StyleRow) || alias1).call(depth0,(data && data.index),"prow","arow",{"name":"StyleRow","hash":{},"data":data}))
    + "\"><td>"
    + alias2(alias3((depth0 != null ? depth0.Industry : depth0), depth0))
    + "</td><td style=\"text-align: left;\">"
    + alias2(alias3((depth0 != null ? depth0.ClientName : depth0), depth0))
    + "</td><td>"
    + alias2(alias3((depth0 != null ? depth0.ParticipatesIn : depth0), depth0))
    + "</td><td style=\"width:100px; text-align: right;\">"
    + alias2((helpers.FormatCurrency || (depth0 && depth0.FormatCurrency) || alias1).call(depth0,(depth0 != null ? depth0.Balance : depth0),true,{"name":"FormatCurrency","hash":{},"data":data}))
    + "</td><td style=\"width:100px; text-align: center;\">"
    + alias2((helpers.CHSIDate || (depth0 && depth0.CHSIDate) || alias1).call(depth0,(depth0 != null ? depth0.LastDate : depth0),{"name":"CHSIDate","hash":{},"data":data}))
    + "</td><td><input type=\"text\" id=\"Amount_"
    + alias2(((helper = (helper = helpers.index || (data && data.index)) != null ? helper : alias1),(typeof helper === "function" ? helper.call(depth0,{"name":"index","hash":{},"data":data}) : helper)))
    + "_"
    + alias2(alias3((depth0 != null ? depth0.ClientID : depth0), depth0))
    + "\" class=\"transaction-amount\" style=\"width:100px; text-align: right;\" /></td></tr>\r\n";
},"4":function(depth0,helpers,partials,data) {
    return "            No results returned.\r\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.Batches : depth0),{"name":"if","hash":{},"fn":this.program(1, data, 0),"inverse":this.program(4, data, 0),"data":data})) != null ? stack1 : "");
},"useData":true});