this["CHSITemplates"]["ClaimManagement"]["IncidentInjuryModalView"] = Handlebars.template({"1":function(depth0,helpers,partials,data) {
    var alias1=this.lambda, alias2=this.escapeExpression;

  return "                <option value=\""
    + alias2(alias1(depth0, depth0))
    + "\">"
    + alias2(alias1(depth0, depth0))
    + "</option>\r\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"popup-form\">\r\n    <div class=\"group-container col-1\">\r\n        <div class=\"input-container col-33\">\r\n            <label>Nature of Injury</label>\r\n            <select id=\"NatureOfInjury\" class=\"popup-form-input\">\r\n"
    + ((stack1 = helpers.each.call(depth0,((stack1 = (depth0 != null ? depth0.Config : depth0)) != null ? stack1.NatureOfInjury : stack1),{"name":"each","hash":{},"fn":this.program(1, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "            </select>\r\n        </div>\r\n        <div class=\"input-container col-3 center\">\r\n            <label>Primary Injury?</label>\r\n            <input id=\"PrimaryInjury\" type=\"checkbox\" class=\"popup-form-input\" />\r\n        </div>\r\n        <div class=\"input-container col-1\">\r\n            <label>Body Part</label>\r\n            <select id=\"BodyPart\" class=\"popup-form-input\">\r\n"
    + ((stack1 = helpers.each.call(depth0,((stack1 = (depth0 != null ? depth0.Config : depth0)) != null ? stack1.BodyPart : stack1),{"name":"each","hash":{},"fn":this.program(1, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "            </select>\r\n        </div>\r\n    </div>\r\n    <div class=\"group-container col-1\">\r\n        <div class=\"input-container col-1\">\r\n            <label>Comments</label>\r\n            <textarea id=\"Comments\" class=\"popup-form-textarea\"></textarea>\r\n        </div>\r\n    </div>\r\n    <div class=\"btn-bar\">\r\n        <input id=\"injury-cancel\" value=\"Cancel\" type=\"button\" style=\"float:right;\" />\r\n        <input id=\"injury-save edit\" value=\"Save\" type=\"button\" style=\"float:right;margin-right:20px\" />\r\n    </div> \r\n</div>\r\n";
},"useData":true});