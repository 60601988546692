this["CHSITemplates"]["Common"]["ValidationAlertsModal"] = Handlebars.template({"1":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.compare || (depth0 && depth0.compare) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.ValidationStatus : depth0),"good",{"name":"compare","hash":{},"fn":this.program(2, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "");
},"2":function(depth0,helpers,partials,data) {
    return "        <div>\r\n            <a class=\"statusicon statusintheoffice\" style=\"display:inline-block\" />\r\n            <span>"
    + this.escapeExpression(this.lambda((depth0 != null ? depth0.ValidationMessage : depth0), depth0))
    + "</span>\r\n        </div>\r\n";
},"4":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.compare || (depth0 && depth0.compare) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.ValidationStatus : depth0),"warning",{"name":"compare","hash":{},"fn":this.program(5, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "");
},"5":function(depth0,helpers,partials,data) {
    return "        <div>\r\n            <a class=\"statusicon statusvacation\" style=\"display:inline-block\"/>\r\n            <span>"
    + this.escapeExpression(this.lambda((depth0 != null ? depth0.ValidationMessage : depth0), depth0))
    + "</span>\r\n        </div>\r\n";
},"7":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.compare || (depth0 && depth0.compare) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.ValidationStatus : depth0),"critical",{"name":"compare","hash":{},"fn":this.program(8, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "");
},"8":function(depth0,helpers,partials,data) {
    return "        <div>\r\n            <a class=\"statusicon statusoutofoffice\" style=\"display:inline-block\"/>\r\n            <span>"
    + this.escapeExpression(this.lambda((depth0 != null ? depth0.ValidationMessage : depth0), depth0))
    + "</span>\r\n        </div>\r\n";
},"10":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.compare || (depth0 && depth0.compare) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.ValidationStatus : depth0),"error",{"name":"compare","hash":{},"fn":this.program(11, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "");
},"11":function(depth0,helpers,partials,data) {
    return "        <div>\r\n            <a class=\"statusicon statusunknown\" style=\"display:inline-block\"/>\r\n            <span>"
    + this.escapeExpression(this.lambda((depth0 != null ? depth0.ValidationMessage : depth0), depth0))
    + "</span>\r\n        </div>\r\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"popup-form\" style=\"margin:8px\">\r\n    <div class=\"group-container col-1\">\r\n"
    + ((stack1 = helpers.each.call(depth0,(depth0 != null ? depth0.results : depth0),{"name":"each","hash":{},"fn":this.program(1, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers.each.call(depth0,(depth0 != null ? depth0.results : depth0),{"name":"each","hash":{},"fn":this.program(4, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers.each.call(depth0,(depth0 != null ? depth0.results : depth0),{"name":"each","hash":{},"fn":this.program(7, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers.each.call(depth0,(depth0 != null ? depth0.results : depth0),{"name":"each","hash":{},"fn":this.program(10, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "    </div>\r\n</div>\r\n<div class=\"btn-bar\">\r\n    <input class=\"validation-cancel\" value=\"Cancel\" type=\"button\" style=\"float:right;\" />\r\n</div> ";
},"useData":true});