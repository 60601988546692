this["CHSITemplates"]["Template_AttachmentGrid"] = Handlebars.template({"1":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.each.call(depth0,(depth0 != null ? depth0.Attachments : depth0),{"name":"each","hash":{},"fn":this.program(2, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "");
},"2":function(depth0,helpers,partials,data) {
    var stack1, helper, alias1=helpers.helperMissing, alias2="function", alias3=this.escapeExpression;

  return "            <tr>\r\n                <td>\r\n                    <img src=\"/Images/Icons/"
    + alias3(((helper = (helper = helpers.IconFile || (depth0 != null ? depth0.IconFile : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"IconFile","hash":{},"data":data}) : helper)))
    + "\" alt=\""
    + alias3(((helper = (helper = helpers.FileType || (depth0 != null ? depth0.FileType : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"FileType","hash":{},"data":data}) : helper)))
    + "\" />\r\n                </td>\r\n                <td>\r\n                   "
    + alias3((helpers.TruncateString || (depth0 && depth0.TruncateString) || alias1).call(depth0,(depth0 != null ? depth0.FileName : depth0),50,{"name":"TruncateString","hash":{},"data":data}))
    + "\r\n                </td>\r\n                <td>\r\n                    "
    + alias3((helpers.TruncateString || (depth0 && depth0.TruncateString) || alias1).call(depth0,(depth0 != null ? depth0.Description : depth0),75,{"name":"TruncateString","hash":{},"data":data}))
    + "\r\n                </td>\r\n                <td>\r\n                    "
    + alias3(((helper = (helper = helpers.PolicyPeriod || (depth0 != null ? depth0.PolicyPeriod : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"PolicyPeriod","hash":{},"data":data}) : helper)))
    + "\r\n                </td>\r\n                <td>\r\n                    "
    + alias3((helpers.CHSIDate || (depth0 && depth0.CHSIDate) || alias1).call(depth0,(depth0 != null ? depth0.ItemDate : depth0),{"name":"CHSIDate","hash":{},"data":data}))
    + "\r\n                </td>\r\n                <td>\r\n                    [<a onclick=\"CHSI.Common.Attachments.Events.ShowFileUploader("
    + alias3(((helper = (helper = helpers.AttachmentID || (depth0 != null ? depth0.AttachmentID : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"AttachmentID","hash":{},"data":data}) : helper)))
    + ", "
    + alias3(((helper = (helper = helpers.DefaultSectionID || (depth0 != null ? depth0.DefaultSectionID : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"DefaultSectionID","hash":{},"data":data}) : helper)))
    + ");\" class=\"minilink\">Details</a>]\r\n                </td>\r\n                <td>\r\n                    [<a href=\"chsifilehandler.axd?fileid="
    + alias3(((helper = (helper = helpers.FileID || (depth0 != null ? depth0.FileID : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"FileID","hash":{},"data":data}) : helper)))
    + "\" class=\"minilink\">View</a>]\r\n                </td>\r\n                <td>\r\n                    [<a onclick=\"CHSI.Common.Attachments.Events.DeleteAttachment("
    + alias3(((helper = (helper = helpers.AttachmentID || (depth0 != null ? depth0.AttachmentID : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"AttachmentID","hash":{},"data":data}) : helper)))
    + ", "
    + alias3(((helper = (helper = helpers.FileID || (depth0 != null ? depth0.FileID : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"FileID","hash":{},"data":data}) : helper)))
    + ", '"
    + alias3(((helper = (helper = helpers.ItemDate || (depth0 != null ? depth0.ItemDate : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"ItemDate","hash":{},"data":data}) : helper)))
    + "', '"
    + alias3((helpers.escapeScriptHTML || (depth0 && depth0.escapeScriptHTML) || alias1).call(depth0,(depth0 != null ? depth0.Description : depth0),{"name":"escapeScriptHTML","hash":{},"data":data}))
    + "', '"
    + alias3((helpers.escapeScriptHTML || (depth0 && depth0.escapeScriptHTML) || alias1).call(depth0,(depth0 != null ? depth0.FileName : depth0),{"name":"escapeScriptHTML","hash":{},"data":data}))
    + "', "
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.PolicyPeriod : depth0),{"name":"if","hash":{},"fn":this.program(3, data, 0),"inverse":this.program(5, data, 0),"data":data})) != null ? stack1 : "")
    + ", '"
    + alias3(((helper = (helper = helpers.FileType || (depth0 != null ? depth0.FileType : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"FileType","hash":{},"data":data}) : helper)))
    + "', '"
    + alias3(((helper = (helper = helpers.CreateUser || (depth0 != null ? depth0.CreateUser : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"CreateUser","hash":{},"data":data}) : helper)))
    + "', '"
    + alias3(((helper = (helper = helpers.Createtmsp || (depth0 != null ? depth0.Createtmsp : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"Createtmsp","hash":{},"data":data}) : helper)))
    + "', '"
    + alias3(((helper = (helper = helpers.UpdateUser || (depth0 != null ? depth0.UpdateUser : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"UpdateUser","hash":{},"data":data}) : helper)))
    + "');\" class=\"minilink\">Delete</a>]\r\n                </td>\r\n            </tr>\r\n";
},"3":function(depth0,helpers,partials,data) {
    var helper;

  return this.escapeExpression(((helper = (helper = helpers.PolicyPeriod || (depth0 != null ? depth0.PolicyPeriod : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"PolicyPeriod","hash":{},"data":data}) : helper)));
},"5":function(depth0,helpers,partials,data) {
    return "0";
},"7":function(depth0,helpers,partials,data) {
    return "            <tr>\r\n                <td>\r\n                    &nbsp;\r\n                </td>\r\n                <td>\r\n                    No Attachments found.\r\n                </td>\r\n                <td>\r\n                    &nbsp;\r\n                </td>\r\n                <td>\r\n                    &nbsp;\r\n                </td>\r\n                <td>\r\n                    &nbsp;\r\n                </td>\r\n                <td>\r\n                    &nbsp;\r\n                </td>\r\n                <td>\r\n                    &nbsp;\r\n                </td>\r\n                <td>\r\n                    &nbsp;\r\n                </td>\r\n            </tr>\r\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1;

  return "    To upload one or more files, click New File or Drag and Drop the files to the grid below.\r\n<input type=\"hidden\" id=\"jxAllowDocuments\" value=\"\" />\r\n<div id=\"jxAttachmentGrid\">\r\n    <table class=\"dtable sortable\">\r\n        <thead>\r\n            <tr>\r\n                <td width=\"30\">\r\n                    &nbsp;\r\n                </td>\r\n                <td width=\"350\">\r\n                    File Name\r\n                </td>\r\n                <td width=\"350\">\r\n                    Description\r\n                </td>\r\n                <td width=\"200\">\r\n                    Policy Year\r\n                </td>\r\n                <td width=\"200\">\r\n                    Item Date\r\n                </td>\r\n                <td width=\"50\" class=\"sorttable_nosort\">\r\n                    Options\r\n                </td>\r\n                <td width=\"40\" class=\"sorttable_nosort\">\r\n                    &nbsp;\r\n                </td>\r\n                <td width=\"40\" class=\"sorttable_nosort\">\r\n                    &nbsp;\r\n                </td>\r\n            </tr>\r\n        </thead>\r\n        <tbody>\r\n"
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.Attachments : depth0),{"name":"if","hash":{},"fn":this.program(1, data, 0),"inverse":this.program(7, data, 0),"data":data})) != null ? stack1 : "")
    + "        </tbody>\r\n    </table>\r\n</div>";
},"useData":true});