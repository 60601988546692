this["CHSITemplates"]["ClaimManagement"] = this["CHSITemplates"]["ClaimManagement"] || {};
this["CHSITemplates"]["ClaimManagement"]["AssigneeModalView"] = Handlebars.template({"1":function(depth0,helpers,partials,data) {
    return " disabled ";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1, alias1=this.lambda, alias2=this.escapeExpression;

  return "<div class=\"popup-form\">\r\n    <div class=\"group-container col-1\">\r\n        <div class=\"input-container col-2\">\r\n            <label>Assignee Name</label>\r\n            <input maxlength=\"100\" type=\"text\" id=\"AssigneeName\" class=\"popup-form-input\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.Assignee : depth0)) != null ? stack1.AssigneeName : stack1), depth0))
    + "\" />\r\n        </div>\r\n        <div class=\"input-container col-2\">\r\n            <label>User ID</label>\r\n            <input maxlength=\"20\" type=\"text\" id=\"UserID\" class=\"popup-form-input\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.Assignee : depth0)) != null ? stack1.UserID : stack1), depth0))
    + "\" />\r\n        </div>\r\n        <div class=\"input-container col-2\">\r\n            <label>Email</label>\r\n            <input maxlength=\"100\" type=\"text\" id=\"Email\" class=\"popup-form-input\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.Assignee : depth0)) != null ? stack1.Email : stack1), depth0))
    + "\" />\r\n        </div>\r\n        <div class=\"input-container col-1\">\r\n            <label>Address</label>\r\n            <input maxlength=\"100\" type=\"text\" id=\"Address1\" class=\"popup-form-input\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.Assignee : depth0)) != null ? stack1.Address1 : stack1), depth0))
    + "\" />\r\n        </div>\r\n        <div class=\"input-container col-1\">\r\n            <input maxlength=\"100\" type=\"text\" id=\"Address2\" class=\"popup-form-input\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.Assignee : depth0)) != null ? stack1.Address2 : stack1), depth0))
    + "\" />\r\n        </div>\r\n        <div class=\"input-container col-2\">\r\n            <label>City</label>\r\n            <input maxlength=\"30\" type=\"text\" id=\"City\" class=\"popup-form-input\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.Assignee : depth0)) != null ? stack1.City : stack1), depth0))
    + "\" />\r\n        </div>\r\n        <div class=\"input-container col-4\">\r\n            <label>State</label>\r\n            <input maxlength=\"2\" type=\"text\" id=\"State\" class=\"popup-form-input\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.Assignee : depth0)) != null ? stack1.State : stack1), depth0))
    + "\" />\r\n        </div>\r\n        <div class=\"input-container col-4\">\r\n            <label>Zip</label>\r\n            <input maxlength=\"10\" type=\"text\" id=\"Zip\" class=\"popup-form-input\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.Assignee : depth0)) != null ? stack1.Zip : stack1), depth0))
    + "\" />\r\n        </div>\r\n        <div class=\"input-container col-2\">\r\n            <label>Primary Phone</label>\r\n            <input maxlength=\"10\" type=\"text\" id=\"Phone1\" class=\"popup-form-input phone-mask\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.Assignee : depth0)) != null ? stack1.Phone1 : stack1), depth0))
    + "\" />\r\n        </div>\r\n        <div class=\"input-container col-2\">\r\n            <label>Fax</label>\r\n            <input maxlength=\"10\" type=\"text\" id=\"Fax\" class=\"popup-form-input phone-mask\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.Assignee : depth0)) != null ? stack1.Fax : stack1), depth0))
    + "\" />\r\n        </div>\r\n    </div>\r\n</div>\r\n<div class=\"btn-bar\">\r\n    <!--<input class=\"Assignee-delete\" value=\"Delete\" type=\"button\" style=\"float:left;\" "
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.isNew : depth0),{"name":"if","hash":{},"fn":this.program(1, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + " />-->\r\n    <input class=\"assignee-cancel\" value=\"Cancel\" type=\"button\" style=\"float:right;\" />\r\n    <input class=\"assignee-save edit\" value=\"Save\" type=\"button\" style=\"float:right;margin-right:20px;\" />\r\n</div> ";
},"useData":true});