this["CHSITemplates"]["ClaimManagement"]["ClaimantDetailsView"] = Handlebars.template({"1":function(depth0,helpers,partials,data) {
    return " selected";
},"3":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=this.lambda, alias2=this.escapeExpression;

  return "                <option value=\""
    + alias2(alias1(depth0, depth0))
    + "\" "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || helpers.helperMissing).call(depth0,depth0,((stack1 = (depths[1] != null ? depths[1].Claimant : depths[1])) != null ? stack1.MaritalStatus : stack1),{"name":"compare","hash":{},"fn":this.program(1, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ">"
    + alias2(alias1(depth0, depth0))
    + "</option>\r\n";
},"5":function(depth0,helpers,partials,data) {
    return "checked";
},"7":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=this.lambda, alias2=this.escapeExpression;

  return "                <option value=\""
    + alias2(alias1(depth0, depth0))
    + "\" "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || helpers.helperMissing).call(depth0,depth0,((stack1 = (depths[1] != null ? depths[1].Claimant : depths[1])) != null ? stack1.PrimaryLanguage : stack1),{"name":"compare","hash":{},"fn":this.program(8, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ">"
    + alias2(alias1(depth0, depth0))
    + "</option>\r\n";
},"8":function(depth0,helpers,partials,data) {
    return "selected";
},"10":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=this.lambda, alias2=this.escapeExpression;

  return "                <option value=\""
    + alias2(alias1(depth0, depth0))
    + "\" "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || helpers.helperMissing).call(depth0,depth0,((stack1 = (depths[1] != null ? depths[1].Claimant : depths[1])) != null ? stack1.EmploymentType : stack1),{"name":"compare","hash":{},"fn":this.program(8, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ">"
    + alias2(alias1(depth0, depth0))
    + "</option>\r\n";
},"12":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=this.lambda, alias2=this.escapeExpression;

  return "                <option value=\""
    + alias2(alias1(depth0, depth0))
    + "\" "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || helpers.helperMissing).call(depth0,depth0,((stack1 = (depths[1] != null ? depths[1].Claimant : depths[1])) != null ? stack1.WageFreq : stack1),{"name":"compare","hash":{},"fn":this.program(8, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ">"
    + alias2(alias1(depth0, depth0))
    + "</option>\r\n";
},"14":function(depth0,helpers,partials,data) {
    return " checked";
},"16":function(depth0,helpers,partials,data) {
    return " disabled ";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=helpers.helperMissing, alias2=this.lambda, alias3=this.escapeExpression;

  return "<!-- claim management claimant details view -->\r\n<div id=\"claimant-details\" class=\"popup-form\" style=\"height:100%;min-height:555px;margin:0 8px 0 0;\">\r\n    <div class=\"group-container\" style=\"\">\r\n        <h1>Claimant Info</h1>\r\n        <div class=\"input-container col-2\">\r\n            <label>Claimant Type</label>\r\n            <select id=\"ClaimantType\" class=\"popup-form-input\">\r\n                <option value=\"Individual\" "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias1).call(depth0,"Individual",((stack1 = (depth0 != null ? depth0.Claimant : depth0)) != null ? stack1.ClaimantType : stack1),{"name":"compare","hash":{},"fn":this.program(1, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ">Individual</option>\r\n                <option value=\"Company\" "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias1).call(depth0,"Company",((stack1 = (depth0 != null ? depth0.Claimant : depth0)) != null ? stack1.ClaimantType : stack1),{"name":"compare","hash":{},"fn":this.program(1, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ">Company</option>\r\n            </select>\r\n        </div>\r\n        <div class=\"input-container col-1\">\r\n            <label>Claimant Name</label>\r\n            <input maxlength=\"200\" type=\"text\" id=\"ClaimantName\" class=\"popup-form-input\" value=\""
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.Claimant : depth0)) != null ? stack1.ClaimantName : stack1), depth0))
    + "\" />\r\n        </div>\r\n        <div class=\"input-container col-1\">\r\n            <label>Tax ID / SSN</label>\r\n            <input maxlength=\"20\" type=\"text\" id=\"TaxID\" class=\"popup-form-input\" value=\""
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.Claimant : depth0)) != null ? stack1.TaxID : stack1), depth0))
    + "\" />\r\n        </div>\r\n        <div class=\"input-container col-2 individual-only\">\r\n            <label>Birth Date</label>\r\n            <input type=\"text\" id=\"DateOfBirth\" class=\"popup-form-input date\" value=\""
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.Claimant : depth0)) != null ? stack1.DateOfBirth : stack1), depth0))
    + "\" />\r\n        </div>\r\n        <div class=\"input-container col-2 individual-only\">\r\n            <label>Death Date</label>\r\n            <input type=\"text\" id=\"DateOfDeath\" class=\"popup-form-input date\" value=\""
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.Claimant : depth0)) != null ? stack1.DateOfDeath : stack1), depth0))
    + "\" />\r\n        </div>\r\n        <div class=\"input-container col-2 individual-only\">\r\n            <label>Marital Status</label>\r\n            <select id=\"MaritalStatus\" class=\"popup-form-input\">\r\n"
    + ((stack1 = helpers.each.call(depth0,((stack1 = (depth0 != null ? depth0.Config : depth0)) != null ? stack1.MaritalStatus : stack1),{"name":"each","hash":{},"fn":this.program(3, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "                <option value=\"Other\" "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias1).call(depth0,"Other",((stack1 = (depth0 != null ? depth0.Claimant : depth0)) != null ? stack1.MaritalStatus : stack1),{"name":"compare","hash":{},"fn":this.program(1, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ">Other</option>\r\n            </select>\r\n        </div>\r\n        <div class=\"input-container col-2 individual-only\">\r\n            <label>Dependents</label>\r\n            <input type=\"text\" id=\"Dependents\" class=\"popup-form-input\" value=\""
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.Claimant : depth0)) != null ? stack1.Dependents : stack1), depth0))
    + "\" />\r\n        </div>\r\n        <div class=\"input-container col-2 individual-only\">\r\n            <label>Gender</label>\r\n            <select id=\"Sex\" class=\"popup-form-input\">\r\n                <option value=\"M\" "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias1).call(depth0,"M",((stack1 = (depth0 != null ? depth0.Claimant : depth0)) != null ? stack1.Sex : stack1),{"name":"compare","hash":{},"fn":this.program(1, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ">Male</option>\r\n                <option value=\"F\" "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias1).call(depth0,"F",((stack1 = (depth0 != null ? depth0.Claimant : depth0)) != null ? stack1.Sex : stack1),{"name":"compare","hash":{},"fn":this.program(1, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ">Female</option>\r\n            </select>\r\n        </div>\r\n        <div class=\"input-container col-2 center individual-only\">\r\n            <label>Medicare Eligible?</label>\r\n            <input type=\"checkbox\" id=\"MedicareEligible\" class=\"popup-form-input\" value=\""
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.Claimant : depth0)) != null ? stack1.MedicareEligible : stack1), depth0))
    + "\" "
    + ((stack1 = helpers["if"].call(depth0,((stack1 = (depth0 != null ? depth0.Claimant : depth0)) != null ? stack1.MedicareEligible : stack1),{"name":"if","hash":{},"fn":this.program(5, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + " />\r\n        </div>\r\n    </div>\r\n\r\n    <div id=\"claimant-view-contact-info\" class=\"group-container\" style=\"\">\r\n        <h1>Contact Info</h1>\r\n        <div class=\"input-container col-3\">\r\n            <label>First Name</label>\r\n            <input maxlength=\"50\" type=\"text\" id=\"FirstName\" class=\"popup-form-input\" value=\""
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.Claimant : depth0)) != null ? stack1.FirstName : stack1), depth0))
    + "\" />\r\n        </div>\r\n        <div class=\"input-container col-3\">\r\n            <label>Middle Name</label>\r\n            <input maxlength=\"20\" type=\"text\" id=\"MiddleName\" class=\"popup-form-input\" value=\""
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.Claimant : depth0)) != null ? stack1.MiddleName : stack1), depth0))
    + "\" />\r\n        </div>\r\n        <div class=\"input-container col-3\">\r\n            <label>Last Name</label>\r\n            <input maxlength=\"50\" type=\"text\" id=\"LastName\" class=\"popup-form-input\" value=\""
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.Claimant : depth0)) != null ? stack1.LastName : stack1), depth0))
    + "\" />\r\n        </div>\r\n        <div class=\"input-container col-1\">\r\n            <label>Address</label>\r\n            <input maxlength=\"100\" type=\"text\" id=\"Address1\" class=\"popup-form-input\" value=\""
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.Claimant : depth0)) != null ? stack1.Address1 : stack1), depth0))
    + "\" />\r\n        </div>\r\n        <div class=\"input-container col-1\">\r\n            <input maxlength=\"100\" type=\"text\" id=\"Address2\" class=\"popup-form-input\" value=\""
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.Claimant : depth0)) != null ? stack1.Address2 : stack1), depth0))
    + "\" />\r\n        </div>\r\n        <div class=\"input-container col-2\">\r\n            <label>City</label>\r\n            <input maxlength=\"50\" type=\"text\" id=\"City\" class=\"popup-form-input\" value=\""
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.Claimant : depth0)) != null ? stack1.City : stack1), depth0))
    + "\" />\r\n        </div>\r\n        <div class=\"input-container col-4\">\r\n            <label>State</label>\r\n            <input maxlength=\"2\" type=\"text\" id=\"State\" class=\"popup-form-input\" value=\""
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.Claimant : depth0)) != null ? stack1.State : stack1), depth0))
    + "\" />\r\n        </div>\r\n        <div class=\"input-container col-4\">\r\n            <label>Zip</label>\r\n            <input maxlength=\"10\" type=\"text\" id=\"Zip\" class=\"popup-form-input\" value=\""
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.Claimant : depth0)) != null ? stack1.Zip : stack1), depth0))
    + "\" />\r\n        </div>\r\n        <div class=\"input-container col-1\">\r\n            <label>County</label>\r\n            <input maxlength=\"50\" type=\"text\" id=\"County\" class=\"popup-form-input\" value=\""
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.Claimant : depth0)) != null ? stack1.County : stack1), depth0))
    + "\" />\r\n        </div>\r\n        <div class=\"input-container col-2\">\r\n            <label>Primary Phone</label>\r\n            <input maxlength=\"10\" type=\"text\" id=\"Phone1\" class=\"popup-form-input phone-mask\" value=\""
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.Claimant : depth0)) != null ? stack1.Phone1 : stack1), depth0))
    + "\" />\r\n        </div>\r\n        <div class=\"input-container col-2\">\r\n            <label>Email</label>\r\n            <input maxlength=\"100\" type=\"text\" id=\"Email\" class=\"popup-form-input\" value=\""
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.Claimant : depth0)) != null ? stack1.Email : stack1), depth0))
    + "\" />\r\n        </div>\r\n        <div class=\"input-container col-1\">\r\n            <label>Primary Language</label>\r\n            <select id=\"PrimaryLanguage\" class=\"popup-form-input\">\r\n"
    + ((stack1 = helpers.each.call(depth0,((stack1 = (depth0 != null ? depth0.Config : depth0)) != null ? stack1.PrimaryLanguage : stack1),{"name":"each","hash":{},"fn":this.program(7, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "                <option value=\"Other\" "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias1).call(depth0,"Other",((stack1 = (depth0 != null ? depth0.Claimant : depth0)) != null ? stack1.PrimaryLanguage : stack1),{"name":"compare","hash":{},"fn":this.program(1, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ">Other</option>\r\n            </select>\r\n        </div>\r\n    </div>\r\n\r\n    <!--\r\n    <div id=\"claimant-view-employment-info\" class=\"group-container group-bg row-33\" style=\"width:31%;margin:1% 1% 0 1%;\">\r\n        <h1>Employment Info</h1>\r\n        <div class=\"input-container col-2\">\r\n            <label>Hire Date</label>\r\n            <input type=\"text\" id=\"DateHired\" class=\"popup-form-input date\" value=\""
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.Claimant : depth0)) != null ? stack1.DateHired : stack1), depth0))
    + "\" />\r\n        </div>\r\n        <div class=\"input-container col-2\">\r\n            <label>Termination Date</label>\r\n            <input type=\"text\" id=\"DateTerminated\" class=\"popup-form-input date\" value=\""
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.Claimant : depth0)) != null ? stack1.DateTerminated : stack1), depth0))
    + "\" />\r\n        </div>\r\n        <div class=\"input-container col-1\">\r\n            <label>Occupation</label>\r\n            <input maxlength=\"50\" type=\"text\" id=\"Occupation\" class=\"popup-form-input\" value=\""
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.Claimant : depth0)) != null ? stack1.Occupation : stack1), depth0))
    + "\" />\r\n        </div>\r\n        <div class=\"input-container col-1\">\r\n            <label>Department</label>\r\n            <input maxlength=\"50\" type=\"text\" id=\"Department\" class=\"popup-form-input\" value=\""
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.Claimant : depth0)) != null ? stack1.Department : stack1), depth0))
    + "\" />\r\n        </div>\r\n        <div class=\"input-container col-1\">\r\n            <label>Supervisor</label>\r\n            <input maxlength=\"50\" type=\"text\" id=\"Supervisor\" class=\"popup-form-input\" value=\""
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.Claimant : depth0)) != null ? stack1.Supervisor : stack1), depth0))
    + "\" />\r\n        </div>\r\n        <div class=\"input-container col-2\">\r\n            <label>Class Code</label>\r\n            <input maxlength=\"6\" type=\"text\" id=\"ClassCode\" class=\"popup-form-input\" value=\""
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.Claimant : depth0)) != null ? stack1.ClassCode : stack1), depth0))
    + "\" />\r\n        </div>\r\n        <div class=\"input-container col-2\">\r\n            <label>Employee ID</label>\r\n            <input maxlength=\"20\" type=\"text\" id=\"EmployeeID\" class=\"popup-form-input\" value=\""
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.Claimant : depth0)) != null ? stack1.EmployeeID : stack1), depth0))
    + "\" />\r\n        </div>\r\n        <div class=\"input-container col-1\">\r\n            <label>Employment Type</label>\r\n            <select id=\"EmploymentType\" class=\"popup-form-input\">\r\n"
    + ((stack1 = helpers.each.call(depth0,((stack1 = (depth0 != null ? depth0.Config : depth0)) != null ? stack1.EmploymentType : stack1),{"name":"each","hash":{},"fn":this.program(10, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "                <option value=\"Other\" "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias1).call(depth0,"Other",((stack1 = (depth0 != null ? depth0.Claimant : depth0)) != null ? stack1.EmploymentType : stack1),{"name":"compare","hash":{},"fn":this.program(1, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ">Other</option>\r\n            </select>\r\n        </div>\r\n        <div class=\"input-container col-2\">\r\n            <label>Wage</label>\r\n            <input maxlength=\"100\" type=\"number\" id=\"Wage\" class=\"popup-form-input\" value=\""
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.Claimant : depth0)) != null ? stack1.Wage : stack1), depth0))
    + "\" />\r\n        </div>\r\n        <div class=\"input-container col-2\">\r\n            <label>Freq</label>\r\n            <select id=\"WageFreq\" class=\"popup-form-input\">\r\n"
    + ((stack1 = helpers.each.call(depth0,((stack1 = (depth0 != null ? depth0.Config : depth0)) != null ? stack1.WageFreq : stack1),{"name":"each","hash":{},"fn":this.program(12, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "                <option value=\"Other\" "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias1).call(depth0,"Other",((stack1 = (depth0 != null ? depth0.Claimant : depth0)) != null ? stack1.WageFreq : stack1),{"name":"compare","hash":{},"fn":this.program(1, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ">Other</option>\r\n            </select>\r\n        </div>\r\n    </div>\r\n    <div class=\"group-container group-bg\" style=\"width:97%;margin:1% 1% 0 1%;\">\r\n        <div class=\"group-container col-2\">\r\n            <div class=\"input-container col-1\">\r\n                <label>Temporary Disability</label>\r\n                <input maxlength=\"100\" type=\"number\" id=\"TempDisabilityRate\" class=\"popup-form-input\" value=\""
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.Claimant : depth0)) != null ? stack1.TempDisabilityRate : stack1), depth0))
    + "\" />\r\n            </div>\r\n            <div class=\"input-container col-1\">\r\n                <label>Permanent Disability</label>\r\n                <input maxlength=\"100\" type=\"number\" id=\"PermDisabilityRate\" class=\"popup-form-input\" value=\""
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.Claimant : depth0)) != null ? stack1.PermDisabilityRate : stack1), depth0))
    + "\" />\r\n            </div>\r\n            <div class=\"input-container col-1\">\r\n                <label>Vocational Rehab</label>\r\n                <input maxlength=\"100\" type=\"number\" id=\"VocationalRehabRate\" class=\"popup-form-input\" value=\""
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.Claimant : depth0)) != null ? stack1.VocationalRehabRate : stack1), depth0))
    + "\" />\r\n            </div>\r\n        </div>\r\n        <div class=\"group-container col-2\">\r\n            <div class=\"input-container col-2 center\">\r\n                <label>Others Involved?</label>\r\n                <input type=\"checkbox\" id=\"OthersInvolved\" class=\"popup-form-input\" value=\"Claimant.OthersInvolved\" "
    + ((stack1 = helpers["if"].call(depth0,((stack1 = (depth0 != null ? depth0.Claimant : depth0)) != null ? stack1.OthersInvolved : stack1),{"name":"if","hash":{},"fn":this.program(14, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + " />\r\n            </div>\r\n            <div class=\"input-container col-2\">\r\n                <label>First Day Lost</label>\r\n                <input type=\"text\" id=\"FirstDayLost\" class=\"popup-form-input date\" value=\""
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.Claimant : depth0)) != null ? stack1.FirstDayLost : stack1), depth0))
    + "\" />\r\n            </div>\r\n            <div class=\"input-container col-2 center\">\r\n                <label>Full Day Lost?</label>\r\n                <input type=\"checkbox\" id=\"FullDayLost\" class=\"popup-form-input\" value=\""
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.Claimant : depth0)) != null ? stack1.FullDayLost : stack1), depth0))
    + "\" "
    + ((stack1 = helpers["if"].call(depth0,((stack1 = (depth0 != null ? depth0.Claimant : depth0)) != null ? stack1.FullDayLost : stack1),{"name":"if","hash":{},"fn":this.program(14, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + " />\r\n            </div>\r\n            <div class=\"input-container col-2\">\r\n                <label>Last Day Worked</label>\r\n                <input type=\"text\" id=\"LastDayWorked\" class=\"popup-form-input date\" value=\""
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.Claimant : depth0)) != null ? stack1.LastDayWorked : stack1), depth0))
    + "\" />\r\n            </div>\r\n            <div class=\"input-container col-2 center\">\r\n                <label>Full Day Worked?</label>\r\n                <input type=\"checkbox\" id=\"FullPayOnLastDay\" class=\"popup-form-input\" value=\""
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.Claimant : depth0)) != null ? stack1.FullPayOnLastDay : stack1), depth0))
    + "\" "
    + ((stack1 = helpers["if"].call(depth0,((stack1 = (depth0 != null ? depth0.Claimant : depth0)) != null ? stack1.FullPayOnLastDay : stack1),{"name":"if","hash":{},"fn":this.program(14, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + " />\r\n            </div>\r\n            <div class=\"input-container col-2 center\">\r\n                <label>Primary Claimant?</label>\r\n                <input type=\"checkbox\" id=\"PrimaryClaimant\" class=\"popup-form-input\" value=\""
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.Claimant : depth0)) != null ? stack1.PrimaryClaimant : stack1), depth0))
    + "\" "
    + ((stack1 = helpers["if"].call(depth0,((stack1 = (depth0 != null ? depth0.Claimant : depth0)) != null ? stack1.PrimaryClaimant : stack1),{"name":"if","hash":{},"fn":this.program(5, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + " />\r\n            </div>\r\n        </div>\r\n    </div>\r\n        -->\r\n</div>\r\n<div class=\"btn-bar\" style=\"margin:0 1%; width:97%\">\r\n    <input id=\"claimant-delete\" value=\"Delete\" type=\"button\" style=\"float:left;\" "
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.isNew : depth0),{"name":"if","hash":{},"fn":this.program(16, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + " />\r\n    <input id=\"claimant-cancel\" value=\"Cancel\" type=\"button\" style=\"float:right;\" />\r\n    <input id=\"claimant-save\" value=\"Save\" type=\"button\" style=\"float:right;margin-right:20px\" />\r\n</div> ";
},"useData":true,"useDepths":true});