this["CHSITemplates"]["AdminTools_Questionnaire_Notification_Editor"] = Handlebars.template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1, alias1=this.lambda, alias2=this.escapeExpression, alias3=helpers.helperMissing;

  return "<style>\r\n    #notification_editor{\r\n        display: none;\r\n        margin: 10px;\r\n    }\r\n    .loading_page {\r\n        background-image: url('/Images/Icons/loading.gif');\r\n        background-size: 22px 22px;\r\n        background-repeat: no-repeat;\r\n        width: 175px;\r\n        height: 17px;\r\n        z-index: 99;\r\n        text-align: right;\r\n        line-height: 17px;\r\n        font-weight: bold;\r\n        padding: 2px;\r\n        margin: 0 auto;\r\n        margin-top: 245px;\r\n    }\r\n\r\n    #notification_editor > div {\r\n        margin-top: 2px;\r\n        margin-bottom: 2px;\r\n    }\r\n\r\n    #notification_editor > div > label > span {\r\n        font-weight: bold;\r\n    }\r\n    #editor_container{\r\n        margin-top: 6px !important;\r\n    }\r\n    .date{\r\n        width: 70px;\r\n    }    \r\n    label > input, select {\r\n        margin-left: 4px;\r\n    }\r\n    #notification_name { width: 620px; }    \r\n    #notification_cc { width: 821px }\r\n    #notification_bcc { width : 811px }\r\n    #notification_subject { width: 870px; }\r\n    #notification_cancel{ float: right; }\r\n    #bottom_button_container { position: absolute; bottom: 18px; width: 98%; height: 30px; }\r\n\r\n    .validation-error{\r\n            border: 1px solid #FF7F7F;\r\n            padding: 2px 0px;\r\n        }\r\n    #notification_message{\r\n            display: inline;\r\n    }\r\n    .CHSI-ModalClose{\r\n        display:none;\r\n    }\r\n</style>\r\n\r\n<div id=\"loading_notification_editor\" class=\"loading_page\">Preparing Form Editor...</div>\r\n\r\n<div id=\"notification_editor\" data-notificationid=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.Notification : depth0)) != null ? stack1.NotificationID : stack1), depth0))
    + "\">\r\n    <div>\r\n        <label>\r\n            <span>Notification Name:</span>\r\n            <input id=\"notification_name\" class=\"required_validation\" data-errormessage=\"Please add a notification name.\" type=\"text\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.Notification : depth0)) != null ? stack1.NotificationName : stack1), depth0))
    + "\" />\r\n        </label>\r\n        <label>\r\n            <span>Add User As Recipient?:</span>\r\n            <input id=\"notification_recipient\" type=\"checkbox\" "
    + ((stack1 = (helpers.MarkAsChecked || (depth0 && depth0.MarkAsChecked) || alias3).call(depth0,((stack1 = (depth0 != null ? depth0.Notification : depth0)) != null ? stack1.AddUserAsRecipient : stack1),{"name":"MarkAsChecked","hash":{},"data":data})) != null ? stack1 : "")
    + " />\r\n        </label>\r\n    </div>\r\n    <div>\r\n        <label>\r\n            <span>Form:</span>\r\n            <select id=\"notification_form\" class=\"required_validation\"></select>\r\n        </label>\r\n        <label>\r\n            <span>Email Template:</span>\r\n            <select id=\"notification_email_template\"></select>\r\n        </label>\r\n        <label>\r\n            <span>Copy Body From:</span>\r\n            <select id=\"notification_copy\"></select>\r\n            <button id=\"copy_from_notification\" disabled>Copy</button>\r\n        </label>                \r\n    </div>\r\n    <div>\r\n        <label>\r\n            <span>Status Trigger:</span>\r\n            <select id=\"notification_status_trigger\"></select>\r\n        </label>        \r\n        <label>\r\n            <span>Active Date:</span>\r\n            <input id=\"notification_active_date\" class=\"date\" type=\"text\" value=\""
    + alias2((helpers.CHSIDate || (depth0 && depth0.CHSIDate) || alias3).call(depth0,((stack1 = (depth0 != null ? depth0.Notification : depth0)) != null ? stack1.ActiveDate : stack1),{"name":"CHSIDate","hash":{},"data":data}))
    + "\" />\r\n        </label>\r\n        <label>\r\n            <span>Inactive Date:</span>\r\n            <input id=\"notification_inactive_date\" class=\"date\" type=\"text\" value=\""
    + alias2((helpers.CHSIDate || (depth0 && depth0.CHSIDate) || alias3).call(depth0,((stack1 = (depth0 != null ? depth0.Notification : depth0)) != null ? stack1.InActiveDate : stack1),{"name":"CHSIDate","hash":{},"data":data}))
    + "\" />\r\n        </label>\r\n        <label>\r\n            <span>Include Report?:</span>\r\n            <input id=\"notification_include_report\" type=\"checkbox\" "
    + ((stack1 = (helpers.MarkAsChecked || (depth0 && depth0.MarkAsChecked) || alias3).call(depth0,((stack1 = (depth0 != null ? depth0.Notification : depth0)) != null ? stack1.IncludeReport : stack1),{"name":"MarkAsChecked","hash":{},"data":data})) != null ? stack1 : "")
    + " />\r\n        </label>\r\n    </div>    \r\n    <div>\r\n        <label>\r\n            <span>Notification Contact Type:</span>\r\n            <select id=\"notification_contact_type\"></select>\r\n        </label>    \r\n        <label>\r\n            <span>Notification Contact Type CC:</span>\r\n            <select id=\"notification_contact_type_cc\"></select>\r\n        </label>\r\n    </div>\r\n    <div>\r\n        <label>\r\n            <span>Notification CC:</span>\r\n            <input id=\"notification_cc\" max=\"500\" type=\"text\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.Notification : depth0)) != null ? stack1.NotificationCC : stack1), depth0))
    + "\" />\r\n        </label>\r\n    </div>\r\n    <div>\r\n        <label>\r\n            <span>Notification BCC:</span>\r\n            <input id=\"notification_bcc\" max=\"500\" type=\"text\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.Notification : depth0)) != null ? stack1.NotificationBCC : stack1), depth0))
    + "\" />\r\n        </label>\r\n    </div>    \r\n    <div>\r\n        <label>\r\n            <span>Subject:</span>\r\n            <input id=\"notification_subject\" class=\"required_validation\" data-errormessage=\"Please add a subject to this notification.\" type=\"text\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.Notification : depth0)) != null ? stack1.Subject : stack1), depth0))
    + "\" />\r\n        </label>\r\n    </div>\r\n    <div id=\"editor_container\">\r\n        <textarea id=\"html_editor\" data-errormessage=\"Please add body content to this notification.\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.Notification : depth0)) != null ? stack1.Body : stack1), depth0))
    + "</textarea>\r\n    </div>\r\n    <div id=\"bottom_button_container\">\r\n        <button id=\"notification_save\" type=\"button\">Save</button>\r\n        <div id=\"notification_message\"></div>\r\n        <button id=\"notification_cancel\" type=\"button\">Close</button>\r\n    </div>\r\n</div>";
},"useData":true});