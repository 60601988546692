this["CHSITemplates"]["CRM"] = this["CHSITemplates"]["CRM"] || {};
this["CHSITemplates"]["CRM"]["NewExcessPolicy"] = Handlebars.template({"1":function(depth0,helpers,partials,data) {
    return " disabled ";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1, helper, alias1=helpers.helperMissing, alias2="function", alias3=this.escapeExpression;

  return "<div class=\"popup-form\">\r\n    <div class=\"group-container col-1\">\r\n        <div class=\"input-container col-1\">\r\n            <label>Company</label>\r\n            <input maxlength=\"50\" type=\"text\" id=\"ReInsuranceCo\" class=\"popup-form-input\" value=\""
    + alias3(((helper = (helper = helpers.ReInsuranceCo || (depth0 != null ? depth0.ReInsuranceCo : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"ReInsuranceCo","hash":{},"data":data}) : helper)))
    + "\" />\r\n        </div>\r\n        <div class=\"input-container col-2\">\r\n            <label>Excess Provider</label>\r\n            <input maxlength=\"1\" type=\"text\" id=\"ExcessProvider\" class=\"popup-form-input\" value=\""
    + alias3(((helper = (helper = helpers.ExcessProvider || (depth0 != null ? depth0.ExcessProvider : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"ExcessProvider","hash":{},"data":data}) : helper)))
    + "\" readonly/>\r\n        </div>\r\n        <div class=\"input-container col-2\">\r\n            <label>Policy Number</label>\r\n            <input maxlength=\"30\" type=\"text\" id=\"PolicyNumber\" class=\"popup-form-input\" value=\""
    + alias3(((helper = (helper = helpers.PolicyNumber || (depth0 != null ? depth0.PolicyNumber : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"PolicyNumber","hash":{},"data":data}) : helper)))
    + "\" />\r\n        </div>\r\n        <div class=\"input-container col-2\">\r\n            <label>Effective Date</label>\r\n            <input id=\"EffectiveDate\" class=\"popup-form-input date\" value=\""
    + alias3(((helper = (helper = helpers.EffectiveDate || (depth0 != null ? depth0.EffectiveDate : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"EffectiveDate","hash":{},"data":data}) : helper)))
    + "\" />\r\n        </div>\r\n        <div class=\"input-container col-2\">\r\n            <label>Expiration Date</label>\r\n            <input id=\"ExpirationDate\" class=\"popup-form-input date\" value=\""
    + alias3(((helper = (helper = helpers.ExpirationDate || (depth0 != null ? depth0.ExpirationDate : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"ExpirationDate","hash":{},"data":data}) : helper)))
    + "\" />\r\n        </div>\r\n        <div class=\"input-container col-3\">\r\n            <label>SIR</label>\r\n            <input type=\"number\" id=\"SIR\" class=\"popup-form-input\" value=\""
    + alias3(((helper = (helper = helpers.SIR || (depth0 != null ? depth0.SIR : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"SIR","hash":{},"data":data}) : helper)))
    + "\" />\r\n        </div>\r\n        <div class=\"input-container col-3\">\r\n            <label>Max Liability</label>\r\n            <input type=\"number\" id=\"MaxLiability\" class=\"popup-form-input\" value=\""
    + alias3(((helper = (helper = helpers.MaxLiability || (depth0 != null ? depth0.MaxLiability : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"MaxLiability","hash":{},"data":data}) : helper)))
    + "\" />\r\n        </div>\r\n        <div class=\"input-container col-3\">\r\n            <label>Ceded Pct</label>\r\n            <input type=\"number\" id=\"CededPct\" class=\"popup-form-input\" value=\""
    + alias3(((helper = (helper = helpers.CededPct || (depth0 != null ? depth0.CededPct : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"CededPct","hash":{},"data":data}) : helper)))
    + "\" />\r\n        </div>\r\n        <div class=\"input-container col-1\">\r\n            <label>Premium Source</label>\r\n            <input maxlength=\"20\" type=\"text\" id=\"PremiumSource\" class=\"popup-form-input\" value=\""
    + alias3(((helper = (helper = helpers.PremiumSource || (depth0 != null ? depth0.PremiumSource : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"PremiumSource","hash":{},"data":data}) : helper)))
    + "\" />\r\n        </div>\r\n    </div>\r\n</div>\r\n<div class=\"btn-bar\">\r\n    <!--<input class=\"Assignee-delete\" value=\"Delete\" type=\"button\" style=\"float:left;\" "
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.isNew : depth0),{"name":"if","hash":{},"fn":this.program(1, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + " />-->\r\n    <input class=\"policy-cancel\" value=\"Cancel\" type=\"button\" style=\"float:right;\" />\r\n    <input class=\"policy-save\" value=\"Save\" type=\"button\" style=\"float:right;margin-right:20px;\" />\r\n</div> ";
},"useData":true});