this["CHSITemplates"]["Template_AnswerGrid"] = Handlebars.template({"1":function(depth0,helpers,partials,data) {
    var stack1, helper, alias1=helpers.helperMissing, alias2="function", alias3=this.escapeExpression;

  return "        <tr>\r\n            <td>"
    + alias3(((helper = (helper = helpers.CategoryTitle || (depth0 != null ? depth0.CategoryTitle : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"CategoryTitle","hash":{},"data":data}) : helper)))
    + "</td>\r\n            <td>"
    + alias3(((helper = (helper = helpers.SubCategoryTitle || (depth0 != null ? depth0.SubCategoryTitle : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"SubCategoryTitle","hash":{},"data":data}) : helper)))
    + "</td>\r\n            <td>"
    + alias3(((helper = (helper = helpers.FieldName || (depth0 != null ? depth0.FieldName : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"FieldName","hash":{},"data":data}) : helper)))
    + "</td>\r\n            <td>"
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.PrevAllowNA : depth0),{"name":"if","hash":{},"fn":this.program(2, data, 0),"inverse":this.program(4, data, 0),"data":data})) != null ? stack1 : "")
    + "</td>\r\n            <td>"
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.AllowNA : depth0),{"name":"if","hash":{},"fn":this.program(2, data, 0),"inverse":this.program(6, data, 0),"data":data})) != null ? stack1 : "")
    + "</td>\r\n            <td style=\"text-align:left; padding-left: 30px;\">"
    + alias3((helpers.Variation || (depth0 && depth0.Variation) || alias1).call(depth0,(depth0 != null ? depth0.Value : depth0),(depth0 != null ? depth0.PriorValue : depth0),{"name":"Variation","hash":{},"data":data}))
    + "</td>\r\n            <td>"
    + alias3(((helper = (helper = helpers.AnswerStatus || (depth0 != null ? depth0.AnswerStatus : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"AnswerStatus","hash":{},"data":data}) : helper)))
    + "</td>\r\n            <td>"
    + alias3((helpers.DisplayReviewStatus || (depth0 && depth0.DisplayReviewStatus) || alias1).call(depth0,(depth0 != null ? depth0.ReviewStatus : depth0),{"name":"DisplayReviewStatus","hash":{},"data":data}))
    + "</td>\r\n            <td>"
    + alias3((helpers.CHSIDate || (depth0 && depth0.CHSIDate) || alias1).call(depth0,(depth0 != null ? depth0.CreateTmsp : depth0),{"name":"CHSIDate","hash":{},"data":data}))
    + "</td>\r\n            <td>"
    + alias3(((helper = (helper = helpers.CreateUser || (depth0 != null ? depth0.CreateUser : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"CreateUser","hash":{},"data":data}) : helper)))
    + "</td>\r\n            <td>[<a href=\"javascript:ShowReviewAnswer("
    + alias3(((helper = (helper = helpers.ElementID || (depth0 != null ? depth0.ElementID : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"ElementID","hash":{},"data":data}) : helper)))
    + ","
    + alias3(((helper = (helper = helpers.ColumnID || (depth0 != null ? depth0.ColumnID : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"ColumnID","hash":{},"data":data}) : helper)))
    + ","
    + alias3(((helper = (helper = helpers.ValueIndex || (depth0 != null ? depth0.ValueIndex : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"ValueIndex","hash":{},"data":data}) : helper)))
    + ","
    + alias3(((helper = (helper = helpers.FormID || (depth0 != null ? depth0.FormID : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"FormID","hash":{},"data":data}) : helper)))
    + ","
    + alias3(((helper = (helper = helpers.ResultSetID || (depth0 != null ? depth0.ResultSetID : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"ResultSetID","hash":{},"data":data}) : helper)))
    + ")\" class=\"mini\">Edit</a>]</td>\r\n            <td>"
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.flagAR : depth0),{"name":"if","hash":{},"fn":this.program(8, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "</td>\r\n        </tr>\r\n";
},"2":function(depth0,helpers,partials,data) {
    return "N/A";
},"4":function(depth0,helpers,partials,data) {
    var helper;

  return "<div class=\"ApplyEllipses\">"
    + this.escapeExpression(((helper = (helper = helpers.PriorValue || (depth0 != null ? depth0.PriorValue : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"PriorValue","hash":{},"data":data}) : helper)))
    + "</div>";
},"6":function(depth0,helpers,partials,data) {
    var helper;

  return "<div class=\"ApplyEllipses\">"
    + this.escapeExpression(((helper = (helper = helpers.Value || (depth0 != null ? depth0.Value : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"Value","hash":{},"data":data}) : helper)))
    + "</div>";
},"8":function(depth0,helpers,partials,data) {
    var helper, alias1=helpers.helperMissing, alias2="function", alias3=this.escapeExpression;

  return "<input type=\"button\" id=\"jxAutoReview_"
    + alias3(((helper = (helper = helpers.index || (data && data.index)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"index","hash":{},"data":data}) : helper)))
    + "\" class=\"SingleAutoReview\" data-elementid=\""
    + alias3(((helper = (helper = helpers.ElementID || (depth0 != null ? depth0.ElementID : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"ElementID","hash":{},"data":data}) : helper)))
    + "\" data-columnid=\""
    + alias3(((helper = (helper = helpers.ColumnID || (depth0 != null ? depth0.ColumnID : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"ColumnID","hash":{},"data":data}) : helper)))
    + "\" data-valueindex=\""
    + alias3(((helper = (helper = helpers.ValueIndex || (depth0 != null ? depth0.ValueIndex : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"ValueIndex","hash":{},"data":data}) : helper)))
    + "\" data-formid=\""
    + alias3(((helper = (helper = helpers.FormID || (depth0 != null ? depth0.FormID : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"FormID","hash":{},"data":data}) : helper)))
    + "\" data-resultsetid=\""
    + alias3(((helper = (helper = helpers.ResultSetID || (depth0 != null ? depth0.ResultSetID : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"ResultSetID","hash":{},"data":data}) : helper)))
    + "\" value=\"Complete\">";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1;

  return "<table width=\"100%\" class=\"dtable sortable\">\r\n    <thead>\r\n        <tr>\r\n            <td>Category</td>\r\n            <td>SubCat</td>\r\n            <td>Field Name</td>\r\n            <td>Prior Value</td>\r\n            <td>Value</td>\r\n            <td>Variation (%)</td>\r\n            <td>Status</td>\r\n            <td>Reviewed</td>\r\n            <td>Last&nbsp;Changed</td>\r\n            <td>User</td>\r\n            <td class=\"sorttable_nosort\">&nbsp;</td>\r\n            <td class=\"sorttable_nosort\">&nbsp;</td>\r\n        </tr>\r\n    </thead>\r\n    <tbody>\r\n"
    + ((stack1 = helpers.each.call(depth0,(depth0 != null ? depth0.Answers : depth0),{"name":"each","hash":{},"fn":this.program(1, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "    </tbody>\r\n</table>";
},"useData":true});