this["CHSITemplates"]["Template_QuoteInfo_CoverageLine"] = Handlebars.template({"1":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["with"].call(depth0,(depth0 != null ? depth0.value : depth0),{"name":"with","hash":{},"fn":this.program(2, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "");
},"2":function(depth0,helpers,partials,data) {
    var helper, alias1=helpers.helperMissing, alias2="function", alias3=this.escapeExpression;

  return "        <tr>\r\n            <td>\r\n                <b>"
    + alias3(((helper = (helper = helpers.CoverageLineName || (depth0 != null ? depth0.CoverageLineName : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"CoverageLineName","hash":{},"data":data}) : helper)))
    + "</b>\r\n            </td>\r\n            <td>\r\n                <input type=\"button\" class=\"jxDeleteCL\" data-clid=\""
    + alias3(((helper = (helper = helpers.CoverageLineID || (depth0 != null ? depth0.CoverageLineID : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"CoverageLineID","hash":{},"data":data}) : helper)))
    + "\" value=\" Remove Coverage Line \" />\r\n            </td>\r\n        </tr>\r\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1, helper, alias1=helpers.helperMissing;

  return "<table width=\"100%\" class=\"dtable sortable\">\r\n    <thead>\r\n        <tr>\r\n            <td style=\"width: 95%;\">\r\n                Coverage Line\r\n            </td>\r\n            <td>\r\n                &nbsp;\r\n            </td>\r\n        </tr>\r\n    </thead>\r\n    <tbody>\r\n"
    + ((stack1 = (helpers.eachProperty || (depth0 && depth0.eachProperty) || alias1).call(depth0,((stack1 = (depth0 != null ? depth0.CoverageLineObject : depth0)) != null ? stack1.CL : stack1),{"name":"eachProperty","hash":{},"fn":this.program(1, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "    </tbody>\r\n</table>\r\n<br />\r\n<br />\r\n<b>Add a Coverage Line</b> "
    + this.escapeExpression(((helper = (helper = helpers.GetAddCoverageLineSelectList || (depth0 != null ? depth0.GetAddCoverageLineSelectList : depth0)) != null ? helper : alias1),(typeof helper === "function" ? helper.call(depth0,{"name":"GetAddCoverageLineSelectList","hash":{},"data":data}) : helper)));
},"useData":true});