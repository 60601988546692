this["CHSITemplates"]["Template_QuotePremium_Premium"] = Handlebars.template({"1":function(depth0,helpers,partials,data) {
    var stack1;

  return "\r\n"
    + ((stack1 = helpers["with"].call(depth0,(depth0 != null ? depth0.value : depth0),{"name":"with","hash":{},"fn":this.program(2, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "    </div>\r\n\r\n";
},"2":function(depth0,helpers,partials,data) {
    var stack1, helper, alias1=helpers.helperMissing, alias2="function", alias3=this.escapeExpression;

  return "    <h3 style=\"clear:both;\">"
    + alias3(((helper = (helper = helpers.CoverageLineName || (depth0 != null ? depth0.CoverageLineName : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"CoverageLineName","hash":{},"data":data}) : helper)))
    + "</h3>\r\n    <a href=\"#\" id=\"jxplusbutton_"
    + alias3(((helper = (helper = helpers.CoverageLineID || (depth0 != null ? depth0.CoverageLineID : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"CoverageLineID","hash":{},"data":data}) : helper)))
    + "\" data-cl=\""
    + alias3(((helper = (helper = helpers.CoverageLineID || (depth0 != null ? depth0.CoverageLineID : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"CoverageLineID","hash":{},"data":data}) : helper)))
    + "\" class=\"plusbutton\"><img src=\"/images/images/plus.gif\" id=\"jxplusimg_"
    + alias3(((helper = (helper = helpers.CoverageLineID || (depth0 != null ? depth0.CoverageLineID : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"CoverageLineID","hash":{},"data":data}) : helper)))
    + "\" data-status=\"+\" style=\"float:left;\"></a>\r\n    <div class=\"blockIndent\" id=\"jxCL_"
    + alias3(((helper = (helper = helpers.CoverageLineID || (depth0 != null ? depth0.CoverageLineID : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"CoverageLineID","hash":{},"data":data}) : helper)))
    + "\">\r\n"
    + ((stack1 = helpers.each.call(depth0,(depth0 != null ? depth0.Rates : depth0),{"name":"each","hash":{},"fn":this.program(3, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "        <table  class=\"VerticalTable\">\r\n"
    + ((stack1 = (helpers.firstProperty || (depth0 && depth0.firstProperty) || alias1).call(depth0,(depth0 != null ? depth0.Vars : depth0),{"name":"firstProperty","hash":{},"fn":this.program(12, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = (helpers.eachProperty || (depth0 && depth0.eachProperty) || alias1).call(depth0,(depth0 != null ? depth0.Vars : depth0),{"name":"eachProperty","hash":{},"fn":this.program(14, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "        </table>\r\n\r\n";
},"3":function(depth0,helpers,partials,data) {
    var stack1, helper, alias1=helpers.helperMissing;

  return "        <div style=\"margin-top:5px; clear:both;\" class=\"Collapsable\">Rate: "
    + this.escapeExpression(((helper = (helper = helpers.RateName || (depth0 != null ? depth0.RateName : depth0)) != null ? helper : alias1),(typeof helper === "function" ? helper.call(depth0,{"name":"RateName","hash":{},"data":data}) : helper)))
    + "</div>\r\n        <table cellspacing=\"2\" cellpadding=\"0\" class=\"dtable sortable\" style=\"width:99%\">\r\n            <thead>\r\n                <tr>\r\n"
    + ((stack1 = (helpers.firstProperty || (depth0 && depth0.firstProperty) || alias1).call(depth0,(depth0 != null ? depth0.RateTypes : depth0),{"name":"firstProperty","hash":{},"fn":this.program(4, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "                </tr>\r\n            </thead>\r\n            <tbody>\r\n"
    + ((stack1 = (helpers.eachProperty || (depth0 && depth0.eachProperty) || alias1).call(depth0,(depth0 != null ? depth0.RateTypes : depth0),{"name":"eachProperty","hash":{},"fn":this.program(8, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "            </tbody>\r\n        </table>\r\n";
},"4":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["with"].call(depth0,(depth0 != null ? depth0.value : depth0),{"name":"with","hash":{},"fn":this.program(5, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "");
},"5":function(depth0,helpers,partials,data) {
    var stack1;

  return "                    <td class=\"Collapsable\" style=\"width:150px;\">Rate Detail</td>\r\n"
    + ((stack1 = (helpers.eachProperty || (depth0 && depth0.eachProperty) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.Vars : depth0),{"name":"eachProperty","hash":{},"fn":this.program(6, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\r\n";
},"6":function(depth0,helpers,partials,data) {
    var stack1;

  return "                    "
    + this.escapeExpression((helpers.GetAppearanceTitle || (depth0 && depth0.GetAppearanceTitle) || helpers.helperMissing).call(depth0,"RateVariables",((stack1 = (depth0 != null ? depth0.value : depth0)) != null ? stack1.ProgramRateVariableID : stack1),1,{"name":"GetAppearanceTitle","hash":{},"data":data}))
    + "\r\n\r\n";
},"8":function(depth0,helpers,partials,data) {
    var stack1;

  return "                <tr>\r\n"
    + ((stack1 = helpers["with"].call(depth0,(depth0 != null ? depth0.value : depth0),{"name":"with","hash":{},"fn":this.program(9, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "                </tr>\r\n";
},"9":function(depth0,helpers,partials,data) {
    var stack1, helper, alias1=helpers.helperMissing;

  return "                    <td class=\"Collapsable\">"
    + this.escapeExpression(((helper = (helper = helpers.Title || (depth0 != null ? depth0.Title : depth0)) != null ? helper : alias1),(typeof helper === "function" ? helper.call(depth0,{"name":"Title","hash":{},"data":data}) : helper)))
    + "</td>\r\n"
    + ((stack1 = (helpers.eachProperty || (depth0 && depth0.eachProperty) || alias1).call(depth0,(depth0 != null ? depth0.Vars : depth0),{"name":"eachProperty","hash":{},"fn":this.program(10, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "");
},"10":function(depth0,helpers,partials,data) {
    var stack1;

  return "                    "
    + this.escapeExpression((helpers.GetAppearanceControl || (depth0 && depth0.GetAppearanceControl) || helpers.helperMissing).call(depth0,"RateVariables",((stack1 = (depth0 != null ? depth0.value : depth0)) != null ? stack1.ProgramRateVariableID : stack1),(depth0 != null ? depth0.value : depth0),1,{"name":"GetAppearanceControl","hash":{},"data":data}))
    + "\r\n";
},"12":function(depth0,helpers,partials,data) {
    var stack1;

  return "                "
    + this.escapeExpression((helpers.GetVariableSummaryTitles || (depth0 && depth0.GetVariableSummaryTitles) || helpers.helperMissing).call(depth0,"Variables",((stack1 = (depth0 != null ? depth0.value : depth0)) != null ? stack1.VariableID : stack1),(depth0 != null ? depth0.value : depth0),1,{"name":"GetVariableSummaryTitles","hash":{},"data":data}))
    + "           \r\n";
},"14":function(depth0,helpers,partials,data) {
    var stack1;

  return "                <tr>                \r\n                    "
    + this.escapeExpression((helpers.GetVariableSummary || (depth0 && depth0.GetVariableSummary) || helpers.helperMissing).call(depth0,"Variables",((stack1 = (depth0 != null ? depth0.value : depth0)) != null ? stack1.VariableID : stack1),(depth0 != null ? depth0.value : depth0),1,{"name":"GetVariableSummary","hash":{},"data":data}))
    + "\r\n                </tr>\r\n";
},"16":function(depth0,helpers,partials,data) {
    var stack1;

  return "            "
    + this.escapeExpression((helpers.GetVariableSummaryTitles || (depth0 && depth0.GetVariableSummaryTitles) || helpers.helperMissing).call(depth0,"Variables",((stack1 = (depth0 != null ? depth0.value : depth0)) != null ? stack1.VariableID : stack1),(depth0 != null ? depth0.value : depth0),1,{"name":"GetVariableSummaryTitles","hash":{},"data":data}))
    + "\r\n";
},"18":function(depth0,helpers,partials,data) {
    var stack1;

  return "        <tr>           \r\n            "
    + this.escapeExpression((helpers.GetVariableSummary || (depth0 && depth0.GetVariableSummary) || helpers.helperMissing).call(depth0,"Variables",((stack1 = (depth0 != null ? depth0.value : depth0)) != null ? stack1.VariableID : stack1),(depth0 != null ? depth0.value : depth0),1,{"name":"GetVariableSummary","hash":{},"data":data}))
    + "\r\n        </tr>\r\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1, alias1=helpers.helperMissing;

  return "<h2><label class=\"QuoteLabel\">Quote</label></h2>\r\n<div class=\"blockIndent\">\r\n"
    + ((stack1 = (helpers.eachProperty || (depth0 && depth0.eachProperty) || alias1).call(depth0,(depth0 != null ? depth0.CL : depth0),{"name":"eachProperty","hash":{},"fn":this.program(1, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "    <h3 style=\"clear:both; border-bottom: solid 1px gray;\">Summary</h3>\r\n    <table  class=\"VerticalTable\">\r\n"
    + ((stack1 = (helpers.firstProperty || (depth0 && depth0.firstProperty) || alias1).call(depth0,(depth0 != null ? depth0.Vars : depth0),{"name":"firstProperty","hash":{},"fn":this.program(16, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = (helpers.eachProperty || (depth0 && depth0.eachProperty) || alias1).call(depth0,(depth0 != null ? depth0.Vars : depth0),{"name":"eachProperty","hash":{},"fn":this.program(18, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "    </table>\r\n</div>";
},"useData":true});