this["CHSITemplates"]["Template_QuotePremium_BillingSchedule"] = Handlebars.template({"1":function(depth0,helpers,partials,data) {
    var alias1=this.escapeExpression;

  return "            <tr>\r\n                <td width=\"1%\" nowrap=\"\">\r\n	                "
    + alias1((helpers.CHSIDate || (depth0 && depth0.CHSIDate) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.BillDate : depth0),{"name":"CHSIDate","hash":{},"data":data}))
    + "\r\n                </td>\r\n                <td width=\"99%\" align=\"right\">\r\n	                "
    + alias1(this.lambda((depth0 != null ? depth0.BillAmount : depth0), depth0))
    + "\r\n	                &nbsp;&nbsp;\r\n</td>\r\n</tr>\r\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1;

  return "<table class=\"dtable sortable\" cellpadding=\"0\">\r\n        <thead><tr></tr></thead>\r\n        <tbody>	          \r\n"
    + ((stack1 = helpers.each.call(depth0,(depth0 != null ? depth0.BillingSchedule : depth0),{"name":"each","hash":{},"fn":this.program(1, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "</tbody>\r\n</table>		";
},"useData":true});