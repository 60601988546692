this["CHSITemplates"]["ScheduleGridView"] = Handlebars.template({"1":function(depth0,helpers,partials,data) {
    return " checked=\"checked\" ";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1, helper, alias1=helpers.helperMissing, alias2="function", alias3=this.escapeExpression;

  return "<style>\r\n	/*.headerFilter {\r\n		width: 90px;\r\n	}*/\r\n	/*.sorting {\r\n		width: 90px !important;\r\n	}*/\r\n</style> \r\n<table width=\"100%\">\r\n    <tr>\r\n        <td>\r\n            <a id=\"jxBack\" href='#GroupName="
    + alias3(((helper = (helper = helpers.GroupID || (depth0 != null ? depth0.GroupID : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"GroupID","hash":{},"data":data}) : helper)))
    + "'><img src=\"/images/icons/leftarrow.png\" style=\"display: inline\" /></a>  <span id=\"jxPageHeader\" class=\"CategoryTitle\">"
    + alias3(((helper = (helper = helpers.ScheduleCategory || (depth0 != null ? depth0.ScheduleCategory : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"ScheduleCategory","hash":{},"data":data}) : helper)))
    + "</span>\r\n        </td>\r\n        <td align=\"right\">\r\n            <input type=\"radio\" id=\"jxFilterActive\" name=\"StatusFilter\" class=\"Filter\" value=\"3\" "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias1).call(depth0,(depth0 != null ? depth0.statustype : depth0),"3",{"name":"compare","hash":{},"fn":this.program(1, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + " />\r\n            Active\r\n            <input type=\"radio\" id=\"jxFilterPending\" name=\"StatusFilter\"  class=\"Filter\" value=\"2\" "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias1).call(depth0,(depth0 != null ? depth0.statustype : depth0),"2",{"name":"compare","hash":{},"fn":this.program(1, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + " />\r\n            Pending Only\r\n            <input type=\"radio\" id=\"jxFilterInactive\" name=\"StatusFilter\" class=\"Filter\" value=\"4\" "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias1).call(depth0,(depth0 != null ? depth0.statustype : depth0),"4",{"name":"compare","hash":{},"fn":this.program(1, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + " />\r\n            Inactive\r\n            <input type=\"radio\" id=\"jxFilterAll\" name=\"StatusFilter\"  class=\"Filter\" value=\"7\" "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias1).call(depth0,(depth0 != null ? depth0.statustype : depth0),"7",{"name":"compare","hash":{},"fn":this.program(1, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + " />\r\n            All\r\n            \r\n        </td>\r\n    </tr>\r\n</table>\r\n    <table width=\"100%\" class=\"dtable\" id=\"jxScheduleGrid\">\r\n        <tfoot></tfoot>\r\n    </table>\r\n ";
},"useData":true});