this["CHSITemplates"]["ClaimIntakeForm_Start"] = Handlebars.template({"1":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=this.lambda, alias2=this.escapeExpression, alias3=helpers.helperMissing;

  return "<table>\r\n    <tr>\r\n        <td colspan=\"2\">\r\n            <div class=\"dynamicCategoryTitle\"><h3>START</h3></div>\r\n        </td>\r\n    </tr>\r\n    <tr> \r\n        <td>\r\n            &nbsp;\r\n        </td>\r\n    </tr>\r\n    <tr>\r\n        <td colspan=\"5\">            \r\n            <div id=\"jxClaimHeaderInformation\" style=\"width: 700px;\">\r\n                "
    + ((stack1 = alias1(((stack1 = (depth0 != null ? depth0.ClaimObject : depth0)) != null ? stack1.Instructions : stack1), depth0)) != null ? stack1 : "")
    + "    \r\n            </div>\r\n        </td>        \r\n    </tr>\r\n    <tr>\r\n        <td>\r\n            &nbsp;\r\n        </td>\r\n    </tr>\r\n    <tr>\r\n        <td style=\"width: 30%\">\r\n            <b>Company Name</b>\r\n        </td>\r\n        <td>\r\n            "
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.ClaimObject : depth0)) != null ? stack1.ClientInformation : stack1)) != null ? stack1.ClientName : stack1), depth0))
    + "\r\n        </td>\r\n    </tr>\r\n    <tr>\r\n        <td style=\"width: 30%\">\r\n            <b>Mailing Address</b>\r\n        </td>\r\n        <td>            \r\n"
    + ((stack1 = helpers.each.call(depth0,((stack1 = (depth0 != null ? depth0.ClaimObject : depth0)) != null ? stack1.ClientAddress : stack1),{"name":"each","hash":{},"fn":this.program(2, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "        </td>\r\n    </tr>\r\n    <tr>\r\n        <td style=\"width: 30%\">\r\n            <b>Phone Number</b>\r\n        </td>\r\n        <td>\r\n            "
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.ClaimObject : depth0)) != null ? stack1.ClientInformation : stack1)) != null ? stack1.Phone1 : stack1), depth0))
    + "\r\n        </td>\r\n    </tr>\r\n    <tr>\r\n        <td style=\"width: 30%\">\r\n            <b>Member ID</b>\r\n        </td>\r\n        <td>\r\n            "
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.ClaimObject : depth0)) != null ? stack1.ClientInformation : stack1)) != null ? stack1.ClientNumber : stack1), depth0))
    + "\r\n        </td>\r\n    </tr>\r\n    <tr>\r\n        <td style=\"width: 30%\">\r\n            <b>Employer ID No.</b>\r\n        </td>\r\n        <td>\r\n            "
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.ClaimObject : depth0)) != null ? stack1.AdditionalClientInformation : stack1)) != null ? stack1.DetailValue : stack1), depth0))
    + "\r\n        </td>\r\n    </tr>\r\n    <tr>\r\n        <td style=\"width: 30%\">\r\n            <b>Nature of Business</b>\r\n        </td>\r\n        <td>\r\n            "
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.ClaimObject : depth0)) != null ? stack1.ClientInformation : stack1)) != null ? stack1.Industry : stack1), depth0))
    + "\r\n        </td>\r\n    </tr>\r\n    <tr>\r\n        <td style=\"width: 30%\">\r\n            <b>Location</b>\r\n        </td>\r\n        <td>\r\n            <select id=\"jxLocation\" "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias3).call(depth0,(depth0 != null ? depth0.Status : depth0),"Submitted",{"name":"compare","hash":{"operator":"=="},"fn":this.program(6, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ">\r\n                <option value=\"\"></option>\r\n"
    + ((stack1 = helpers.each.call(depth0,((stack1 = (depth0 != null ? depth0.ClaimObject : depth0)) != null ? stack1.Locations : stack1),{"name":"each","hash":{},"fn":this.program(8, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "            </select>\r\n        </td>\r\n    </tr>\r\n    <tr>\r\n        <td style=\"width: 30%\">\r\n            <b>Date of Incident</b>\r\n        </td>\r\n        <td>\r\n            <input id=\"jxIncidentDate\" type=\"text\" "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias3).call(depth0,(depth0 != null ? depth0.Status : depth0),"New",{"name":"compare","hash":{"operator":"=="},"fn":this.program(13, data, 0, blockParams, depths),"inverse":this.program(15, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + " "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias3).call(depth0,(depth0 != null ? depth0.Status : depth0),"Submitted",{"name":"compare","hash":{"operator":"=="},"fn":this.program(6, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + " />            \r\n        </td>\r\n    </tr>\r\n</table>\r\n";
},"2":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.compare || (depth0 && depth0.compare) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.AddressType : depth0),"mailing",{"name":"compare","hash":{"operator":"=="},"fn":this.program(3, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "");
},"3":function(depth0,helpers,partials,data) {
    var stack1, alias1=this.lambda, alias2=this.escapeExpression;

  return "                    "
    + alias2(alias1((depth0 != null ? depth0.Address1 : depth0), depth0))
    + " "
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.Address2 : depth0),{"name":"if","hash":{},"fn":this.program(4, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + " "
    + alias2(alias1((depth0 != null ? depth0.City : depth0), depth0))
    + ", "
    + alias2(alias1((depth0 != null ? depth0.State : depth0), depth0))
    + " "
    + alias2(alias1((depth0 != null ? depth0.Zip : depth0), depth0))
    + "\r\n";
},"4":function(depth0,helpers,partials,data) {
    return this.escapeExpression(this.lambda((depth0 != null ? depth0.Address2 : depth0), depth0));
},"6":function(depth0,helpers,partials,data) {
    return " readonly=\"readonly\" disabled=\"disabled\" ";
},"8":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=helpers.helperMissing, alias2="function", alias3=this.escapeExpression;

  return "                <option value=\""
    + alias3(((helper = (helper = helpers.LocationID || (depth0 != null ? depth0.LocationID : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"LocationID","hash":{},"data":data}) : helper)))
    + "\" "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias1).call(depth0,((stack1 = ((stack1 = (depths[1] != null ? depths[1].ClaimObject : depths[1])) != null ? stack1.IncidentReport : stack1)) != null ? stack1.LocationID : stack1),(depth0 != null ? depth0.LocationID : depth0),{"name":"compare","hash":{"operator":"=="},"fn":this.program(9, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ">"
    + alias3(((helper = (helper = helpers.DBA || (depth0 != null ? depth0.DBA : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"DBA","hash":{},"data":data}) : helper)))
    + ", "
    + alias3(((helper = (helper = helpers.Address1 || (depth0 != null ? depth0.Address1 : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"Address1","hash":{},"data":data}) : helper)))
    + " "
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.Address2 : depth0),{"name":"if","hash":{},"fn":this.program(11, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "</option>\r\n";
},"9":function(depth0,helpers,partials,data) {
    return " selected=\"selected\" ";
},"11":function(depth0,helpers,partials,data) {
    var helper;

  return " "
    + this.escapeExpression(((helper = (helper = helpers.Address2 || (depth0 != null ? depth0.Address2 : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"Address2","hash":{},"data":data}) : helper)));
},"13":function(depth0,helpers,partials,data) {
    return " value=\"\" ";
},"15":function(depth0,helpers,partials,data) {
    var stack1;

  return " value=\""
    + this.escapeExpression((helpers.CHSIDate || (depth0 && depth0.CHSIDate) || helpers.helperMissing).call(depth0,((stack1 = ((stack1 = (depth0 != null ? depth0.ClaimObject : depth0)) != null ? stack1.IncidentReport : stack1)) != null ? stack1.IncidentDate : stack1),{"name":"CHSIDate","hash":{},"data":data}))
    + "\" ";
},"17":function(depth0,helpers,partials,data) {
    return "<div class=\"submittedContainer\">\r\n    Form has been Submitted\r\n</div>\r\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return "<style>\r\n    .submittedContainer {\r\n        border: 1px solid black;\r\n        border-radius: 2px;\r\n        background-color: #B6FCB6;\r\n        width: 130px;\r\n        height: 40px;\r\n        padding: 15px;\r\n        margin: 0 auto;\r\n        margin-top: 10px;\r\n        font-size: large;\r\n        text-align: center;\r\n    }\r\n        .dynamicCategoryTitle{\r\n        margin: 0 auto;\r\n        text-align: center;\r\n    }\r\n</style>\r\n"
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.ClaimObject : depth0),{"name":"if","hash":{},"fn":this.program(1, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.Status : depth0),"Submitted",{"name":"compare","hash":{"operator":"=="},"fn":this.program(17, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "");
},"useData":true,"useDepths":true});