this["CHSITemplates"]["Template_ClaimSearch_PortalResults"] = Handlebars.template({"1":function(depth0,helpers,partials,data) {
    return "    <div style=\"color: Red; font-size: 16px;\">\r\n        More than 100 records found, please refine your search.\r\n    </div> \r\n";
},"3":function(depth0,helpers,partials,data) {
    var stack1;

  return "            <table class=\"dtable sortable\"><thead><tr><td style=\"width: 100px\">Coverage</td><td style=\"width: 100px\">Claim Number</td><td style=\"width: 175px\">Claimant</td><td style=\"width: 90px\">Date Opened</td><td style=\"width: 150px\">Claim Type</td><td style=\"width: 90px\">Claim Status</td><td style=\"width: 90px\">Incident Date</td><td style=\"width: 90px\">Date Closed</td><td>&nbsp;</td></tr></thead><tbody>"
    + ((stack1 = helpers.each.call(depth0,(depth0 != null ? depth0.ClaimTableRecord : depth0),{"name":"each","hash":{},"fn":this.program(4, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "</tbody></table>\r\n";
},"4":function(depth0,helpers,partials,data) {
    var alias1=this.lambda, alias2=this.escapeExpression, alias3=helpers.helperMissing;

  return "<tr><td style=\"width: 80px\">"
    + alias2(alias1((depth0 != null ? depth0.Coverage : depth0), depth0))
    + "</td><td style=\"width: 90px\">"
    + alias2(alias1((depth0 != null ? depth0.ClaimNumber : depth0), depth0))
    + "</td><td style=\"width: 130px\">"
    + alias2(alias1((depth0 != null ? depth0.Claimant : depth0), depth0))
    + "</td><td style=\"width: 150px\">"
    + alias2((helpers.CHSIDate || (depth0 && depth0.CHSIDate) || alias3).call(depth0,(depth0 != null ? depth0.DateOpened : depth0),{"name":"CHSIDate","hash":{},"data":data}))
    + "</td><td style=\"width: 100px\">"
    + alias2(alias1((depth0 != null ? depth0.Type : depth0), depth0))
    + "</td><td style=\"width: 70px\">"
    + alias2(alias1((depth0 != null ? depth0.Status : depth0), depth0))
    + "</td><td style=\"width: 130px\">"
    + alias2((helpers.CHSIDate || (depth0 && depth0.CHSIDate) || alias3).call(depth0,(depth0 != null ? depth0.DateOfIncident : depth0),{"name":"CHSIDate","hash":{},"data":data}))
    + "</td><td style=\"width: 100px\">"
    + alias2((helpers.CHSIDate || (depth0 && depth0.CHSIDate) || alias3).call(depth0,(depth0 != null ? depth0.DateClosed : depth0),{"name":"CHSIDate","hash":{},"data":data}))
    + "</td><td style=\"width: 80px\" class=\"sorttable_nosort\"><a href=\"/claims/claimsunderreviewdetails.aspx?claimkey="
    + alias2(alias1((depth0 != null ? depth0.ClaimID : depth0), depth0))
    + "&claimversion="
    + alias2(alias1((depth0 != null ? depth0.ClaimVersion : depth0), depth0))
    + "\">(View)</a></td></tr>";
},"6":function(depth0,helpers,partials,data) {
    return "            <p>No claims found using the supplied search filters.  Please refine your search and try again...</p>\r\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.ShowTooManyRecords : depth0),{"name":"if","hash":{},"fn":this.program(1, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "<br /><span style=\"font-size: 20px;\">Reported Claims</span><hr style=\"color: #7ba2d6;\" />\r\n"
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.ClaimTableRecord : depth0),{"name":"if","hash":{},"fn":this.program(3, data, 0),"inverse":this.program(6, data, 0),"data":data})) != null ? stack1 : "");
},"useData":true});