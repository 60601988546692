this["CHSITemplates"]["Template_QuotePremium"] = Handlebars.template({"1":function(depth0,helpers,partials,data) {
    var stack1;

  return this.escapeExpression((helpers.GetVariableSummaryTitles || (depth0 && depth0.GetVariableSummaryTitles) || helpers.helperMissing).call(depth0,"Variables",((stack1 = (depth0 != null ? depth0.value : depth0)) != null ? stack1.VariableID : stack1),(depth0 != null ? depth0.value : depth0),1,{"name":"GetVariableSummaryTitles","hash":{},"data":data}))
    + "\r\n";
},"3":function(depth0,helpers,partials,data) {
    var stack1;

  return "<tr>\r\n    "
    + this.escapeExpression((helpers.GetVariableSummary || (depth0 && depth0.GetVariableSummary) || helpers.helperMissing).call(depth0,"Variables",((stack1 = (depth0 != null ? depth0.value : depth0)) != null ? stack1.VariableID : stack1),(depth0 != null ? depth0.value : depth0),1,{"name":"GetVariableSummary","hash":{},"data":data}))
    + "\r\n</tr>\r\n";
},"5":function(depth0,helpers,partials,data) {
    var helper;

  return "               <input type=\"text\" id=\"jxPolicyTotalD\" readonly=\"readonly\" value=\""
    + this.escapeExpression(((helper = (helper = helpers.PolicyTotalD || (depth0 != null ? depth0.PolicyTotalD : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"PolicyTotalD","hash":{},"data":data}) : helper)))
    + "\" />";
},"7":function(depth0,helpers,partials,data) {
    var helper;

  return "               <input type=\"text\" id=\"jxPolicyTotalE\" readonly=\"readonly\" value=\""
    + this.escapeExpression(((helper = (helper = helpers.PolicyTotalE || (depth0 != null ? depth0.PolicyTotalE : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"PolicyTotalE","hash":{},"data":data}) : helper)))
    + "\" />";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1, helper, alias1=helpers.helperMissing;

  return "<h3 style=\"clear:both; border-bottom: solid 1px gray;\">Summary</h3>\r\n<br />\r\n<table class=\"VerticalTable\">\r\n"
    + ((stack1 = (helpers.firstProperty || (depth0 && depth0.firstProperty) || alias1).call(depth0,(depth0 != null ? depth0.Vars : depth0),{"name":"firstProperty","hash":{},"fn":this.program(1, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = (helpers.eachProperty || (depth0 && depth0.eachProperty) || alias1).call(depth0,(depth0 != null ? depth0.Vars : depth0),{"name":"eachProperty","hash":{},"fn":this.program(3, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "    <tr>\r\n        <td>\r\n            &nbsp;\r\n        </td>\r\n    </tr>\r\n</table>\r\n<h3 style=\"clear:both;border-bottom: solid 1px gray;\">Policy Total</h3>\r\n<br />\r\n<div style=\"text-align:left; padding-right: 42px;\">\r\n    <table class=\"VerticalTable\">\r\n        <tr>\r\n            <td class=\"VariableLabel NonCollapsable\">               \r\n            </td>\r\n            <td class=\"VariableValue NonCollapsable\">\r\n                Endorsement Value\r\n            </td>\r\n            <td class=\"VariableValue NonCollapsable\">\r\n                Original Value\r\n            </td>\r\n            <td class=\"VariableValue NonCollapsable\">\r\n                Difference\r\n            </td>\r\n        </tr>        \r\n       <tr>\r\n           <td class=\"VariableLabel NonCollapsable\">\r\n              Policy Total:             \r\n           </td>\r\n           <td class=\"VariableValue NonCollapsable\">\r\n               <input type=\"text\" id=\"jxPolicyTotal\" readonly=\"readonly\" value=\""
    + this.escapeExpression(((helper = (helper = helpers.PolicyTotal || (depth0 != null ? depth0.PolicyTotal : depth0)) != null ? helper : alias1),(typeof helper === "function" ? helper.call(depth0,{"name":"PolicyTotal","hash":{},"data":data}) : helper)))
    + "\" />\r\n           </td>          \r\n           <td class=\"VariableValue NonCollapsable\">\r\n"
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.PolicyTotalE : depth0),{"name":"if","hash":{},"fn":this.program(5, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\r\n           </td>\r\n           <td class=\"VariableValue NonCollapsable\">\r\n"
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.PolicyTotalE : depth0),{"name":"if","hash":{},"fn":this.program(7, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\r\n           </td>\r\n       </tr>        \r\n    </table>\r\n</div>";
},"useData":true});