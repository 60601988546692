this["CHSITemplates"]["ClaimManagement"]["IncidentDetailsView"] = Handlebars.template({"1":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return "                    <option "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || helpers.helperMissing).call(depth0,depth0,((stack1 = (depths[1] != null ? depths[1].Incident : depths[1])) != null ? stack1.Cause : stack1),{"name":"compare","hash":{},"fn":this.program(2, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ">"
    + this.escapeExpression(this.lambda(depth0, depth0))
    + "</option>\r\n";
},"2":function(depth0,helpers,partials,data) {
    return " selected";
},"4":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return "                    <option "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || helpers.helperMissing).call(depth0,depth0,((stack1 = (depths[1] != null ? depths[1].Incident : depths[1])) != null ? stack1.IncidentType : stack1),{"name":"compare","hash":{},"fn":this.program(2, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ">"
    + this.escapeExpression(this.lambda(depth0, depth0))
    + "</option>\r\n";
},"6":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=this.lambda, alias2=this.escapeExpression;

  return "                    <option "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.LocationID : depth0),((stack1 = (depths[1] != null ? depths[1].Incident : depths[1])) != null ? stack1.LocationID : stack1),{"name":"compare","hash":{},"fn":this.program(2, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + " value=\""
    + alias2(alias1((depth0 != null ? depth0.LocationID : depth0), depth0))
    + "\">"
    + alias2(alias1((depth0 != null ? depth0.Description : depth0), depth0))
    + "</option>\r\n";
},"8":function(depth0,helpers,partials,data) {
    return "disabled";
},"10":function(depth0,helpers,partials,data) {
    return " checked";
},"12":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return "                    <option "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || helpers.helperMissing).call(depth0,depth0,((stack1 = (depths[1] != null ? depths[1].Incident : depths[1])) != null ? stack1.InjuryCause : stack1),{"name":"compare","hash":{},"fn":this.program(2, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ">"
    + this.escapeExpression(this.lambda(depth0, depth0))
    + "</option>\r\n";
},"14":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return "                    <option "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || helpers.helperMissing).call(depth0,depth0,((stack1 = (depths[1] != null ? depths[1].Incident : depths[1])) != null ? stack1.IllnessCause : stack1),{"name":"compare","hash":{},"fn":this.program(2, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ">"
    + this.escapeExpression(this.lambda(depth0, depth0))
    + "</option>\r\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=this.lambda, alias2=this.escapeExpression;

  return "<div class=\"popup-form\">\r\n    <div class=\"group-container group-bg\">\r\n        <h1>Incident Details</h1>\r\n        <div class=\"group-container col-2\">\r\n            <div class=\"input-container col-2\">\r\n                <label>Incident Cause</label>\r\n                <select id=\"Cause\" class=\"popup-form-input\">\r\n"
    + ((stack1 = helpers.each.call(depth0,((stack1 = ((stack1 = (depth0 != null ? depth0.Config : depth0)) != null ? stack1.references : stack1)) != null ? stack1.IncidentCauses : stack1),{"name":"each","hash":{},"fn":this.program(1, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "                </select>\r\n            </div>\r\n            <div class=\"input-container col-2\">\r\n                <label>Incident Types</label>\r\n                <select id=\"IncidentType\" class=\"popup-form-input\">\r\n"
    + ((stack1 = helpers.each.call(depth0,((stack1 = ((stack1 = (depth0 != null ? depth0.Config : depth0)) != null ? stack1.references : stack1)) != null ? stack1.IncidentTypes : stack1),{"name":"each","hash":{},"fn":this.program(4, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "                </select>\r\n            </div>\r\n            <div class=\"input-container col-2\">\r\n                <label>Equipment Used</label>\r\n                <input type=\"text\" id=\"EquipmentUsed\" class=\"popup-form-input\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.Incident : depth0)) != null ? stack1.EquipmentUsed : stack1), depth0))
    + "\" />\r\n            </div>\r\n            <div class=\"input-container col-2\">\r\n                <label>Other Claim Num.</label>\r\n                <input type=\"text\" id=\"OtherClaimNumber\" class=\"popup-form-input\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.Incident : depth0)) != null ? stack1.OtherClaimNumber : stack1), depth0))
    + "\" />\r\n            </div>\r\n            <div class=\"input-container col-2\">\r\n                <label>OSHA Description</label>\r\n                <input type=\"text\" id=\"OSHADesc\" class=\"popup-form-input\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.Incident : depth0)) != null ? stack1.OSHADesc : stack1), depth0))
    + "\" />\r\n            </div>\r\n            <div class=\"input-container col-2\" style=\"clear:both;\">\r\n                <label>Select Client Location</label>\r\n                <select id=\"LocationID\" class=\"popup-form-input\">\r\n                    <option value=\"0\">Custom</option>\r\n"
    + ((stack1 = helpers.each.call(depth0,((stack1 = (depth0 != null ? depth0.Config : depth0)) != null ? stack1.locations : stack1),{"name":"each","hash":{},"fn":this.program(6, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "                </select>\r\n            </div>\r\n            <div class=\"input-container col-2\">\r\n                <label>New Location</label>\r\n                <input type=\"text\" id=\"IncidentLocation\" class=\"popup-form-input loc-data\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.Incident : depth0)) != null ? stack1.IncidentLocation : stack1), depth0))
    + "\" "
    + ((stack1 = helpers["if"].call(depth0,((stack1 = (depth0 != null ? depth0.Incident : depth0)) != null ? stack1.LocationID : stack1),{"name":"if","hash":{},"fn":this.program(8, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "/>\r\n            </div>\r\n            <div class=\"input-container col-1\">\r\n                <label>Address</label>\r\n                <input type=\"text\" id=\"Address1\" class=\"popup-form-input loc-data\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.Incident : depth0)) != null ? stack1.Address1 : stack1), depth0))
    + "\" "
    + ((stack1 = helpers["if"].call(depth0,((stack1 = (depth0 != null ? depth0.Incident : depth0)) != null ? stack1.LocationID : stack1),{"name":"if","hash":{},"fn":this.program(8, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + " />\r\n            </div>\r\n            <div class=\"input-container col-1\">\r\n                <input type=\"text\" id=\"Address2\" class=\"popup-form-input loc-data\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.Incident : depth0)) != null ? stack1.Address2 : stack1), depth0))
    + "\" "
    + ((stack1 = helpers["if"].call(depth0,((stack1 = (depth0 != null ? depth0.Incident : depth0)) != null ? stack1.LocationID : stack1),{"name":"if","hash":{},"fn":this.program(8, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + " />\r\n            </div>\r\n            <div class=\"input-container col-2\">\r\n                <label>City</label>\r\n                <input type=\"text\" id=\"City\" class=\"popup-form-input loc-data\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.Incident : depth0)) != null ? stack1.City : stack1), depth0))
    + "\" "
    + ((stack1 = helpers["if"].call(depth0,((stack1 = (depth0 != null ? depth0.Incident : depth0)) != null ? stack1.LocationID : stack1),{"name":"if","hash":{},"fn":this.program(8, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + " />\r\n            </div>\r\n            <div class=\"input-container col-4\">\r\n                <label>State</label>\r\n                <input type=\"text\" id=\"State\" class=\"popup-form-input loc-data\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.Incident : depth0)) != null ? stack1.State : stack1), depth0))
    + "\" maxlength=\"2\" "
    + ((stack1 = helpers["if"].call(depth0,((stack1 = (depth0 != null ? depth0.Incident : depth0)) != null ? stack1.LocationID : stack1),{"name":"if","hash":{},"fn":this.program(8, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + " />\r\n            </div>\r\n            <div class=\"input-container col-4\">\r\n                <label>Zip</label>\r\n                <input type=\"text\" id=\"Zip\" class=\"popup-form-input loc-data\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.Incident : depth0)) != null ? stack1.Zip : stack1), depth0))
    + "\" "
    + ((stack1 = helpers["if"].call(depth0,((stack1 = (depth0 != null ? depth0.Incident : depth0)) != null ? stack1.LocationID : stack1),{"name":"if","hash":{},"fn":this.program(8, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + " />\r\n            </div>\r\n        </div>\r\n        <div class=\"group-container col-2\">\r\n            <div class=\"input-container col-2 center\">\r\n                <label>Occured on Premises?</label>\r\n                <input type=\"checkbox\" id=\"OccurredOnPremises\" class=\"popup-form-input\" "
    + ((stack1 = helpers["if"].call(depth0,((stack1 = (depth0 != null ? depth0.Incident : depth0)) != null ? stack1.OccurredOnPremises : stack1),{"name":"if","hash":{},"fn":this.program(10, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + " />\r\n            </div>\r\n            <div class=\"input-container col-2 center\">\r\n                <label>Physical Restrictions?</label>\r\n                <input type=\"checkbox\" id=\"PhysicalRestrictions\" class=\"popup-form-input\" "
    + ((stack1 = helpers["if"].call(depth0,((stack1 = (depth0 != null ? depth0.Incident : depth0)) != null ? stack1.PhysicalRestrictions : stack1),{"name":"if","hash":{},"fn":this.program(10, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + " />\r\n            </div>\r\n            <div class=\"input-container col-2 center\">\r\n                <label>Hospitalized?</label>\r\n                <input type=\"checkbox\" id=\"Hospitalized\" class=\"popup-form-input\" "
    + ((stack1 = helpers["if"].call(depth0,((stack1 = (depth0 != null ? depth0.Incident : depth0)) != null ? stack1.Hospitalized : stack1),{"name":"if","hash":{},"fn":this.program(10, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + " />\r\n            </div>\r\n            <div class=\"input-container col-2 center\">\r\n                <label>ER Treatment Required?</label>\r\n                <input type=\"checkbox\" id=\"ERTreatment\" class=\"popup-form-input\" "
    + ((stack1 = helpers["if"].call(depth0,((stack1 = (depth0 != null ? depth0.Incident : depth0)) != null ? stack1.ERTreatment : stack1),{"name":"if","hash":{},"fn":this.program(10, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + " />\r\n            </div>\r\n            <div class=\"input-container col-2\">\r\n                <label>Injury Cause</label>\r\n                <select id=\"InjuryCause\" class=\"popup-form-input\">\r\n"
    + ((stack1 = helpers.each.call(depth0,((stack1 = ((stack1 = (depth0 != null ? depth0.Config : depth0)) != null ? stack1.references : stack1)) != null ? stack1.InjuryCauses : stack1),{"name":"each","hash":{},"fn":this.program(12, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "                </select>\r\n            </div>\r\n            <div class=\"input-container col-2\">\r\n                <label>Illness Cause</label>\r\n                <select id=\"IllnessCause\" class=\"popup-form-input\">\r\n"
    + ((stack1 = helpers.each.call(depth0,((stack1 = ((stack1 = (depth0 != null ? depth0.Config : depth0)) != null ? stack1.references : stack1)) != null ? stack1.IllnessCauses : stack1),{"name":"each","hash":{},"fn":this.program(14, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "                </select>\r\n            </div>\r\n            <div class=\"input-container col-2\">\r\n                <label>Injury Description</label>\r\n                <textarea id=\"InjuryDescription\" class=\"popup-form-textarea\" style=\"height:auto\" rows=\"3\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.Incident : depth0)) != null ? stack1.InjuryDescription : stack1), depth0))
    + "</textarea>\r\n            </div>\r\n            <div class=\"input-container col-2\">\r\n                <label>Illness Description</label>\r\n                <textarea id=\"IllnessDescription\" class=\"popup-form-textarea\" style=\"height:auto\" rows=\"3\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.Incident : depth0)) != null ? stack1.IllnessDescription : stack1), depth0))
    + "</textarea>\r\n            </div>\r\n            <div class=\"input-container col-2\">\r\n                <label>Incident Description</label>\r\n                <textarea id=\"IncidentDescription\" class=\"popup-form-textarea\" style=\"height:auto\" rows=\"3\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.Incident : depth0)) != null ? stack1.IncidentDescription : stack1), depth0))
    + "</textarea>\r\n            </div>\r\n            <div class=\"input-container col-2\">\r\n                <label>Activity Performed</label>\r\n                <textarea id=\"Activity\" class=\"popup-form-textarea\" style=\"height:auto\" rows=\"3\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.Incident : depth0)) != null ? stack1.Activity : stack1), depth0))
    + "</textarea>\r\n            </div>\r\n        </div>\r\n    </div>\r\n</div>";
},"useData":true,"useDepths":true});