this["CHSITemplates"]["ClaimManagement"]["EventModalView"] = Handlebars.template({"1":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return "                <option "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || helpers.helperMissing).call(depth0,depth0,((stack1 = (depths[1] != null ? depths[1].Event : depths[1])) != null ? stack1.EventType : stack1),{"name":"compare","hash":{},"fn":this.program(2, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ">"
    + this.escapeExpression(this.lambda(depth0, depth0))
    + "</option>\r\n";
},"2":function(depth0,helpers,partials,data) {
    return " selected";
},"4":function(depth0,helpers,partials,data) {
    return " disabled ";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=this.lambda, alias2=this.escapeExpression;

  return "<div class=\"popup-form\">\r\n    <div class=\"group-container col-1\">\r\n        <div class=\"input-container col-2\">\r\n            <label>Type</label>\r\n            <select id=\"EventType\" class=\"popup-form-input\">\r\n"
    + ((stack1 = helpers.each.call(depth0,((stack1 = (depth0 != null ? depth0.Config : depth0)) != null ? stack1.EventType : stack1),{"name":"each","hash":{},"fn":this.program(1, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "            </select>\r\n        </div>\r\n        <div class=\"input-container col-2\">\r\n            <label>Date</label>\r\n            <input type=\"text\" id=\"CreateTmsp\" class=\"popup-form-input date\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.Event : depth0)) != null ? stack1.CreateTmsp : stack1), depth0))
    + "\" />\r\n        </div>\r\n        <div class=\"input-container col-1\">\r\n            <label>Description</label>\r\n            <textarea id=\"EventResult\" class=\"popup-form-textarea\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.Event : depth0)) != null ? stack1.EventResult : stack1), depth0))
    + "</textarea>\r\n        </div>\r\n        <div class=\"input-container col-2\">\r\n            <label>Created By</label>\r\n            <input type=\"text\" id=\"CreateUser\" class=\"popup-form-input\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.Event : depth0)) != null ? stack1.CreateUser : stack1), depth0))
    + "\" readonly/>\r\n        </div>\r\n    </div>\r\n</div>\r\n<div class=\"btn-bar\">\r\n    <input class=\"event-delete edit\" value=\"Delete\" type=\"button\" style=\"float:left;\" "
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.isNew : depth0),{"name":"if","hash":{},"fn":this.program(4, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + " />\r\n    <input class=\"event-cancel\" value=\"Cancel\" type=\"button\" style=\"float:right;\" />\r\n    <input class=\"event-save edit\" value=\"Save\" type=\"button\" style=\"float:right;margin-right:20px\" />\r\n</div> ";
},"useData":true,"useDepths":true});