this["CHSITemplates"]["Template_QuoteInfo_Commissions"] = Handlebars.template({"1":function(depth0,helpers,partials,data) {
    var helper, alias1=helpers.helperMissing, alias2="function", alias3=this.escapeExpression;

  return "        <tr id=\"CommissionID_"
    + alias3(((helper = (helper = helpers.CommissionID || (depth0 != null ? depth0.CommissionID : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"CommissionID","hash":{},"data":data}) : helper)))
    + "\">\r\n            <td>\r\n                <div id=\"jxProducerMode_"
    + alias3(((helper = (helper = helpers.index || (data && data.index)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"index","hash":{},"data":data}) : helper)))
    + "\" data-producer=\""
    + alias3(((helper = (helper = helpers.ProducerName || (depth0 != null ? depth0.ProducerName : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"ProducerName","hash":{},"data":data}) : helper)))
    + "\" data-producerid=\""
    + alias3(((helper = (helper = helpers.ProducerID || (depth0 != null ? depth0.ProducerID : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"ProducerID","hash":{},"data":data}) : helper)))
    + "\">"
    + alias3(((helper = (helper = helpers.ProducerName || (depth0 != null ? depth0.ProducerName : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"ProducerName","hash":{},"data":data}) : helper)))
    + "</div>\r\n            </td>\r\n            <td>\r\n                <div id=\"jxRepAgentMode_"
    + alias3(((helper = (helper = helpers.index || (data && data.index)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"index","hash":{},"data":data}) : helper)))
    + "\" data-repagent=\""
    + alias3(((helper = (helper = helpers.ProducerRep || (depth0 != null ? depth0.ProducerRep : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"ProducerRep","hash":{},"data":data}) : helper)))
    + "\">"
    + alias3(((helper = (helper = helpers.ProducerRep || (depth0 != null ? depth0.ProducerRep : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"ProducerRep","hash":{},"data":data}) : helper)))
    + "</div>\r\n            </td>\r\n            <td>\r\n                <div id=\"jxCustomerMode_"
    + alias3(((helper = (helper = helpers.index || (data && data.index)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"index","hash":{},"data":data}) : helper)))
    + "\" data-customerno=\""
    + alias3(((helper = (helper = helpers.ProducerCustomerID || (depth0 != null ? depth0.ProducerCustomerID : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"ProducerCustomerID","hash":{},"data":data}) : helper)))
    + "\">"
    + alias3(((helper = (helper = helpers.ProducerCustomerID || (depth0 != null ? depth0.ProducerCustomerID : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"ProducerCustomerID","hash":{},"data":data}) : helper)))
    + "</div>\r\n            </td>\r\n            <td>\r\n                <div id=\"jxPrimaryMode_"
    + alias3(((helper = (helper = helpers.index || (data && data.index)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"index","hash":{},"data":data}) : helper)))
    + "\" data-primary=\""
    + alias3(((helper = (helper = helpers.PrimaryProducer || (depth0 != null ? depth0.PrimaryProducer : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"PrimaryProducer","hash":{},"data":data}) : helper)))
    + "\">"
    + alias3(((helper = (helper = helpers.PrimaryProducer || (depth0 != null ? depth0.PrimaryProducer : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"PrimaryProducer","hash":{},"data":data}) : helper)))
    + "</div>\r\n            </td>\r\n            <td>\r\n                <div id=\"jxRateMode_"
    + alias3(((helper = (helper = helpers.index || (data && data.index)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"index","hash":{},"data":data}) : helper)))
    + "\" data-rate=\""
    + alias3(((helper = (helper = helpers.CommissionRate || (depth0 != null ? depth0.CommissionRate : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"CommissionRate","hash":{},"data":data}) : helper)))
    + "\">"
    + alias3(((helper = (helper = helpers.CommissionRate || (depth0 != null ? depth0.CommissionRate : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"CommissionRate","hash":{},"data":data}) : helper)))
    + "</div>\r\n            </td>\r\n            <td>\r\n                <div id=\"jxRatingMode_"
    + alias3(((helper = (helper = helpers.index || (data && data.index)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"index","hash":{},"data":data}) : helper)))
    + "\" data-rating=\""
    + alias3(((helper = (helper = helpers.UseRating || (depth0 != null ? depth0.UseRating : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"UseRating","hash":{},"data":data}) : helper)))
    + "\">"
    + alias3(((helper = (helper = helpers.UseRating || (depth0 != null ? depth0.UseRating : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"UseRating","hash":{},"data":data}) : helper)))
    + "</div>\r\n            </td>\r\n            <td>\r\n                <div id=\"jxDeleteMode_"
    + alias3(((helper = (helper = helpers.index || (data && data.index)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"index","hash":{},"data":data}) : helper)))
    + "\" data-commissionid=\""
    + alias3(((helper = (helper = helpers.CommissionID || (depth0 != null ? depth0.CommissionID : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"CommissionID","hash":{},"data":data}) : helper)))
    + "\"><input type=\"button\" class=\"jxDeleteCommission buttonWidth\" data-commissionid=\""
    + alias3(((helper = (helper = helpers.CommissionID || (depth0 != null ? depth0.CommissionID : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"CommissionID","hash":{},"data":data}) : helper)))
    + "\" value=\" Delete \" /></div>\r\n            </td>\r\n            <td>\r\n                <div id=\"jxEditMode_"
    + alias3(((helper = (helper = helpers.index || (data && data.index)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"index","hash":{},"data":data}) : helper)))
    + "\" data-status=\""
    + alias3(((helper = (helper = helpers.Status || (depth0 != null ? depth0.Status : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"Status","hash":{},"data":data}) : helper)))
    + "\" data-policyperiod=\""
    + alias3(((helper = (helper = helpers.PolicyPeriod || (depth0 != null ? depth0.PolicyPeriod : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"PolicyPeriod","hash":{},"data":data}) : helper)))
    + "\" data-effectivedate=\""
    + alias3(((helper = (helper = helpers.EffectiveDate || (depth0 != null ? depth0.EffectiveDate : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"EffectiveDate","hash":{},"data":data}) : helper)))
    + "\" data-expirationdate=\""
    + alias3(((helper = (helper = helpers.ExpirationDate || (depth0 != null ? depth0.ExpirationDate : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"ExpirationDate","hash":{},"data":data}) : helper)))
    + "\" data-paycommission=\""
    + alias3(((helper = (helper = helpers.PayCommission || (depth0 != null ? depth0.PayCommission : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"PayCommission","hash":{},"data":data}) : helper)))
    + "\" data-producercustomerid=\""
    + alias3(((helper = (helper = helpers.ProducerCustomerID || (depth0 != null ? depth0.ProducerCustomerID : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"ProducerCustomerID","hash":{},"data":data}) : helper)))
    + "\" data-producercsr=\""
    + alias3(((helper = (helper = helpers.ProducerCSR || (depth0 != null ? depth0.ProducerCSR : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"ProducerCSR","hash":{},"data":data}) : helper)))
    + "\" data-comments=\""
    + alias3(((helper = (helper = helpers.Comments || (depth0 != null ? depth0.Comments : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"Comments","hash":{},"data":data}) : helper)))
    + "\"><input type=\"button\" class=\"jxEditCommission buttonWidth\" data-commissionindex=\""
    + alias3(((helper = (helper = helpers.index || (data && data.index)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"index","hash":{},"data":data}) : helper)))
    + "\" data-commissionid=\""
    + alias3(((helper = (helper = helpers.CommissionID || (depth0 != null ? depth0.CommissionID : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"CommissionID","hash":{},"data":data}) : helper)))
    + "\" value=\" Edit \" /></div>\r\n            </td>\r\n        </tr>\r\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1, alias1=helpers.helperMissing, alias2=this.escapeExpression;

  return "<table width=\"100%\" class=\"dtable sortable\">\r\n    <thead>\r\n        <tr>\r\n            <td>\r\n                Broker\r\n            </td>\r\n            <td>\r\n                Rep/Agent\r\n            </td>\r\n            <td>\r\n                Cust#\r\n            </td>\r\n            <td>\r\n                Primary\r\n            </td>\r\n            <td>\r\n                Rate\r\n            </td>\r\n            <td>\r\n                Rating?\r\n            </td>\r\n            <td class=\"sorttable_nosort\">\r\n                &nbsp;\r\n            </td>\r\n            <td class=\"sorttable_nosort\">\r\n                &nbsp;\r\n            </td>\r\n        </tr>\r\n    </thead>\r\n    <tbody>\r\n        <tr id=\"NewCommission\">\r\n            <td>\r\n                <div id=\"jxProducers\"></div>\r\n            </td>\r\n            <td>\r\n                <input id=\"jxNewCommissionRepAgent\" type=\"text\" />\r\n            </td>\r\n            <td>\r\n                <input id=\"jxNewCommissionCustNo\" type=\"text\" />\r\n            </td>\r\n            <td>\r\n                <input id=\"jxNewCommissionPrimary\" type=\"checkbox\" />\r\n            </td>\r\n            <td>\r\n                <input id=\"jxNewCommissionRate\" type=\"text\" />\r\n            </td>\r\n            <td>\r\n                <input id=\"jxNewCommissionRating\" type=\"checkbox\" />\r\n            </td>\r\n            <td>\r\n                <input type=\"button\" id=\"jxSaveNewCommission\" class=\"buttonWidth\" value=\" Add \" />\r\n            </td>\r\n            <td>\r\n                <input type=\"button\" id=\"jxNewResetCommission\" class=\"buttonWidth\" value=\" Reset \" />\r\n            </td>\r\n        </tr>\r\n"
    + ((stack1 = helpers.each.call(depth0,(depth0 != null ? depth0.CommissionsObject : depth0),{"name":"each","hash":{},"fn":this.program(1, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "    </tbody>\r\n</table>\r\n<br />\r\n<br />\r\n<div>\r\n    <b>Total Commissions</b> <input type=\"text\" style=\"width: 50px;\" value=\""
    + alias2((helpers.RoundTotalCommission || (depth0 && depth0.RoundTotalCommission) || alias1).call(depth0,((stack1 = (depth0 != null ? depth0.CommissionsObject : depth0)) != null ? stack1.TotalCommission : stack1),{"name":"RoundTotalCommission","hash":{},"data":data}))
    + "\" readonly /> &nbsp;&nbsp; <b>Total Ratable Commissions</b> <input type=\"text\" style=\"width: 50px;\" value=\""
    + alias2((helpers.RoundTotalCommission || (depth0 && depth0.RoundTotalCommission) || alias1).call(depth0,((stack1 = (depth0 != null ? depth0.CommissionsObject : depth0)) != null ? stack1.TotalRatableCommission : stack1),{"name":"RoundTotalCommission","hash":{},"data":data}))
    + "\" readonly />\r\n</div>";
},"useData":true});