this["CHSITemplates"]["ClaimManagement"]["ClaimDetailsView"] = Handlebars.template({"1":function(depth0,helpers,partials,data) {
    return "disabled";
},"3":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return "                <option "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || helpers.helperMissing).call(depth0,depth0,((stack1 = (depths[1] != null ? depths[1].Claim : depths[1])) != null ? stack1.ClaimClass : stack1),{"name":"compare","hash":{},"fn":this.program(4, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ">"
    + this.escapeExpression(this.lambda(depth0, depth0))
    + "</option>\r\n";
},"4":function(depth0,helpers,partials,data) {
    return " selected";
},"6":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return "                <option "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || helpers.helperMissing).call(depth0,depth0,((stack1 = (depths[1] != null ? depths[1].Claim : depths[1])) != null ? stack1.ClaimType : stack1),{"name":"compare","hash":{},"fn":this.program(4, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ">"
    + this.escapeExpression(this.lambda(depth0, depth0))
    + "</option>\r\n";
},"8":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return "                <option "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || helpers.helperMissing).call(depth0,depth0,((stack1 = (depths[1] != null ? depths[1].Claim : depths[1])) != null ? stack1.ClaimStatus : stack1),{"name":"compare","hash":{},"fn":this.program(4, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ">"
    + this.escapeExpression(this.lambda(depth0, depth0))
    + "</option>\r\n";
},"10":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return "                <option "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || helpers.helperMissing).call(depth0,depth0,((stack1 = (depths[1] != null ? depths[1].Claim : depths[1])) != null ? stack1.ReopenReason : stack1),{"name":"compare","hash":{},"fn":this.program(4, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ">"
    + this.escapeExpression(this.lambda(depth0, depth0))
    + "</option>\r\n";
},"12":function(depth0,helpers,partials,data) {
    return " checked";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=this.lambda, alias2=this.escapeExpression;

  return "<div class=\"popup-form\">\r\n    <div class=\"group-container group-bg\">\r\n        <h1>Claim Details</h1>\r\n        <div class=\"input-container col-2\">\r\n            <label>Claim Number</label>\r\n            <input type=\"text\" id=\"ClaimNumber\" class=\"popup-form-input\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.Claim : depth0)) != null ? stack1.ClaimNumber : stack1), depth0))
    + "\" "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || helpers.helperMissing).call(depth0,((stack1 = (depth0 != null ? depth0.Claim : depth0)) != null ? stack1.ClaimNumber : stack1),"",{"name":"compare","hash":{"operator":"!="},"fn":this.program(1, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + " />\r\n        </div>\r\n        <div class=\"input-container col-2\">\r\n            <label>Classification</label>\r\n            <!--<input type=\"text\" id=\"ClaimClass\" class=\"popup-form-input\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.Claim : depth0)) != null ? stack1.ClaimClass : stack1), depth0))
    + "\" disabled />-->\r\n            <select id=\"ClaimClass\" class=\"popup-form-input\">\r\n"
    + ((stack1 = helpers.each.call(depth0,((stack1 = (depth0 != null ? depth0.Config : depth0)) != null ? stack1.ClaimClass : stack1),{"name":"each","hash":{},"fn":this.program(3, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "            </select>\r\n        </div>\r\n        <div class=\"input-container col-2\">\r\n            <label>Type</label>\r\n            <!--<input type=\"text\" id=\"ClaimType\" class=\"popup-form-input\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.Claim : depth0)) != null ? stack1.ClaimType : stack1), depth0))
    + "\" disabled />-->\r\n            <select id=\"ClaimType\" class=\"popup-form-input\">\r\n"
    + ((stack1 = helpers.each.call(depth0,((stack1 = (depth0 != null ? depth0.Config : depth0)) != null ? stack1.ClaimType : stack1),{"name":"each","hash":{},"fn":this.program(6, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "            </select>\r\n        </div>\r\n        <div class=\"input-container col-2\">\r\n            <label>Status</label>\r\n            <select id=\"ClaimStatus\" class=\"popup-form-input\">\r\n"
    + ((stack1 = helpers.each.call(depth0,((stack1 = (depth0 != null ? depth0.Config : depth0)) != null ? stack1.ClaimStatus : stack1),{"name":"each","hash":{},"fn":this.program(8, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "            </select>\r\n        </div>\r\n        <div class=\"input-container col-2\">\r\n            <label>Date of Incident</label>\r\n            <input type=\"text\" id=\"DateOfIncident\" class=\"popup-form-input date\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.Claim : depth0)) != null ? stack1.DateOfIncident : stack1), depth0))
    + "\" />\r\n        </div>\r\n        <div class=\"input-container col-2\">\r\n            <label>Date Reported</label>\r\n            <input type=\"text\" id=\"DateReported\" class=\"popup-form-input date\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.Claim : depth0)) != null ? stack1.DateReported : stack1), depth0))
    + "\" />\r\n        </div>\r\n        <div class=\"input-container col-2\">\r\n            <label>Date Opened</label>\r\n            <input type=\"text\" id=\"DateOpened\" class=\"popup-form-input date\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.Claim : depth0)) != null ? stack1.DateOpened : stack1), depth0))
    + "\" />\r\n        </div>\r\n        <div class=\"input-container col-2\">\r\n            <label>Date Closed</label>\r\n            <input type=\"text\" id=\"DateClosed\" class=\"popup-form-input date\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.Claim : depth0)) != null ? stack1.DateClosed : stack1), depth0))
    + "\" />\r\n        </div>\r\n        <div class=\"input-container col-2\">\r\n            <label>Date Reopened</label>\r\n            <input type=\"text\" id=\"DateReOpened\" class=\"popup-form-input date\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.Claim : depth0)) != null ? stack1.DateReOpened : stack1), depth0))
    + "\" />\r\n        </div>\r\n        <div class=\"input-container col-2\">\r\n            <label>Reopen Reason</label>\r\n            <select id=\"ReopenReason\" class=\"popup-form-input\">\r\n"
    + ((stack1 = helpers.each.call(depth0,((stack1 = (depth0 != null ? depth0.Config : depth0)) != null ? stack1.ReOpenReason : stack1),{"name":"each","hash":{},"fn":this.program(10, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "            </select>\r\n        </div>\r\n\r\n        <div class=\"input-container col-3 center\">\r\n            <label>Subrogated?</label>\r\n            <input type=\"checkbox\" id=\"Subrogated\" class=\"popup-form-input\" "
    + ((stack1 = helpers["if"].call(depth0,((stack1 = (depth0 != null ? depth0.Claim : depth0)) != null ? stack1.Subrogated : stack1),{"name":"if","hash":{},"fn":this.program(12, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + " />\r\n        </div>\r\n        <div class=\"input-container col-3 center\">\r\n            <label>Subro Potential?</label>\r\n            <input type=\"checkbox\" id=\"SubroPotential\" class=\"popup-form-input\" "
    + ((stack1 = helpers["if"].call(depth0,((stack1 = (depth0 != null ? depth0.Claim : depth0)) != null ? stack1.SubroPotential : stack1),{"name":"if","hash":{},"fn":this.program(12, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + " />\r\n        </div>\r\n        <div class=\"input-container col-3 center\">\r\n            <label>Triaged?</label>\r\n            <input type=\"checkbox\" id=\"Triaged\" class=\"popup-form-input\" "
    + ((stack1 = helpers["if"].call(depth0,((stack1 = (depth0 != null ? depth0.Claim : depth0)) != null ? stack1.Triaged : stack1),{"name":"if","hash":{},"fn":this.program(12, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + " />\r\n        </div>\r\n        <div class=\"input-container col-3 center\">\r\n            <label>Litigated?</label>\r\n            <input type=\"checkbox\" id=\"Litigated\" class=\"popup-form-input\" "
    + ((stack1 = helpers["if"].call(depth0,((stack1 = (depth0 != null ? depth0.Claim : depth0)) != null ? stack1.Litigated : stack1),{"name":"if","hash":{},"fn":this.program(12, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + " />\r\n        </div>\r\n        <div class=\"input-container col-3 center\">\r\n            <label>Under Review</label>\r\n            <input type=\"checkbox\" id=\"UnderReview\" class=\"popup-form-input\" "
    + ((stack1 = helpers["if"].call(depth0,((stack1 = (depth0 != null ? depth0.Claim : depth0)) != null ? stack1.UnderReview : stack1),{"name":"if","hash":{},"fn":this.program(12, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + " />\r\n        </div>\r\n        <div class=\"input-container col-3 center\">\r\n            <label>Escalated?</label>\r\n            <input type=\"checkbox\" id=\"Escalated\" class=\"popup-form-input\" "
    + ((stack1 = helpers["if"].call(depth0,((stack1 = (depth0 != null ? depth0.Claim : depth0)) != null ? stack1.Escalated : stack1),{"name":"if","hash":{},"fn":this.program(12, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + " />\r\n        </div>\r\n        <div class=\"input-container col-2\">\r\n            <label>Subro Amount</label>\r\n            <input maxlength=\"100\" type=\"number\" id=\"SubroAmount\" class=\"popup-form-input\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.Claim : depth0)) != null ? stack1.SubroAmount : stack1), depth0))
    + "\" />\r\n        </div>\r\n        <div class=\"input-container col-2\">\r\n            <label>Jurisdiction</label>\r\n            <input type=\"text\" id=\"Jurisdiction\" class=\"popup-form-input\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.Claim : depth0)) != null ? stack1.Jurisdiction : stack1), depth0))
    + "\" />\r\n        </div>\r\n    </div>\r\n</div>";
},"useData":true,"useDepths":true});