this["CHSITemplates"]["Template_QuestionReview"] = Handlebars.template({"1":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return ((stack1 = helpers["with"].call(depth0,(depth0 != null ? depth0.ReviewObject : depth0),{"name":"with","hash":{},"fn":this.program(2, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "");
},"2":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return "<h3>Question</h3>  \r\n<div id=\"QuestionDetails\" class=\"containerForModal\">  \r\n    <table>\r\n"
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.FormElement : depth0),{"name":"if","hash":{},"fn":this.program(3, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.History : depth0),{"name":"if","hash":{},"fn":this.program(9, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "<h3>Warning</h3>    \r\n<div id=\"WarningDetails\" class=\"containerForModal\">\r\n    <table>\r\n"
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.ElementValidation : depth0),{"name":"if","hash":{},"fn":this.program(17, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "    </table>\r\n</div>\r\n\r\n<h3>Review</h3>    \r\n<div id=\"ReviewDetails\" class=\"containerForModal\">\r\n    <table>\r\n"
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.ElementReview : depth0),{"name":"if","hash":{},"fn":this.program(20, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "    </table>\r\n</div>\r\n    \r\n<h3>History</h3>    \r\n<div id=\"HistoryDetails\" class=\"containerForModal\">\r\n    <table>\r\n"
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.History : depth0),{"name":"if","hash":{},"fn":this.program(23, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "    </table>\r\n</div>\r\n";
},"3":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["with"].call(depth0,(depth0 != null ? depth0.FormElement : depth0),{"name":"with","hash":{},"fn":this.program(4, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "");
},"4":function(depth0,helpers,partials,data) {
    var stack1, helper, alias1=helpers.helperMissing, alias2="function", alias3=this.escapeExpression;

  return "        <tr>\r\n            <td>\r\n    Question:\r\n            </td>\r\n            <td>\r\n                <div id=\"QuestionInformationcontainerForModal\">\r\n                    <label id=\"uxQuestionInformation\">"
    + alias3(((helper = (helper = helpers.Label || (depth0 != null ? depth0.Label : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"Label","hash":{},"data":data}) : helper)))
    + "</label>\r\n                </div>\r\n            </td>\r\n        </tr>\r\n        <tr>\r\n            <td>\r\n    Section:\r\n            </td>\r\n            <td>\r\n                <div id=\"SectionInformationcontainerModal\">\r\n                    <label id=\"uxSectionInformation\">"
    + alias3(((helper = (helper = helpers.SectionTitle || (depth0 != null ? depth0.SectionTitle : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"SectionTitle","hash":{},"data":data}) : helper)))
    + "</label>\r\n                </div>\r\n            </td>\r\n        </tr>\r\n    </table>\r\n    <table>\r\n        <tr>\r\n            <td>\r\n    Required: <img id=\"uxQuestionRequired\" "
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.IsRequired : depth0),{"name":"if","hash":{},"fn":this.program(5, data, 0),"inverse":this.program(7, data, 0),"data":data})) != null ? stack1 : "")
    + "/> \r\n            </td>   \r\n            <td>\r\n                &nbsp&nbsp&nbsp\r\n            </td>                                          \r\n            <td>\r\n                UW Review Required: <img id=\"uxQuestionUWReviewRequired\" "
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.RequiresReview : depth0),{"name":"if","hash":{},"fn":this.program(5, data, 0),"inverse":this.program(7, data, 0),"data":data})) != null ? stack1 : "")
    + "/>\r\n            </td>\r\n        </tr>\r\n    </table>        \r\n</div>\r\n";
},"5":function(depth0,helpers,partials,data) {
    return "src=\"/Images/smSuccess.gif\"";
},"7":function(depth0,helpers,partials,data) {
    return "  src=\"/Images/smCancel.gif\" ";
},"9":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return ((stack1 = helpers["with"].call(depth0,((stack1 = (depth0 != null ? depth0.History : depth0)) != null ? stack1["0"] : stack1),{"name":"with","hash":{},"fn":this.program(10, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "");
},"10":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=helpers.helperMissing, alias2="function", alias3=this.escapeExpression;

  return "<h3>Response</h3>    \r\n<div id=\"ResponseDetails\" class=\"containerForModal\">\r\n    <table>\r\n        <tr>\r\n            <td>\r\n                    No Change: <input id=\"uxResponseNoChange\" value=\""
    + alias3(((helper = (helper = helpers.ChangeType || (depth0 != null ? depth0.ChangeType : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"ChangeType","hash":{},"data":data}) : helper)))
    + "\" type=\"checkbox\" "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias1).call(depth0,(depth0 != null ? depth0.ChangeType : depth0),"1",{"name":"compare","hash":{},"fn":this.program(11, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + " />\r\n            </td>\r\n        </tr>\r\n        <tr>            \r\n            <td valign=\"top\">\r\n    Current:\r\n            </td>\r\n            <td>\r\n                    <textarea id=\"uxResponseCurrent\" class=\"form-control\" rows=\"2\" cols=\"5\" "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias1).call(depth0,(depth0 != null ? depth0.ChangeType : depth0),"1",{"name":"compare","hash":{},"fn":this.program(13, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ">"
    + alias3(((helper = (helper = helpers.CurrentResultAsString || (depth0 != null ? depth0.CurrentResultAsString : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"CurrentResultAsString","hash":{},"data":data}) : helper)))
    + "</textarea>\r\n            </td>        \r\n        </tr>\r\n        <tr>\r\n            <td>\r\n                    N/A: <img id=\"uxResponseCurrentNA\" "
    + ((stack1 = helpers["if"].call(depth0,((stack1 = (depths[1] != null ? depths[1].FormElement : depths[1])) != null ? stack1.AllowNA : stack1),{"name":"if","hash":{},"fn":this.program(5, data, 0, blockParams, depths),"inverse":this.program(7, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + " />\r\n            </td>        \r\n        </tr>\r\n        <tr>\r\n            <td>\r\n    Prior:\r\n            </td>\r\n            <td>\r\n                    <label id=\"uxResponsePrior\">"
    + alias3(((helper = (helper = helpers.PriorResultAsString || (depth0 != null ? depth0.PriorResultAsString : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"PriorResultAsString","hash":{},"data":data}) : helper)))
    + "</label>\r\n            </td>\r\n        </tr>\r\n        <tr>\r\n            <td>\r\n                    N/A: <img id=\"uxResponsePriorNA\"  "
    + ((stack1 = helpers["if"].call(depth0,((stack1 = (depths[1] != null ? depths[1].FormElement : depths[1])) != null ? stack1.PrevAllowNA : stack1),{"name":"if","hash":{},"fn":this.program(15, data, 0, blockParams, depths),"inverse":this.program(7, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + " />\r\n            </td>        \r\n        </tr>\r\n    </table>\r\n</div>\r\n";
},"11":function(depth0,helpers,partials,data) {
    return " checked=\"checked\" ";
},"13":function(depth0,helpers,partials,data) {
    return " disabled=\"disabled\" ";
},"15":function(depth0,helpers,partials,data) {
    return " src=\"/Images/smSuccess.gif\"";
},"17":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["with"].call(depth0,(depth0 != null ? depth0.ElementValidation : depth0),{"name":"with","hash":{},"fn":this.program(18, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "");
},"18":function(depth0,helpers,partials,data) {
    var helper, alias1=helpers.helperMissing, alias2="function", alias3=this.escapeExpression;

  return "        <tr>\r\n            <td>\r\n                Warning Level:\r\n            </td>\r\n            <td>                                \r\n                <label id=\"uxWarningLevel\">"
    + alias3(((helper = (helper = helpers.ValidationResult || (depth0 != null ? depth0.ValidationResult : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"ValidationResult","hash":{},"data":data}) : helper)))
    + "</label>                                        \r\n            </td>\r\n        </tr>\r\n        <tr>\r\n            <td>\r\n    Description:\r\n            </td>\r\n            <td>                \r\n                <div id=\"DescriptioncontainerForModal\">\r\n                    <label id=\"uxWarningDescription\">"
    + alias3(((helper = (helper = helpers.Message || (depth0 != null ? depth0.Message : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"Message","hash":{},"data":data}) : helper)))
    + "</label>\r\n                </div>\r\n            </td>\r\n        </tr>\r\n        <tr>\r\n            <td>\r\n    Explanation:\r\n            </td>\r\n            <td>\r\n                <div id=\"ExplanationcontainerForModal\">\r\n                    <label id=\"uxWarningExplanation\">"
    + alias3(((helper = (helper = helpers.Explanation || (depth0 != null ? depth0.Explanation : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"Explanation","hash":{},"data":data}) : helper)))
    + "</label>\r\n                </div>\r\n            </td>\r\n        </tr>\r\n";
},"20":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["with"].call(depth0,(depth0 != null ? depth0.ElementReview : depth0),{"name":"with","hash":{},"fn":this.program(21, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "");
},"21":function(depth0,helpers,partials,data) {
    var stack1, helper, alias1=helpers.helperMissing, alias2="function", alias3=this.escapeExpression;

  return "        <tr>\r\n            <td>\r\n                Review Completed: <input id=\"uxReviewCompleted\" type=\"checkbox\" "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias1).call(depth0,(depth0 != null ? depth0.Status : depth0),"Completed",{"name":"compare","hash":{},"fn":this.program(11, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + " />\r\n            </td>\r\n        </tr>\r\n        <tr>\r\n            <td valign=\"top\">\r\n                Reviewer Comments:\r\n            </td>\r\n            <td>\r\n                <textarea id=\"uxReviewComments\" class=\"form-control\" rows=\"2\" cols=\"5\">"
    + alias3(((helper = (helper = helpers.ReviewerNotes || (depth0 != null ? depth0.ReviewerNotes : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"ReviewerNotes","hash":{},"data":data}) : helper)))
    + "</textarea>\r\n            </td>\r\n        </tr>\r\n        <tr>\r\n            <td>\r\n                Reviewed By:    \r\n            </td>\r\n            <td>\r\n                <label id=\"uxReviewedBy\">"
    + alias3(((helper = (helper = helpers.ReviewedBy || (depth0 != null ? depth0.ReviewedBy : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"ReviewedBy","hash":{},"data":data}) : helper)))
    + "</label>\r\n            </td>\r\n        </tr>\r\n        <tr>\r\n            <td>\r\n                Review Date:\r\n            </td>\r\n            <td>\r\n                <label id=\"uxReviewDate\">"
    + alias3((helpers.CHSIDateTime || (depth0 && depth0.CHSIDateTime) || alias1).call(depth0,(depth0 != null ? depth0.ReviewedDate : depth0),{"name":"CHSIDateTime","hash":{},"data":data}))
    + "</label>\r\n            </td>\r\n        </tr>\r\n";
},"23":function(depth0,helpers,partials,data) {
    var stack1;

  return "        <tr class=\"boldText\">\r\n            <td style=\"width: 20%\">\r\n                Date/Time\r\n            </td>            \r\n            <td style=\"width: 20%\">\r\n                User Name\r\n            </td>\r\n            <td style=\"width: 20%\">\r\n                Value\r\n            </td>\r\n        </tr>        \r\n"
    + ((stack1 = helpers.each.call(depth0,(depth0 != null ? depth0.History : depth0),{"name":"each","hash":{},"fn":this.program(24, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "");
},"24":function(depth0,helpers,partials,data) {
    var helper, alias1=helpers.helperMissing, alias2=this.escapeExpression, alias3="function";

  return "        <tr>\r\n            <td>\r\n                <label id=\"uxHistoryDateTimeStamp\">"
    + alias2((helpers.CHSIDateTime || (depth0 && depth0.CHSIDateTime) || alias1).call(depth0,(depth0 != null ? depth0.CreateTmsp : depth0),{"name":"CHSIDateTime","hash":{},"data":data}))
    + "</label>\r\n            </td>\r\n            <td>\r\n                <label id=\"uxHistoryUserNameStamp\">"
    + alias2(((helper = (helper = helpers.CreateUser || (depth0 != null ? depth0.CreateUser : depth0)) != null ? helper : alias1),(typeof helper === alias3 ? helper.call(depth0,{"name":"CreateUser","hash":{},"data":data}) : helper)))
    + "</label>\r\n            </td>\r\n            <td>\r\n                <label id=\"uxHistoryValueStamp\">"
    + alias2(((helper = (helper = helpers.CurrentResultAsString || (depth0 != null ? depth0.CurrentResultAsString : depth0)) != null ? helper : alias1),(typeof helper === alias3 ? helper.call(depth0,{"name":"CurrentResultAsString","hash":{},"data":data}) : helper)))
    + "</label>\r\n            </td>\r\n        </tr>\r\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.ReviewObject : depth0),{"name":"if","hash":{},"fn":this.program(1, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "");
},"useData":true,"useDepths":true});