this["CHSITemplates"]["ClaimManagement"]["NoteModalView"] = Handlebars.template({"1":function(depth0,helpers,partials,data) {
    return " disabled ";
},"3":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=this.lambda, alias2=this.escapeExpression;

  return "                <option value=\""
    + alias2(alias1(depth0, depth0))
    + "\" "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || helpers.helperMissing).call(depth0,depth0,((stack1 = (depths[1] != null ? depths[1].Note : depths[1])) != null ? stack1.NoteType : stack1),{"name":"compare","hash":{},"fn":this.program(4, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ">"
    + alias2(alias1(depth0, depth0))
    + "</option>\r\n";
},"4":function(depth0,helpers,partials,data) {
    return " selected ";
},"6":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=helpers.helperMissing, alias2="function", alias3=this.escapeExpression;

  return "                <option value=\""
    + alias3(((helper = (helper = helpers.key || (data && data.key)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"key","hash":{},"data":data}) : helper)))
    + "\" "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias1).call(depth0,(data && data.key),((stack1 = (depths[1] != null ? depths[1].Note : depths[1])) != null ? stack1.NoteCategory : stack1),{"name":"compare","hash":{},"fn":this.program(4, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ">"
    + alias3(((helper = (helper = helpers.key || (data && data.key)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"key","hash":{},"data":data}) : helper)))
    + "</option>\r\n";
},"8":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=this.lambda, alias2=this.escapeExpression;

  return "                <option value=\""
    + alias2(alias1(depth0, depth0))
    + "\" "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || helpers.helperMissing).call(depth0,depth0,((stack1 = (depths[1] != null ? depths[1].Note : depths[1])) != null ? stack1.Source : stack1),{"name":"compare","hash":{},"fn":this.program(4, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ">"
    + alias2(alias1(depth0, depth0))
    + "</option>\r\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=this.lambda, alias2=this.escapeExpression;

  return "<!-- claim management note details modal -->\r\n<div class=\"popup-form\">\r\n    <div class=\"group-container col-1 row-1\">\r\n        <div class=\"input-container\">\r\n            <h1>Type</h1>\r\n            <select id=\"NoteType\" class=\"popup-form-input\" "
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.isReply : depth0),{"name":"if","hash":{},"fn":this.program(1, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ">\r\n                <option value=\"\" disabled selected></option>\r\n"
    + ((stack1 = helpers.each.call(depth0,((stack1 = (depth0 != null ? depth0.Config : depth0)) != null ? stack1.Types : stack1),{"name":"each","hash":{},"fn":this.program(3, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "            </select>\r\n        </div>\r\n        <div class=\"input-container\">\r\n            <h1>Category</h1>\r\n            <select id=\"NoteCategory\" class=\"popup-form-input\" "
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.isReply : depth0),{"name":"if","hash":{},"fn":this.program(1, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ">\r\n                <option value=\"\" disabled selected></option>\r\n"
    + ((stack1 = helpers.each.call(depth0,((stack1 = (depth0 != null ? depth0.Config : depth0)) != null ? stack1.Categories : stack1),{"name":"each","hash":{},"fn":this.program(6, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "            </select>\r\n        </div>\r\n        <div class=\"input-container\">\r\n            <h1>Source</h1>\r\n            <select id=\"Source\" class=\"popup-form-input\" "
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.isReply : depth0),{"name":"if","hash":{},"fn":this.program(1, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ">\r\n                <option value=\"\" disabled selected></option>\r\n"
    + ((stack1 = helpers.each.call(depth0,((stack1 = (depth0 != null ? depth0.Config : depth0)) != null ? stack1.Sources : stack1),{"name":"each","hash":{},"fn":this.program(8, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "            </select>\r\n        </div>\r\n        <div class=\"input-container\" style=\"width: 63%;\">\r\n            <h1>Subject</h1>\r\n            <input id=\"Subject\" class=\"popup-form-input\" type=\"text\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.Note : depth0)) != null ? stack1.Subject : stack1), depth0))
    + "\" "
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.isReply : depth0),{"name":"if","hash":{},"fn":this.program(1, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + " />\r\n        </div>\r\n        <div class=\"input-container\">\r\n            <h1>Date</h1>\r\n            <input id=\"NoteDate\" class=\"popup-form-input\" type=\"text\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.Note : depth0)) != null ? stack1.NoteDate : stack1), depth0))
    + "\" style=\"width:87%\" />\r\n        </div>\r\n        <div class=\"input-container\" style=\"width:100%\">\r\n            <h1>Note</h1>\r\n            <!-- ckeditor replaces textarea -->\r\n            <textarea id=\"Note\" class=\"popup-form-textarea\">"
    + ((stack1 = alias1(((stack1 = (depth0 != null ? depth0.Note : depth0)) != null ? stack1.Note : stack1), depth0)) != null ? stack1 : "")
    + "</textarea>\r\n        </div>\r\n    </div>\r\n</div>\r\n<div class=\"btn-bar\">\r\n    <input id=\"note-delete\" class=\"edit\" value=\"Delete\" type=\"button\" style=\"float:left;\" "
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.isNew : depth0),{"name":"if","hash":{},"fn":this.program(1, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + " />\r\n    <input id=\"note-cancel\" value=\"Cancel\" type=\"button\" style=\"float:right;\" />\r\n    <input id=\"note-save\" class=\"edit\" value=\"Save\" type=\"button\" style=\"float:right;margin-right:20px\" />\r\n</div> \r\n\r\n";
},"useData":true,"useDepths":true});