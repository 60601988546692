this["CHSITemplates"]["Template_Claims_LossSummary"] = Handlebars.template({"1":function(depth0,helpers,partials,data) {
    var stack1;

  return "\r\n        <table cellpadding=\"2\" cellspacing=\"0\" width=\"95%\">\r\n"
    + ((stack1 = (helpers.eachArray || (depth0 && depth0.eachArray) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.LossSummary : depth0),{"name":"eachArray","hash":{},"fn":this.program(2, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "            <tr>\r\n                <td colspan=\"12\">\r\n                    <div style=\"text-align: right; color: Red;\">* denotes unaudited values\r\n                    </div>\r\n                </td>\r\n            </tr>\r\n        </table>\r\n";
},"2":function(depth0,helpers,partials,data) {
    var stack1, alias1=this.escapeExpression, alias2=helpers.helperMissing;

  return "                    <thead style=\"font-weight: bold;\">\r\n                        <tr>\r\n                            <td style=\"color: Red; font-size: 16px; border-bottom: solid 2px #7ba2d6;\">"
    + alias1(this.lambda(((stack1 = (depth0 != null ? depth0.value : depth0)) != null ? stack1.PolicyPeriod : stack1), depth0))
    + "</td>\r\n                            <td style=\"text-align: right; border-bottom: solid 2px #7ba2d6; width: 40px;\">Open</td>\r\n                            <td style=\"text-align: right; border-bottom: solid 2px #7ba2d6; width: 40px;\">Closed</td>\r\n                            <td style=\"text-align: right; border-bottom: solid 2px #7ba2d6; width: 40px;\">Litigated</td>\r\n                            <td style=\"text-align: right; border-bottom: solid 2px #7ba2d6; width: 80px;\">Paid</td>\r\n                            <td style=\"text-align: right; border-bottom: solid 2px #7ba2d6; width: 80px;\">Reserves</td>\r\n                            <td style=\"text-align: right; border-bottom: solid 2px #7ba2d6;\">Recoveries</td>\r\n                            <td style=\"text-align: right; border-bottom: solid 2px #7ba2d6;\">Incurred</td>\r\n                            <td style=\"text-align: right; border-bottom: solid 2px #7ba2d6;\">Payroll</td>\r\n                            <td style=\"text-align: right; border-bottom: solid 2px #7ba2d6;\">Contribution</td>\r\n                            <td style=\"text-align: right; border-bottom: solid 2px #7ba2d6;\">Loss Ratio</td>\r\n                            <td style=\"text-align: right; border-bottom: solid 2px #7ba2d6;\">Frequency</td>\r\n                        </tr>\r\n                    </thead>\r\n            <tbody>\r\n"
    + ((stack1 = (helpers.eachArray || (depth0 && depth0.eachArray) || alias2).call(depth0,((stack1 = (depth0 != null ? depth0.value : depth0)) != null ? stack1.LossSummaryTableRecordList : stack1),{"name":"eachArray","hash":{},"fn":this.program(3, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "                <tr>\r\n                    <td style=\"border-top: solid 1px silver;\">Total</td>\r\n                    <td style=\"border-top: solid 1px silver; text-align: right; padding-right: 4px; border-right: dotted 1px #efefef;\">"
    + alias1((helpers.CommafyNumber || (depth0 && depth0.CommafyNumber) || alias2).call(depth0,((stack1 = (depth0 != null ? depth0.value : depth0)) != null ? stack1.OpenedSum : stack1),{"name":"CommafyNumber","hash":{},"data":data}))
    + "\r\n                    </td>\r\n                    <td style=\"border-top: solid 1px silver; text-align: right; padding-right: 4px; border-right: dotted 1px #efefef;\">"
    + alias1((helpers.CommafyNumber || (depth0 && depth0.CommafyNumber) || alias2).call(depth0,((stack1 = (depth0 != null ? depth0.value : depth0)) != null ? stack1.ClosedSum : stack1),{"name":"CommafyNumber","hash":{},"data":data}))
    + " \r\n                    </td>\r\n                    <td style=\"border-top: solid 1px silver; text-align: right; padding-right: 4px; border-right: dotted 1px #efefef;\">"
    + alias1((helpers.CommafyNumber || (depth0 && depth0.CommafyNumber) || alias2).call(depth0,((stack1 = (depth0 != null ? depth0.value : depth0)) != null ? stack1.LitigatedSum : stack1),{"name":"CommafyNumber","hash":{},"data":data}))
    + " \r\n                    </td>\r\n                    <td style=\"border-top: solid 1px silver; text-align: right; padding-right: 4px; border-right: dotted 1px #efefef;\">"
    + alias1((helpers.CommafyNumber || (depth0 && depth0.CommafyNumber) || alias2).call(depth0,((stack1 = (depth0 != null ? depth0.value : depth0)) != null ? stack1.PaidSum : stack1),{"name":"CommafyNumber","hash":{},"data":data}))
    + "\r\n                    </td>\r\n                    <td style=\"border-top: solid 1px silver; text-align: right; padding-right: 4px; border-right: dotted 1px #efefef;\">"
    + alias1((helpers.CommafyNumber || (depth0 && depth0.CommafyNumber) || alias2).call(depth0,((stack1 = (depth0 != null ? depth0.value : depth0)) != null ? stack1.ReservesSum : stack1),{"name":"CommafyNumber","hash":{},"data":data}))
    + "\r\n                    </td>\r\n                    <td style=\"border-top: solid 1px silver; text-align: right; padding-right: 4px; border-right: dotted 1px #efefef;\">"
    + alias1((helpers.CommafyNumber || (depth0 && depth0.CommafyNumber) || alias2).call(depth0,((stack1 = (depth0 != null ? depth0.value : depth0)) != null ? stack1.RecoveriesSum : stack1),{"name":"CommafyNumber","hash":{},"data":data}))
    + "\r\n                    </td>\r\n                    <td style=\"border-top: solid 1px silver; text-align: right; padding-right: 4px; border-right: dotted 1px #efefef;\">"
    + alias1((helpers.CommafyNumber || (depth0 && depth0.CommafyNumber) || alias2).call(depth0,((stack1 = (depth0 != null ? depth0.value : depth0)) != null ? stack1.IncurredSum : stack1),{"name":"CommafyNumber","hash":{},"data":data}))
    + "\r\n                    </td>\r\n                    <td style=\"border-top: solid 1px silver; text-align: right; padding-right: 4px; border-right: dotted 1px #efefef;\">"
    + alias1((helpers.CommafyNumber || (depth0 && depth0.CommafyNumber) || alias2).call(depth0,((stack1 = (depth0 != null ? depth0.value : depth0)) != null ? stack1.Payroll : stack1),{"name":"CommafyNumber","hash":{},"data":data}))
    + "\r\n"
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias2).call(depth0,((stack1 = (depth0 != null ? depth0.value : depth0)) != null ? stack1.Audited : stack1),1,{"name":"compare","hash":{"operator":"<"},"fn":this.program(5, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "                    </td>\r\n                    <td style=\"border-top: solid 1px silver; text-align: right; padding-right: 4px; border-right: dotted 1px #efefef;\">"
    + alias1((helpers.CommafyNumber || (depth0 && depth0.CommafyNumber) || alias2).call(depth0,((stack1 = (depth0 != null ? depth0.value : depth0)) != null ? stack1.Contribution : stack1),{"name":"CommafyNumber","hash":{},"data":data}))
    + "\r\n"
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias2).call(depth0,((stack1 = (depth0 != null ? depth0.value : depth0)) != null ? stack1.Audited : stack1),1,{"name":"compare","hash":{"operator":"<"},"fn":this.program(5, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "                    </td>\r\n                    <td style=\"border-top: solid 1px silver; text-align: right; padding-right: 4px; border-right: dotted 1px #efefef;\">"
    + alias1((helpers.FixedToPercentage || (depth0 && depth0.FixedToPercentage) || alias2).call(depth0,((stack1 = (depth0 != null ? depth0.value : depth0)) != null ? stack1.LossRatio : stack1),{"name":"FixedToPercentage","hash":{},"data":data}))
    + "\r\n                    </td>\r\n                    <td style=\"border-top: solid 1px silver; text-align: right; padding-right: 4px;\">"
    + alias1((helpers.DecimalToPercentage || (depth0 && depth0.DecimalToPercentage) || alias2).call(depth0,((stack1 = (depth0 != null ? depth0.value : depth0)) != null ? stack1.Frequency : stack1),{"name":"DecimalToPercentage","hash":{},"data":data}))
    + "\r\n                    </td>\r\n                </tr>\r\n";
},"3":function(depth0,helpers,partials,data) {
    var stack1, alias1=this.escapeExpression, alias2=helpers.helperMissing;

  return "                                <tr>\r\n                                    <td>"
    + alias1(this.lambda(((stack1 = (depth0 != null ? depth0.value : depth0)) != null ? stack1.ClaimClass : stack1), depth0))
    + "</td>\r\n                                    <td style=\"text-align: right; padding-right: 4px; border-right: dotted 1px #efefef;\">"
    + alias1((helpers.CommafyNumber || (depth0 && depth0.CommafyNumber) || alias2).call(depth0,((stack1 = (depth0 != null ? depth0.value : depth0)) != null ? stack1.Opened : stack1),{"name":"CommafyNumber","hash":{},"data":data}))
    + "</td>\r\n                                    <td style=\"text-align: right; padding-right: 4px; border-right: dotted 1px #efefef;\">"
    + alias1((helpers.CommafyNumber || (depth0 && depth0.CommafyNumber) || alias2).call(depth0,((stack1 = (depth0 != null ? depth0.value : depth0)) != null ? stack1.Closed : stack1),{"name":"CommafyNumber","hash":{},"data":data}))
    + "</td>\r\n                                    <td style=\"text-align: right; padding-right: 4px; border-right: dotted 1px #efefef;\">"
    + alias1((helpers.CommafyNumber || (depth0 && depth0.CommafyNumber) || alias2).call(depth0,((stack1 = (depth0 != null ? depth0.value : depth0)) != null ? stack1.Litigated : stack1),{"name":"CommafyNumber","hash":{},"data":data}))
    + "</td>\r\n                                    <td style=\"text-align: right; padding-right: 4px; border-right: dotted 1px #efefef;\">"
    + alias1((helpers.CommafyNumber || (depth0 && depth0.CommafyNumber) || alias2).call(depth0,((stack1 = (depth0 != null ? depth0.value : depth0)) != null ? stack1.Paid : stack1),{"name":"CommafyNumber","hash":{},"data":data}))
    + "</td>\r\n                                    <td style=\"text-align: right; padding-right: 4px; border-right: dotted 1px #efefef;\">"
    + alias1((helpers.CommafyNumber || (depth0 && depth0.CommafyNumber) || alias2).call(depth0,((stack1 = (depth0 != null ? depth0.value : depth0)) != null ? stack1.Reserves : stack1),{"name":"CommafyNumber","hash":{},"data":data}))
    + "</td>\r\n                                    <td style=\"text-align: right; padding-right: 4px; border-right: dotted 1px #efefef;\">"
    + alias1((helpers.CommafyNumber || (depth0 && depth0.CommafyNumber) || alias2).call(depth0,((stack1 = (depth0 != null ? depth0.value : depth0)) != null ? stack1.Recovery : stack1),{"name":"CommafyNumber","hash":{},"data":data}))
    + "</td>\r\n                                    <td style=\"text-align: right; padding-right: 4px; border-right: dotted 1px #efefef;\">"
    + alias1((helpers.CommafyNumber || (depth0 && depth0.CommafyNumber) || alias2).call(depth0,((stack1 = (depth0 != null ? depth0.value : depth0)) != null ? stack1.Incurred : stack1),{"name":"CommafyNumber","hash":{},"data":data}))
    + "</td>\r\n                                </tr>\r\n            </tbody>\r\n";
},"5":function(depth0,helpers,partials,data) {
    return "                            <span style=\"color: red;\">*</span>\r\n";
},"7":function(depth0,helpers,partials,data) {
    return "    <p>Nothing to Display.</p>\r\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.LossSummary : depth0),{"name":"if","hash":{},"fn":this.program(1, data, 0),"inverse":this.program(7, data, 0),"data":data})) != null ? stack1 : "");
},"useData":true});