this["CHSITemplates"]["Template_ScheduleOverview"] = Handlebars.template({"1":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.each.call(depth0,(depth0 != null ? depth0.Schedule : depth0),{"name":"each","hash":{},"fn":this.program(2, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "");
},"2":function(depth0,helpers,partials,data) {
    var stack1, helper, alias1=helpers.helperMissing, alias2="function", alias3=this.escapeExpression;

  return "        <tr>\r\n            <td>\r\n                "
    + alias3(((helper = (helper = helpers.ScheduleID || (depth0 != null ? depth0.ScheduleID : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"ScheduleID","hash":{},"data":data}) : helper)))
    + "\r\n            </td>\r\n            <td>\r\n                "
    + alias3(((helper = (helper = helpers.ScheduleName || (depth0 != null ? depth0.ScheduleName : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"ScheduleName","hash":{},"data":data}) : helper)))
    + "\r\n            </td>\r\n            <td>\r\n                "
    + alias3(((helper = (helper = helpers.ScheduleGroup || (depth0 != null ? depth0.ScheduleGroup : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"ScheduleGroup","hash":{},"data":data}) : helper)))
    + "\r\n            </td>\r\n            <td>\r\n                <span class=\"jxScheduleTypeName\">"
    + alias3(((helper = (helper = helpers.ScheduleType || (depth0 != null ? depth0.ScheduleType : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"ScheduleType","hash":{},"data":data}) : helper)))
    + "</span>\r\n            </td>\r\n            <td>\r\n                "
    + alias3(((helper = (helper = helpers.ScheduleIndex || (depth0 != null ? depth0.ScheduleIndex : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"ScheduleIndex","hash":{},"data":data}) : helper)))
    + "\r\n            </td>\r\n            <td>\r\n                "
    + alias3(((helper = (helper = helpers.Status || (depth0 != null ? depth0.Status : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"Status","hash":{},"data":data}) : helper)))
    + "\r\n            </td>\r\n            <td>\r\n                "
    + alias3(((helper = (helper = helpers.ScheduleCode || (depth0 != null ? depth0.ScheduleCode : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"ScheduleCode","hash":{},"data":data}) : helper)))
    + "\r\n            </td>\r\n            <td>\r\n                "
    + alias3(((helper = (helper = helpers.StartTime || (depth0 != null ? depth0.StartTime : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"StartTime","hash":{},"data":data}) : helper)))
    + "\r\n            </td>\r\n            <td>\r\n                "
    + alias3(((helper = (helper = helpers.FailureCount || (depth0 != null ? depth0.FailureCount : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"FailureCount","hash":{},"data":data}) : helper)))
    + "\r\n            </td>\r\n            <td>\r\n                "
    + alias3(((helper = (helper = helpers.MaxFailureAttempts || (depth0 != null ? depth0.MaxFailureAttempts : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"MaxFailureAttempts","hash":{},"data":data}) : helper)))
    + "\r\n            </td>\r\n            <td>\r\n"
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.ScheduleType : depth0),{"name":"if","hash":{},"fn":this.program(3, data, 0),"inverse":this.program(6, data, 0),"data":data})) != null ? stack1 : "")
    + "            </td>\r\n        </tr>\r\n";
},"3":function(depth0,helpers,partials,data) {
    var stack1;

  return "                "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.ScheduleType : depth0),"Undefined",{"name":"compare","hash":{},"fn":this.program(4, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\r\n";
},"4":function(depth0,helpers,partials,data) {
    return "";
},"6":function(depth0,helpers,partials,data) {
    var helper, alias1=helpers.helperMissing, alias2="function", alias3=this.escapeExpression;

  return "                [<a id=\"jxDetailedInformation\" href=\"#\" onclick=\"ShowDetailedInformation('"
    + alias3(((helper = (helper = helpers.ScheduleType || (depth0 != null ? depth0.ScheduleType : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"ScheduleType","hash":{},"data":data}) : helper)))
    + "', "
    + alias3(((helper = (helper = helpers.BatchFileID || (depth0 != null ? depth0.BatchFileID : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"BatchFileID","hash":{},"data":data}) : helper)))
    + ", "
    + alias3(((helper = (helper = helpers.ReportID || (depth0 != null ? depth0.ReportID : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"ReportID","hash":{},"data":data}) : helper)))
    + ")\" class=\"minilink\">view</a>]\r\n";
},"8":function(depth0,helpers,partials,data) {
    return "        No schedules to display\r\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1;

  return "<table width=\"100%\" class=\"dtable sortable\">\r\n    <thead>\r\n        <tr>\r\n            <td>\r\n                ID\r\n            </td>\r\n            <td>\r\n                Name\r\n            </td>\r\n            <td>\r\n                Group\r\n            </td>\r\n            <td>\r\n                Type\r\n            </td>\r\n            <td>\r\n                Index\r\n            </td>\r\n            <td>\r\n                Status\r\n            </td>\r\n            <td>\r\n                Schedule Code\r\n            </td>\r\n            <td>\r\n                Start Time\r\n            </td>\r\n            <td>\r\n                Failure Count\r\n            </td>\r\n            <td>\r\n                Max Failure Attempts\r\n            </td>\r\n            <td class=\"sorttable_nosort\">\r\n                &nbsp\r\n            </td>\r\n        </tr>\r\n    </thead>\r\n    <tbody>\r\n"
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.Schedule : depth0),{"name":"if","hash":{},"fn":this.program(1, data, 0),"inverse":this.program(8, data, 0),"data":data})) != null ? stack1 : "")
    + "    </tbody>\r\n</table>";
},"useData":true});