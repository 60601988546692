this["CHSITemplates"]["Widgets_TopPreviewPanel"] = Handlebars.template({"1":function(depth0,helpers,partials,data) {
    var stack1, alias1=this.lambda, alias2=this.escapeExpression;

  return "\r\n        <div class=\"widget-preview "
    + ((stack1 = (helpers.IgnoreWidgetIf || (depth0 && depth0.IgnoreWidgetIf) || helpers.helperMissing).call(depth0,depth0,{"name":"IgnoreWidgetIf","hash":{},"fn":this.program(2, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\" data-wid=\""
    + alias2(alias1((depth0 != null ? depth0.WidgetID : depth0), depth0))
    + "\">"
    + alias2(alias1((depth0 != null ? depth0.WidgetName : depth0), depth0))
    + "</div>\r\n";
},"2":function(depth0,helpers,partials,data) {
    return "";
},"4":function(depth0,helpers,partials,data) {
    return "        <div style=\"height:100%;line-height:100px;font-style:italic;font-size:1.3em;\">no more widgets (◕︵◕)</div>\r\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1;

  return "<div id=\"widget-top-preview-panel\" style=\"height:100%;overflow-x:auto;overflow-y:hidden;white-space:nowrap;\">\r\n    <!--<div style=\"position:absolute;width:100%;height:100px;background-color:#e7e7e7;z-index:-10;\"></div>-->    \r\n"
    + ((stack1 = helpers.each.call(depth0,(depth0 != null ? depth0.WidgetPreviews : depth0),{"name":"each","hash":{},"fn":this.program(1, data, 0),"inverse":this.program(4, data, 0),"data":data})) != null ? stack1 : "")
    + "</div>";
},"useData":true});