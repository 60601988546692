this["CHSITemplates"]["ClaimManagement"]["ClaimantEmploymentDetails"] = Handlebars.template({"1":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=this.lambda, alias2=this.escapeExpression;

  return "                    <option value=\""
    + alias2(alias1(depth0, depth0))
    + "\" "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || helpers.helperMissing).call(depth0,depth0,((stack1 = (depths[1] != null ? depths[1].Claimant : depths[1])) != null ? stack1.EmploymentType : stack1),{"name":"compare","hash":{},"fn":this.program(2, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ">"
    + alias2(alias1(depth0, depth0))
    + "</option>\r\n";
},"2":function(depth0,helpers,partials,data) {
    return " selected";
},"4":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=this.lambda, alias2=this.escapeExpression;

  return "                    <option value=\""
    + alias2(alias1(depth0, depth0))
    + "\" "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || helpers.helperMissing).call(depth0,depth0,((stack1 = (depths[1] != null ? depths[1].Claimant : depths[1])) != null ? stack1.WageFreq : stack1),{"name":"compare","hash":{},"fn":this.program(2, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ">"
    + alias2(alias1(depth0, depth0))
    + "</option>\r\n";
},"6":function(depth0,helpers,partials,data) {
    return " checked";
},"8":function(depth0,helpers,partials,data) {
    return "checked";
},"10":function(depth0,helpers,partials,data) {
    return " disabled ";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=this.lambda, alias2=this.escapeExpression, alias3=helpers.helperMissing;

  return "<!-- claim management claimant details view -->\r\n<div class=\"popup-form group-bg\" style=\"height:100%;margin:0 8px 0 0;\">\r\n    <h1>Employment Info</h1>\r\n    <div id=\"claimant-view-employment-info\" class=\"group-container col-2\" style=\"\">\r\n        <div class=\"input-container col-2\">\r\n            <label>Hire Date</label>\r\n            <input type=\"text\" id=\"DateHired\" class=\"popup-form-input date\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.Claimant : depth0)) != null ? stack1.DateHired : stack1), depth0))
    + "\" />\r\n        </div>\r\n        <div class=\"input-container col-2\">\r\n            <label>Termination Date</label>\r\n            <input type=\"text\" id=\"DateTerminated\" class=\"popup-form-input date\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.Claimant : depth0)) != null ? stack1.DateTerminated : stack1), depth0))
    + "\" />\r\n        </div>\r\n        <div class=\"input-container col-1\">\r\n            <label>Occupation</label>\r\n            <input maxlength=\"50\" type=\"text\" id=\"Occupation\" class=\"popup-form-input\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.Claimant : depth0)) != null ? stack1.Occupation : stack1), depth0))
    + "\" />\r\n        </div>\r\n        <div class=\"input-container col-1\">\r\n            <label>Department</label>\r\n            <input maxlength=\"50\" type=\"text\" id=\"Department\" class=\"popup-form-input\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.Claimant : depth0)) != null ? stack1.Department : stack1), depth0))
    + "\" />\r\n        </div>\r\n        <div class=\"input-container col-1\">\r\n            <label>Supervisor</label>\r\n            <input maxlength=\"50\" type=\"text\" id=\"Supervisor\" class=\"popup-form-input\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.Claimant : depth0)) != null ? stack1.Supervisor : stack1), depth0))
    + "\" />\r\n        </div>\r\n        <div class=\"input-container col-2\">\r\n            <label>Class Code</label>\r\n            <input maxlength=\"6\" type=\"text\" id=\"ClassCode\" class=\"popup-form-input\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.Claimant : depth0)) != null ? stack1.ClassCode : stack1), depth0))
    + "\" />\r\n        </div>\r\n        <div class=\"input-container col-2\">\r\n            <label>Employee ID</label>\r\n            <input maxlength=\"20\" type=\"text\" id=\"EmployeeID\" class=\"popup-form-input\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.Claimant : depth0)) != null ? stack1.EmployeeID : stack1), depth0))
    + "\" />\r\n        </div>\r\n    </div>\r\n    <div class=\"group-container col-2\" style=\"\">\r\n        <div class=\"group-container col-2\">\r\n            <div class=\"input-container col-1\">\r\n                <label>Employment Type</label>\r\n                <select id=\"EmploymentType\" class=\"popup-form-input\">\r\n"
    + ((stack1 = helpers.each.call(depth0,((stack1 = (depth0 != null ? depth0.Config : depth0)) != null ? stack1.EmploymentType : stack1),{"name":"each","hash":{},"fn":this.program(1, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "                    <option value=\"Other\" "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias3).call(depth0,"Other",((stack1 = (depth0 != null ? depth0.Claimant : depth0)) != null ? stack1.EmploymentType : stack1),{"name":"compare","hash":{},"fn":this.program(2, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ">Other</option>\r\n                </select>\r\n            </div>\r\n            <div class=\"input-container col-2\">\r\n                <label>Wage</label>\r\n                <input maxlength=\"100\" type=\"number\" id=\"Wage\" class=\"popup-form-input\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.Claimant : depth0)) != null ? stack1.Wage : stack1), depth0))
    + "\" />\r\n            </div>\r\n            <div class=\"input-container col-2\">\r\n                <label>Freq</label>\r\n                <select id=\"WageFreq\" class=\"popup-form-input\">\r\n"
    + ((stack1 = helpers.each.call(depth0,((stack1 = (depth0 != null ? depth0.Config : depth0)) != null ? stack1.WageFreq : stack1),{"name":"each","hash":{},"fn":this.program(4, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "                    <option value=\"Other\" "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias3).call(depth0,"Other",((stack1 = (depth0 != null ? depth0.Claimant : depth0)) != null ? stack1.WageFreq : stack1),{"name":"compare","hash":{},"fn":this.program(2, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ">Other</option>\r\n                </select>\r\n            </div>\r\n            <div class=\"input-container col-1\">\r\n                <label>Temporary Disability</label>\r\n                <input maxlength=\"100\" type=\"number\" id=\"TempDisabilityRate\" class=\"popup-form-input\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.Claimant : depth0)) != null ? stack1.TempDisabilityRate : stack1), depth0))
    + "\" />\r\n            </div>\r\n            <div class=\"input-container col-1\">\r\n                <label>Permanent Disability</label>\r\n                <input maxlength=\"100\" type=\"number\" id=\"PermDisabilityRate\" class=\"popup-form-input\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.Claimant : depth0)) != null ? stack1.PermDisabilityRate : stack1), depth0))
    + "\" />\r\n            </div>\r\n            <div class=\"input-container col-1\">\r\n                <label>Vocational Rehab</label>\r\n                <input maxlength=\"100\" type=\"number\" id=\"VocationalRehabRate\" class=\"popup-form-input\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.Claimant : depth0)) != null ? stack1.VocationalRehabRate : stack1), depth0))
    + "\" />\r\n            </div>\r\n        </div>\r\n        <div class=\"group-container col-2\">\r\n            <div class=\"input-container col-2\">\r\n                <label>First Day Lost</label>\r\n                <input type=\"text\" id=\"FirstDayLost\" class=\"popup-form-input date\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.Claimant : depth0)) != null ? stack1.FirstDayLost : stack1), depth0))
    + "\" />\r\n            </div>\r\n            <div class=\"input-container col-2 center\">\r\n                <label>Full Day Lost?</label>\r\n                <input type=\"checkbox\" id=\"FullDayLost\" class=\"popup-form-input\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.Claimant : depth0)) != null ? stack1.FullDayLost : stack1), depth0))
    + "\" "
    + ((stack1 = helpers["if"].call(depth0,((stack1 = (depth0 != null ? depth0.Claimant : depth0)) != null ? stack1.FullDayLost : stack1),{"name":"if","hash":{},"fn":this.program(6, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + " />\r\n            </div>\r\n            <div class=\"input-container col-2\">\r\n                <label>Last Day Worked</label>\r\n                <input type=\"text\" id=\"LastDayWorked\" class=\"popup-form-input date\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.Claimant : depth0)) != null ? stack1.LastDayWorked : stack1), depth0))
    + "\" />\r\n            </div>\r\n            <div class=\"input-container col-2 center\">\r\n                <label>Full Day Worked?</label>\r\n                <input type=\"checkbox\" id=\"FullPayOnLastDay\" class=\"popup-form-input\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.Claimant : depth0)) != null ? stack1.FullPayOnLastDay : stack1), depth0))
    + "\" "
    + ((stack1 = helpers["if"].call(depth0,((stack1 = (depth0 != null ? depth0.Claimant : depth0)) != null ? stack1.FullPayOnLastDay : stack1),{"name":"if","hash":{},"fn":this.program(6, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + " />\r\n            </div>\r\n            <div class=\"input-container col-2 center\">\r\n                <label>Others Involved?</label>\r\n                <input type=\"checkbox\" id=\"OthersInvolved\" class=\"popup-form-input\" value=\"Claimant.OthersInvolved\" "
    + ((stack1 = helpers["if"].call(depth0,((stack1 = (depth0 != null ? depth0.Claimant : depth0)) != null ? stack1.OthersInvolved : stack1),{"name":"if","hash":{},"fn":this.program(6, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + " />\r\n            </div>\r\n            <div class=\"input-container col-2 center\">\r\n                <label>Primary Claimant?</label>\r\n                <input type=\"checkbox\" id=\"PrimaryClaimant\" class=\"popup-form-input\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.Claimant : depth0)) != null ? stack1.PrimaryClaimant : stack1), depth0))
    + "\" "
    + ((stack1 = helpers["if"].call(depth0,((stack1 = (depth0 != null ? depth0.Claimant : depth0)) != null ? stack1.PrimaryClaimant : stack1),{"name":"if","hash":{},"fn":this.program(8, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + " />\r\n            </div>\r\n        </div>\r\n    </div>\r\n\r\n</div>\r\n<!--<div class=\"btn-bar\" style=\"margin:0 1%; width:97%\">\r\n    <input id=\"claimant-delete\" value=\"Delete\" type=\"button\" style=\"float:left;\" "
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.isNew : depth0),{"name":"if","hash":{},"fn":this.program(10, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + " />\r\n    <input id=\"claimant-cancel\" value=\"Cancel\" type=\"button\" style=\"float:right;\" />\r\n    <input id=\"claimant-save\" value=\"Save\" type=\"button\" style=\"float:right;margin-right:20px\" />\r\n</div>--> ";
},"useData":true,"useDepths":true});