this["CHSITemplates"]["AdminTools_ClaimIntakeEditor_Information_Notifications"] = Handlebars.template({"1":function(depth0,helpers,partials,data) {
    var helper, alias1=helpers.helperMissing, alias2="function", alias3=this.escapeExpression;

  return "        <tr>\r\n            <td>\r\n                "
    + alias3(((helper = (helper = helpers.NotificationName || (depth0 != null ? depth0.NotificationName : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"NotificationName","hash":{},"data":data}) : helper)))
    + "\r\n            </td>\r\n            <td></td>\r\n            <td style=\"text-align: right;\">\r\n                <input type=\"button\" value=\" Remove \" class=\"infoNotificationRemove\" data-notificationid=\""
    + alias3(((helper = (helper = helpers.NotificationID || (depth0 != null ? depth0.NotificationID : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"NotificationID","hash":{},"data":data}) : helper)))
    + "\" />\r\n            </td>\r\n        </tr>\r\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1;

  return "<table width=\"100%\" class=\"dtable sortable\">\r\n    <thead>\r\n        <tr>\r\n            <td>Notification</td>\r\n            <td>&nbsp;</td>\r\n            <td>&nbsp;</td>\r\n        </tr>\r\n    </thead>\r\n    <tbody>\r\n"
    + ((stack1 = helpers.each.call(depth0,(depth0 != null ? depth0.Notifications : depth0),{"name":"each","hash":{},"fn":this.program(1, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "    </tbody>\r\n</table>";
},"useData":true});